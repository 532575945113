import React, { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Menu,
  MenuItem,
  MenuDivider,
  MenuList,
  Avatar,
  MenuButton,
  Tooltip,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { RiShieldUserLine, RiShieldStarFill } from 'react-icons/ri'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useFirebaseHook } from 'services/hooks/useFirebaseHook';
import { useUser } from 'reactfire'
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import LogoImg from 'assets/images/kshubLogo.png'
import { useIntl } from 'react-intl';
import { useContextAppHook } from 'services/contexts/hooks/useContextAppHook';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

export const disabledOptions = ['markets', 'products', 'channels', 'people']

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  const user = useUser();
  const history = useHistory();
  const intl = useIntl()

  const { functions: { logout } } = useFirebaseHook();
  const { data } = useContextAppHook()
  const { functions: { superUserSwitch }, data: { superUserUI } } = useContextUIHook()

  const [isSuperUser, setIsSuperUser] = useState(false)

  useEffect(() => {
    if (data.superUserReference) {
      setIsSuperUser(data.superUserReference)
    }
  }, [data]);

  // const captureSwitchSuperUser = (event: ChangeEvent<HTMLInputElement>) => {
  //   superUserSwitch(event.target.checked)
  // }

  const captureSwitchSuperUser = () => {
    const result = superUserSwitch(!isSuperUser)
    setIsSuperUser(result)
  }

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: intl.formatMessage({ id: 'ks.app.menu.home' }),
      href: 'home'
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.projects' }),
      href: 'projects',
      children: [
        {
          label: intl.formatMessage({ id: 'ks.app.menu.projects' }),
          subLabel: 'Projects you have access to, including your own drafts',
          href: 'projects',
        },
        {
          label: intl.formatMessage({ id: 'ks.app.menu.projectsgroups' }),
          subLabel: 'Projects can be organised into groups',
          href: 'projects-groups',
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.people' }),
      href: 'people'
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.channels' }),
      href: 'channels'
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.organisations' }),
      href: 'organisations'
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.places' }),
      href: 'places'
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.products' }),
      href: 'products'
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.markets' }),
      href: 'markets'
    },
    {
      label: intl.formatMessage({ id: 'ks.app.menu.insights' }),
      href: 'insights/reports',
      children: [
        {
          label: intl.formatMessage({ id: 'ks.app.menu.reports' }),
          subLabel: 'Manage reports by channel and send them automatically by email',
          href: 'insights/reports',
        },
      ],
    },
  ];

  return (
    <Box>
      <Flex
        boxShadow={useColorModeValue('md', 'rgb(255 255 255 / 25%) 0px 0px 25px 0px')}
        position="fixed"
        w="100%"
        zIndex="1500"
        bg={useColorModeValue('gray.100', 'gray.900')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>

        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'flex', lg: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>

          <Box onClick={() => history.push('/app/home')} cursor='pointer'>
            <Image src={LogoImg} alt="Kitspace Hub Logo" height="50px" width="auto" mr={3} />
          </Box>

          <Flex display={{ base: 'none', md: 'none', lg: 'flex' }} minHeight="50px" >
            <DesktopNav navItems={NAV_ITEMS} />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={4}>
          <Menu>
            {
              isSuperUser ?
                <Tooltip label="Super User Mode">
                  <IconButton
                    fontSize="xl"
                    variant="ghost"
                    color="current"
                    onClick={captureSwitchSuperUser}
                    aria-label='Working on super user mode'
                    icon={<RiShieldStarFill />}
                  />
                </Tooltip> :
                <Tooltip label="User Mode">
                  <IconButton
                    fontSize="xl"
                    variant="ghost"
                    color="current"
                    onClick={captureSwitchSuperUser}
                    aria-label='Working on user mode'
                    icon={<RiShieldUserLine />}
                  />
                </Tooltip>
            }
            <ColorModeSwitcher />
            <MenuButton
              as={Button}
              rounded={'full'}
              variant={'link'}
              cursor={'pointer'}>
              <Avatar
                size={'sm'}
                src={user.data.photoURL}
              />
            </MenuButton>
            <MenuList>
              {superUserUI &&
                <>
                  <MenuItem onClick={() => history.push('/app/superuser')}>
                    Super User
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/app/superuser/scheduled')}>
                    Scheduled Tasks
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/app/superuser/debugger')}>
                    Debugger
                  </MenuItem>
                  <MenuDivider />
                </>
              }
              <MenuItem onClick={() => history.push('/app/settings')}>
                {intl.formatMessage({ id: 'ks.app.menu.settings' })}
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout}>
                {intl.formatMessage({ id: 'ks.app.menu.logout' })}
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>

      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav navItems={NAV_ITEMS} />
      </Collapse>
    </Box>
  );
}

interface Navigation {
  navItems: Array<NavItem>
}

function DesktopNav({ navItems }: Navigation) {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkDisabledColor = useColorModeValue('gray.300', 'gray.600');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <Stack direction={'row'} spacing={4} paddingTop={3}>
      {navItems.map((navItem) => {
        return (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                {
                  navItem.href && disabledOptions.includes(navItem.href) ?
                    <Tooltip label="Coming soon">
                      <Link
                        p={2}
                        fontSize={'md'}
                        fontWeight={400}
                        color={linkDisabledColor}
                        _hover={{
                          textDecoration: 'none',
                        }}>
                        {navItem.label}
                      </Link>
                    </Tooltip>
                    :
                    <Link
                      p={1}
                      onClick={() => {
                        history.push(`${url}/${navItem.href}`.toLowerCase())
                      }}
                      fontSize={'md'}
                      fontWeight={400}
                      color={linkColor}
                      _hover={{
                        textDecoration: 'none',
                        color: linkHoverColor,
                      }}>
                      {navItem.label}
                    </Link>
                }
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        )
      })
      }
    </Stack>
  );
};

function DesktopSubNav({ label, href, subLabel }: NavItem) {

  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <Link
      onClick={() => {
        history.push(`${url}/${href}`.toLowerCase())
      }}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('blue.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'blue.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'blue.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

function MobileNav({ navItems }: Navigation) {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      pt={24}
      boxShadow={useColorModeValue('md', 'rgb(255 255 255 / 25%) 0px 0px 25px 0px')}
      display={{ base: 'flex', md: 'flex', lg: 'none' }}
    >
      {navItems.map((navItem) => {

        if (navItem.href && disabledOptions.includes(navItem.href)) {
          return (<MobileNavItemDisabled key={navItem.label} {...navItem} />)
        }

        return (
          <MobileNavItem key={navItem.label} {...navItem} />
        )
      })}
    </Stack>
  );
};

function MobileNavItemDisabled({ label, children, href }: NavItem) {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.300', 'gray.600')}>
          {label}
        </Text>
      </Flex>
    </Stack>
  );
}

function MobileNavItem({ label, children, href }: NavItem) {

  const { isOpen, onToggle } = useDisclosure();
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        onClick={() => {
          history.push(`${url}/${href}`.toLowerCase())
        }}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};