import React from 'react';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import {
  Heading,
  Text,
  Box,
  Divider,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import BadgesAccess from 'pages/Components/generic/Badges/BadgesAccess';

function LabelsInvitations() {

  const intl = useIntl()

  return (
    <BoxShadow>
      <Heading fontSize="xl" mb={5}>
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.access_and_invitations' }
          )
        }
      </Heading>
      <Box mt={3}>
        <Text fontWeight="bold">
          {
            intl.formatMessage(
              { id: 'ks.superuser.home.labels.app_invitation' }
            )
          }
        </Text>
        <BadgesAccess.InvitationInvited mr={2} ml={1} />
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.definition_invited' }
          )
        }
        <br />
        <BadgesAccess.InvitationCanceled mr={2} ml={1} />
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.definition_canceled' }
          )
        }
        <br />
      </Box>
      <Divider mt={3} mb={3} />
      <Box mt={3}>
        <Text fontWeight="bold">
          {
            intl.formatMessage(
              { id: 'ks.app.status' }
            )
          }
        </Text>
        <BadgesAccess.InvitationStatusAccepted ml={1} mr={2} />
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.definition_accepted' }
          )
        }
        <br />
        <BadgesAccess.InvitationStatusOpen ml={1} mr={2} />
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.definition_open' }
          )
        }
        <br />
      </Box>
    </BoxShadow>
  );
}

export default LabelsInvitations;