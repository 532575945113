import React, { useEffect, useState } from 'react';
import { Skeleton, FormControl, FormErrorMessage, Box, Select, FormLabel, Heading, Input, Text, FormHelperText, useToast } from '@chakra-ui/react'
import { useUser } from 'reactfire';
import { useForm } from "react-hook-form";
import { typesOrg } from 'helpers/kshub';
import CenterBox from 'pages/Components/generic/CenterBox';
import { useHistory, useParams } from 'react-router-dom';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import capitalize from 'capitalize'
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions'
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import BoxResponsiveTwo from 'pages/Components/layout/Box/BoxResponsiveTwo';
import SelectMultiple from 'pages/Components/generic/SelectMultiple';
import OptionsCountries from 'pages/Components/generic/Selects/OptionsCountries';
import AlertNoAccess from 'pages/Components/generic/Alerts/AlertNoAccess';
import { toastTypes } from 'helpers/alerts';

interface Props {
  typeForm: 'create' | 'edit'
}

function OrganisationsEdit({ typeForm }: Props) {

  const { register, handleSubmit, reset, setValue, formState: { errors, isSubmitting } } = useForm();

  const { orgId } = useParams<any>(); // only in case of 'edit'
  const { data: { superUserUI } } = useContextUIHook()

  const toast = useToast()
  const user: any = useUser()
  const history = useHistory()

  const stateRedux = useSelector((store: any) => store.organisationReducer);
  const statePlacesRedux = useSelector((store: any) => store.placeReducer);
  const dispatch = useDispatch();

  const [organisation, setOrganisation] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const [loadingPartners, setLoadingPartners] = useState(true)
  const [updatingPartners, setUpdatingPartners] = useState(false);
  const [partners, setPartners] = useState([])

  const [updatingPlaces, setUpdatingPlaces] = useState(false);
  const [places, setPlaces] = useState([])

  const toastProcessing = () => {
    toast(toastTypes.processing)
  }

  const onSubmitPlaces = () => {
    toastProcessing()
    setUpdatingPlaces(true)
    dispatch(actions.organisations.updatePlacesRelated(
      orgId,
      places.map((place: any) => place.value),
      (status: boolean) => {
        if (status) {
          setUpdatingPlaces(false)
          toast(toastTypes.success)
        }
      }
    )
    )
  }

  const onSubmitPartners = () => {
    toastProcessing()
    setUpdatingPartners(true)
    dispatch(actions.organisations.updatePartners(
      orgId,
      partners.map((organisation: any) => organisation.value),
      (status: boolean) => {
        if (status) {
          setUpdatingPartners(false)
          toast(toastTypes.success)
        }
      }
    )
    )
  }

  const onSubmit = (values: any) => {
    toastProcessing()

    return new Promise((resolve, reject) => {
      const payload = values;
      payload.superUserUI = superUserUI;

      if (typeForm === 'create') {
        dispatch(actions.organisations.createOrganisation(payload, (status: any, info: any) => {
          if (status === true) {

            toast(toastTypes.custom({
              title: superUserUI ? "Organization created correctly!" : "Draft created correctly! Ready for Admin review",
              description: "You will be redirected to add people",
              status: "success",
            }))

            setTimeout(() => {
              if (info.data.data.orgId) {
                history.push(`${info.data.data.orgId}/people`);
              }
              reset();
              resolve(true)
            }, 2000)

          } else {
            toast(toastTypes.error)
            reject(false)
          }
        }))
      } else if (typeForm === 'edit') {
        dispatch(actions.organisations.updateOrganisation(orgId, payload, (status: any, info: any) => {
          if (status === true) {
            toast(toastTypes.updated)
            history.push(`/app/organisations`);
            resolve(true)
          } else {
            toast(toastTypes.error)
          }
        }))
      }
    })
  }

  useEffect(() => {
    dispatch(actions.organisations.getOrganisations(false))
    if (typeForm === 'edit') {
      setLoading(true)
      dispatch(actions.organisations.getOrganisationById(orgId, false, null))
      dispatch(actions.places.getPlaces(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateRedux?.organisationsAccessById[orgId]) {
      setLoadingPartners(false)
    }
  }, [orgId, stateRedux.organisationsAccessById]);

  useEffect(() => {
    if (stateRedux.organisationsById[orgId]) {
      const orgData = stateRedux.organisationsById[orgId];

      setOrganisation(orgData)
      setPartners(orgData.organisationsPartners || [])
      Object.keys(orgData).map((key: string) => {
        return setValue(key, orgData[key])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.organisationsById]);

  useEffect(() => {
    setLoading(stateRedux.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.loading]);

  const MainContent = () => <Box mt={8}>
    <Skeleton isLoaded={!loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.name} mt={4}>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            id="name"
            {...register("name", {
              required: "This is required",
              minLength: { value: 4, message: "Minimum length should be 4" }
            })}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>


        <FormControl mt={4} isInvalid={errors.adressline1} >
          <FormLabel>Address Line 1</FormLabel>
          <Input
            type="text"
            id="adressline1"
            {...register("adressline1", {
              required: "This is required",
            })}
          />
          <FormErrorMessage>
            {errors.adressline1 && errors.adressline1.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.adressline2}>
          <FormLabel>Address Line 2</FormLabel>
          <Input
            type="text"
            id="adressline2"
            {...register("adressline2", {
              // required: "This is required",
            })}
          />
          <FormErrorMessage>
            {errors.adressline2 && errors.adressline2.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.city}>
          <FormLabel>City</FormLabel>
          <Input
            type="text"
            id="city"
            {...register("city", {
              required: "This is required",
            })}
          />
          <FormErrorMessage>
            {errors.city && errors.city.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.state}>
          <FormLabel>State</FormLabel>
          <Input
            type="text"
            id="state"
            {...register("state", {
              required: "This is required",
            })}
          />
          <FormErrorMessage>
            {errors.state && errors.state.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.country}>
          <FormLabel>Country</FormLabel>
          <Select
            id="country"
            placeholder="Select a Country"
            {...register("country", {
              required: "This is required",
            })}
          >
            <OptionsCountries simple={true} />
          </Select>
          <FormErrorMessage>
            {errors.country && errors.country.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.abbreviation}>
          <FormLabel>Abbreviation</FormLabel>
          <Input
            id="abbreviation"
            type="text"
            {...register("abbreviation", {
              required: "This is required",
              maxLength: { value: 4, message: "Max length should be 4" }
            })} />
          <FormErrorMessage>
            {errors.abbreviation && errors.abbreviation.message}
          </FormErrorMessage>
          <FormHelperText>Maximum 4 characters and it will work as a unique identifier on the platform</FormHelperText>
        </FormControl>

        <FormControl mt={4} isInvalid={errors.typeOrg}>
          <FormLabel>Type</FormLabel>
          <Select
            id="typeOrg"
            placeholder="Select a type"
            {...register("typeOrg", {
              required: "This is required"
            })}
          >
            {Object.keys(typesOrg).map((key: string, index: number) => {
              return (
                <option value={typesOrg[key].value} key={index}>
                  {typesOrg[key].name}
                </option>
              )
            })}
          </Select>
          <FormErrorMessage>
            {errors.typeOrg && errors.typeOrg.message}
          </FormErrorMessage>
          <FormHelperText>Please contact support if you can't find the correct type</FormHelperText>
        </FormControl>

        <ButtonKs.Success
          text={typeForm === 'edit' ? 'Save Changes' : superUserUI ? 'Create Organization' : 'Request new organization'}
          isLoading={isSubmitting}
          type="submit"
          mt={10} />

        <ButtonKs.Cancel
          isDisabled={isSubmitting}
          ml={4}
          mt={10}
          to='/app/organisations'
        />
      </form>
    </Skeleton>
  </Box>

  return (
    <>
      <BreadcrumbsKs
        data={
          [
            { name: 'Organizations', link: '/app/organisations' },
            { name: 'Directory', link: '/app/organisations' },
            { name: capitalize(typeForm), link: null }
          ]
        }
      />
      <BoxResponsiveTwo boxA={7} boxB={typeForm === 'edit' ? 4 : 0}>
        <CenterBox fullWidth>
          <Heading>
            {typeForm === 'create' && 'New Organization'}
            {typeForm === 'edit' && 'Edit Organization'}
          </Heading>
          <Text>
            {typeForm === 'create' && 'Add a new organization to Kitspace Hub'}
            {typeForm === 'edit' && 'Update all the necessary fields'}
          </Text>
          {
            (organisation && organisation.createdBy === user.data.uid) || superUserUI === true || typeForm === 'create' ?
              <MainContent /> :
              <AlertNoAccess />
          }
        </CenterBox>
        {typeForm === 'edit' &&
          <>
            <CenterBox fullWidth mb={4}>
              <Heading>
                Partners
              </Heading>
              <Text mb={8}>
                Organizations this organization can work with
              </Text>
              <Skeleton isLoaded={!loadingPartners && stateRedux.organisationsAccessById[orgId]}>
                {stateRedux.organisationsAccessById[orgId] &&
                  <SelectMultiple
                    isDisabled={!superUserUI || updatingPartners}
                    isMulti
                    defaultValue={stateRedux.organisations.filter((org: any) => {
                      if (org.id && stateRedux?.organisationsAccessById[orgId]?.organisationsPartners?.includes(org.id)) return true
                      return false
                    }).map((organisation: any) => {
                      return { 'value': organisation.id, 'label': organisation.name, 'colorScheme': 'blue' }
                    })}
                    options={stateRedux.organisations.filter((org: any) => {
                      if (org.id && org.id !== orgId) return true
                      return false
                    }).map((organisation: any) => {
                      return { 'value': organisation.id, 'label': organisation.name, 'colorScheme': 'green' }
                    })}
                    onChange={(organisationsId: any) => { setPartners(organisationsId) }}
                    placeholder="Select organisations..."
                    closeMenuOnSelect={false}
                  />
                }
                <ButtonKs.SaveChanges mt={8} isDisabled={!superUserUI} onClick={onSubmitPartners} />
              </Skeleton>
            </CenterBox>
            <CenterBox fullWidth>
              <Heading>
                Places
              </Heading>
              <Text mb={8}>
                Places and they sites with access
              </Text>
              <Skeleton isLoaded={organisation?.placesRelated && statePlacesRedux.loading === false}>
                {organisation?.placesRelated && statePlacesRedux.loading === false &&
                  <SelectMultiple
                    isDisabled={!superUserUI || updatingPlaces}
                    isMulti
                    defaultValue={statePlacesRedux.places.filter((place: any) => {
                      if (organisation?.placesRelated?.includes(place.id)) return true
                      return false
                    }).map((place: any) => {
                      return { 'value': place.id, 'label': place.name, 'colorScheme': 'blue' }
                    })}
                    options={statePlacesRedux.places.filter((org: any) => {
                      if (org.id && org.id !== orgId) return true
                      return false
                    }).map((place: any) => {
                      return { 'value': place.id, 'label': place.name, 'colorScheme': 'green' }
                    })}
                    onChange={(placeId: any) => { setPlaces(placeId) }}
                    placeholder="Select places..."
                    closeMenuOnSelect={false}
                  />
                }
                <ButtonKs.SaveChanges mt={8} isDisabled={!superUserUI} onClick={onSubmitPlaces} />
              </Skeleton>
            </CenterBox>
          </>
        }
      </BoxResponsiveTwo>
    </>
  );
}

export default OrganisationsEdit;