import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import firebase from 'firebase'
import 'firebase/storage';
import addFirstDocument from 'assets/themeisle/undraw_upload_re_pasx.svg'
import dropDocument from 'assets/themeisle/undraw_add_document_re_mbjx.svg'
import { DropZoneHW, ThumbnailFromDropZone, ThumnailZone, ThumbnailText } from './Home/Documents/styles';
import { useImmer } from 'use-immer';
import { Grid, GridItem, Badge, Image, Box, Divider, Icon, Tooltip, Text, useToast } from '@chakra-ui/react';
import { getFileIcon } from 'helpers/files';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { FaTrash, FaCheck } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { actions } from 'services/redux/actions';
import pluralize from 'pluralize';
import { toastTypes } from 'helpers/alerts';

interface Props {
  projId: string,
  documentId?: string,
  multipleFiles: boolean,
  refStorageFn: () => string,
  typeDropZone: 'versionOfDocument' | 'newDocument'
}

function UploadFiles({ projId, documentId, multipleFiles, refStorageFn, typeDropZone }: Props) {

  const dispatch = useDispatch()

  const storage: any = firebase.storage();
  const toast = useToast();

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  // Cover Image ===>
  const [customDocPreview, setCustomDocPreview] = useState<Array<any>>([]);
  const [customDocFile, setCustomDocFile] = useState<Array<Blob>>([]);
  const [customDocFileStatus, setCustomDocFileStatus] = useImmer<any>({ data: {} });


  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    customDocPreview.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [customDocPreview]);

  const onDrop = (acceptedFiles: any) => {
    let tmp: Array<any> = []
    if (isSubmitted || multipleFiles === false) {
      setIsSubmitted(false)
      tmp = [...acceptedFiles]
    } else {
      tmp = [...customDocFile, ...acceptedFiles]
    }

    setCustomDocFile(tmp);

    tmp.map((file: any, key: number) => {
      return setCustomDocFileStatus((draft: any) => {
        draft.data[key] = { status: 'to_upload', fileName: file.name }
      });
    })

    setCustomDocPreview(
      tmp.map((file: any, key: number) => {
        // if (file.size / 1000 > 500)

        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    multiple: multipleFiles
  });


  const resetForm = () => {
    setIsSubmitting(false)
    setIsSubmitted(false)
    setCustomDocPreview([])
    setCustomDocFile([])
    setCustomDocFileStatus((draft: any) => {
      draft.data = {}
    })
  }

  const handleUploadImage = ({ file, refStorage, storage }: any) => {

    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(true);
      } else {

        const refFireStorage = storage.ref(refStorage);
        refFireStorage.put(file).then((snapshot: any) => {
          if (snapshot.state === 'success') {
            snapshot.ref.getDownloadURL().then((downloadURL: string) => {
              const pathImage = snapshot.metadata.fullPath;
              resolve({ pathImage, downloadURL });
            }).catch((err: any) => {
              reject(err)
            })
          }
        }).catch((err: any) => {
          reject(err)
        })
      }
    })
  }

  const handleSaveChanges = () => {

    setIsSubmitting(true)

    Promise.all(
      customDocFile.map(async (file: any, key: number) => {
        await changeFileStatusByKey(key, 'uploading')
        const refStorage = refStorageFn();

        await handleUploadImage({ file, refStorage, storage })
          .then(async ({ pathImage }: any) => {

            const callback = async () => {
              const payload: any = {}
              if (pathImage) payload.customDocPreview = pathImage;
              await changeFileStatusByKey(key, 'success')

              if (customDocFile.length === key + 1) {

                typeDropZone === 'newDocument' && dispatch(actions.projects.getDocumentsByProject(projId))
                typeDropZone === 'versionOfDocument' && toast(toastTypes.success)

                setIsSubmitting(false)
                setIsSubmitted(true)
              }
            }

            switch (typeDropZone) {

              case 'versionOfDocument':
                dispatch(actions.projects.createDocumentVersion(projId, documentId || '', { refStorage: refStorage, fileName: file.name, fileType: file.type }, callback))
                break;

              case 'newDocument':
                dispatch(actions.projects.createDocument(projId, { refStorage: refStorage, fileName: file.name, fileType: file.type }, callback))
                break;

            }

          }).catch(async (err: any) => {
            await changeFileStatusByKey(key, 'error')

            if (customDocFile.length === key + 1) {
              setIsSubmitting(false)
              setIsSubmitted(true)
            }
            console.error(err)
          })

        return true
      })
    )
  }

  const changeFileStatusByKey = (key: number, status: string) => {
    return new Promise((resolve) => {
      const tmp: any = customDocFileStatus
      setCustomDocFileStatus((draft: any) => {
        draft.data[key] = { ...tmp[key], status: status }
      })
      resolve(true)
    })
  }

  const deleteFile = (key: number) => {
    const newStatus: any = {}
    let count: number = 0;
    for (let i of Object.keys(customDocFileStatus.data)) {
      if (parseInt(i) !== key) {
        newStatus[count] = customDocFileStatus.data[i]
        count++
      }
    }

    setCustomDocPreview(customDocPreview.filter((val, index) => key !== index))
    setCustomDocFile(customDocFile.filter((val, index) => key !== index))

    setCustomDocFileStatus((draft: any) => {
      draft.data = newStatus
    });
  }


  return (
    <>

      <DropZoneHW
        haveFile={customDocPreview.length > 0}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {
          isDragActive && !isSubmitting ?
            <Grid
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(1, 1fr)"
              alignItems="center"
            >
              <GridItem mb={2} mt={6}>
                <Image src={dropDocument} margin='0 auto' height={'150px'} /><br />
              </GridItem>
              <GridItem alignContent='center'>
                <Badge ml={4} colorScheme='green'>Drop files here</Badge>
              </GridItem>
            </Grid> :
            <Grid
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(1, 1fr)"
              alignItems="center"
            >
              {
                customDocPreview.length === 0 &&
                <GridItem mb={2} mt={6}>
                  <Image src={addFirstDocument} margin='0 auto' height={'150px'} />
                </GridItem>
              }
              <GridItem alignContent='center'>
                <Badge colorScheme='blue' ml={4}>
                  {
                    multipleFiles ?
                      'Drag and drop or click here to upload files' :
                      'Drag and drop or click here'
                  }
                </Badge>
              </GridItem>
            </Grid>
        }
      </DropZoneHW>
      {customDocPreview.length > 0 &&
        <ThumnailZone>
          <>
            <Divider mb={6} />
            <Text> {customDocPreview.length} {pluralize('file', customDocPreview.length)} </Text>
            <ButtonKs.Default
              mb={9}
              size='xs'
              onClick={resetForm}
              isDisabled={isSubmitting}
            >
              Remove
            </ButtonKs.Default>

            <Grid templateColumns={multipleFiles ? "repeat(4, 1fr)" : "repeat(1,1fr)"} gap={3}>
              {
                customDocPreview.map((file: any, keyX: number) => {
                  const typeFormat = (file.type).split('/')
                  if (typeFormat[0] === 'image') {
                    return (
                      <Box w="100%" key={keyX} pt={3}>
                        <ThumbnailFromDropZone
                          key={file.name || ''}
                          alt={file.name || ''}
                          src={file.preview}
                        />
                        <>
                          <Tooltip label={file.name}>
                            <ThumbnailText>{file.name}</ThumbnailText>
                          </Tooltip>
                          {
                            customDocFileStatus.data[keyX]?.status === 'to_upload'
                            &&
                            <ButtonKs.IconCustom
                              mt={4}
                              icon={<FaTrash />}
                              onClick={() => deleteFile(keyX)}
                              isDisabled={isSubmitting}
                            />
                          }
                          {
                            customDocFileStatus.data[keyX]?.status === 'uploading'
                            &&
                            <ButtonKs.IconCustom
                              mt={4}
                              isLoading
                            />
                          }
                          {
                            customDocFileStatus.data[keyX]?.status === 'success'
                            &&
                            <ButtonKs.IconCustom
                              mt={4}
                              icon={<FaCheck />}
                              colorScheme='green'
                              disabled
                            />
                          }
                          {/* <p>{customDocFileStatus.data[keyX]?.status}</p> */}
                        </>
                      </Box>)
                  } else {
                    return (
                      <Box w="100%" key={keyX} pt={3}>
                        <Box>
                          <Icon boxSize='6em' as={getFileIcon(file.type)} />
                        </Box>
                        <>
                          <Tooltip label={file.name}>
                            <ThumbnailText>{file.name}</ThumbnailText>
                          </Tooltip>
                          {
                            customDocFileStatus.data[keyX]?.status === 'to_upload'
                            &&
                            <ButtonKs.IconCustom
                              mt={4}
                              icon={<FaTrash />}
                              onClick={() => deleteFile(keyX)}
                              isDisabled={isSubmitting}
                            />
                          }
                          {
                            customDocFileStatus.data[keyX]?.status === 'uploading'
                            &&
                            <ButtonKs.IconCustom
                              mt={4}
                              isLoading
                            />
                          }
                          {
                            customDocFileStatus.data[keyX]?.status === 'success'
                            &&
                            <ButtonKs.IconCustom
                              mt={4}
                              icon={<FaCheck />}
                              colorScheme='green'
                              disabled
                            />
                          }
                        </>
                        {/* <p>{customDocFileStatus.data[keyX]?.status}</p> */}
                      </Box>)
                  }
                })
              }
            </Grid>
            <Divider mt={12} mb={6} />
            {
              isSubmitted ?
                <ButtonKs.Primary
                  text={
                    multipleFiles ?
                      'Upload more files' :
                      'Upload another file'
                  }
                  mt={4}
                  onClick={resetForm}
                />
                :
                <ButtonKs.Success
                  mt={4}
                  onClick={handleSaveChanges}
                  isLoading={isSubmitting}
                  loadingText="Submitting"
                >
                  Upload {pluralize('file', customDocPreview.length)}
                </ButtonKs.Success>
            }
          </>

        </ThumnailZone>
      }
    </>
  );
}

export default UploadFiles;