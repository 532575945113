import React from 'react';
import { UIProvider } from 'services/contexts/ContextUI'
import { AppProvider } from 'services/contexts/ContextApp'

function Contexts(props: any) {
  return (
    <AppProvider>
      <UIProvider>
        {props.children}
      </UIProvider>
    </AppProvider>
  );
}

export default Contexts;