import { AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFileExcel, AiOutlineFilePpt, AiFillFile } from "react-icons/ai";
import { FaFileCsv } from "react-icons/fa";
import { GrDocumentTxt } from "react-icons/gr";
import { RiImageLine } from "react-icons/ri";

export const filesTypes: any = {
  'png': RiImageLine,
  'jpg': RiImageLine,
  'jpeg': RiImageLine,
  'pdf': AiOutlineFilePdf,
  'msword': AiOutlineFileWord,
  'docx': AiOutlineFileWord,
  'vnd.openxmlformats-officedocument.wordprocessingml.document': AiOutlineFileWord,
  'xlsx': AiOutlineFileExcel,
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': AiOutlineFileExcel,
  'pptx': AiOutlineFilePpt,
  'vnd.openxmlformats-officedocument.presentationml.presentation': AiOutlineFilePpt,
  'csv': FaFileCsv,
  'txt': GrDocumentTxt,
}

export const getFileIcon = (type: string) => {
  const typeFormat = type ? type.split('/') : null

  if (!typeFormat) return AiFillFile
  return filesTypes[type] || filesTypes[typeFormat[1] || ''] || AiFillFile
}

export const getFileTypeSimple = (type: string) => {
  if (!type) return 'ERROR'
  return type.split('/')[1].toUpperCase() || 'ERROR' 
}