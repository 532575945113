let firebaseConfigJson: any;

firebaseConfigJson = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY || '',
  appId: process.env.REACT_APP_FIREBASE_APPID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
}

const branch = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF && process.env.REACT_APP_VERCEL_GIT_COMMIT_REF !== '' ? process.env.REACT_APP_VERCEL_GIT_COMMIT_REF : 'localhost';

console.log('%c [KSHUB] ====>', 'background: #222; color: #bada55');
console.log('%c | ' + firebaseConfigJson.projectId, 'background: #222; color: #bada55');
console.log('%c | ' + branch, 'background: #222; color: #bada55');
console.log('%c [KSHUB] <====', 'background: #222; color: #bada55');

export const firebaseConfig = firebaseConfigJson;