import AppContext, { AppContextType } from 'services/contexts/ContextApp'
import { useContext } from 'react'
import { useUser } from 'reactfire'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook'
import { useFirebaseHook } from 'services/hooks/useFirebaseHook';
import { useToast } from '@chakra-ui/react'
import { useContextUIHook } from './useContextUIHook';

export function useContextAppHook() {

  const cttApp = useContext(AppContext);
  const user = useUser()
  const toast = useToast()

  const { functions: { getDoc }, firestore: { KSHUBFS } } = useFirestoreHook()
  const { functions: { logout } } = useFirebaseHook()
  const { functions: { superUserSwitch } } = useContextUIHook()


  const forceLogout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname !== 'localhost') {
      logout();
      toast({
        description: "Sorry, but we couldn't find an invitation linked to your email to use the platform. Contact an Administrator.",
        status: "error",
        duration: null,
        isClosable: false,
      });
    }
  }

  const initContextData = (nouserData: any, authorizedInfo: any) => {
    cttApp.FNsetDataImmer((draft: AppContextType) => {
      draft.updatedAt = new Date();
      draft.initialized = true;
      draft.loading = false;
      draft.data.nouser = nouserData;
      draft.data.superUserReference = nouserData ? nouserData.superUserReference : false;
      draft.data.public.authorized = authorizedInfo;
    })

    if (nouserData && nouserData.superUserReference) {
      superUserSwitch(nouserData.superUserReference, false)
    }
  }

  const initContext = () => {

    const emailUser: string = user.data.email || '';
    const domainUser: string = emailUser.substring(emailUser.lastIndexOf("@") + 1);

    getDoc(KSHUBFS.nousers_doc(emailUser)).then((nouserData: any) => {
      getDoc(KSHUBFS.public_authorized()).then((authorizedInfo: any) => {
        // console.info(nouserData, authorizedInfo)

        if (
          authorizedInfo.special.includes(emailUser) ||
          authorizedInfo.domains.includes(domainUser)) {
          initContextData(nouserData, authorizedInfo)
        } else if (nouserData) {
          if (nouserData.appInvitation === true) {
            initContextData(nouserData, authorizedInfo)
          } else {
            console.info("USER DONT HAVE INVITATION")
            forceLogout()
          }
        } else {
          console.info("USER DONT HAVE NOUSER DOC")
          forceLogout()
        }

      }).catch((err: any) => {
        console.info(err)
        forceLogout()
      })
    }).catch((err: any) => {
      console.info(err)
      forceLogout()
    })
  }

  return {
    functions: {
      initContext
    },
    data: cttApp.data
  }
}