import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { Box, Icon, MenuGroup, MenuItem, Table, Tbody, Td, Th, Thead, Tr, Text, Stack, Badge, MenuDivider, useToast } from '@chakra-ui/react';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import React, { useEffect, useState } from 'react';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { Link, } from "react-router-dom";
import { useTable, useSortBy } from 'react-table';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { useInsightsHook } from 'services/hooks/useInsightsHook';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import { daysString } from 'helpers/mini';
import { projectStatus } from 'helpers/kshub';
import EditReportProject from './EditReportProject';
import { actions } from 'services/redux/actions';
import { toastTypes } from 'helpers/alerts';

function InsightsReports() {

  const { data: { superUserUI } } = useContextUIHook()
  const { data: { organisationsLoading, organisationsById } } = useOrganisationHook();
  const { data: { projectReportsById, insightsLoading }, fun: { updateProjectsReports } } = useInsightsHook();

  const stateRedux = useSelector((store: any) => store.placeReducer);
  const dispatch = useDispatch();
  const toast = useToast();

  const [dataTable, setDataTable] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [createMode, setCreateMode] = useState(false)
  const [editModeReportId, setEditModeReportId] = useState<null | string>(null)

  useEffect(() => {
    setLoading(stateRedux.loading)
  }, [stateRedux.loading]);

  const refreshTable = () => {
    updateProjectsReports()
  }

  useEffect(() => {
    setDataTable(Object.keys(projectReportsById).map((key: any) => projectReportsById[key]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectReportsById]);

  const sendNow = (projectReportId: string) => {
    toast(toastTypes.processingLong)
    dispatch(actions.insights.sendNow(projectReportId, (status: boolean) => {
      status ? toast(toastTypes.success) : toast(toastTypes.error)
    }))
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Channels',
        accessor: 'channels',
        Cell: (props: any) => {
          const orgsId = props.cell.value;
          return (
            <>
              <Text mb={2}>
                {orgsId &&
                  orgsId.map((orgId: string, index: number) => {
                    return (
                      organisationsById && organisationsById[orgId] ?
                        <> {organisationsById[orgId].name}{orgsId.length > index + 1 && ','} </> :
                        orgId
                    )
                  })
                }
              </Text>
            </>
          );
        },
      },
      {
        Header: 'Delivery',
        id: 'scheduledTask_active',
        accessor: 'scheduledTask',
        Cell: (props: any) => {
          const scheduledTask = props.cell.value;
          return (
            <Box maxWidth={200}>
              <Stack direction="row">
                <Icon
                  as={scheduledTask.active ? RiCheckboxCircleLine : RiCheckboxBlankCircleLine}
                  boxSize='2em'
                  color='green'
                  marginTop={1}
                />
              </Stack>
            </Box>
          );
        },
      },
      {
        Header: 'Days',
        id: 'scheduledTask_runDays',
        accessor: 'scheduledTask',
        Cell: (props: any) => {
          const scheduledTask = props.cell.value;

          return (
            <>
              {scheduledTask.runDays.map((dayNumber: number, index: number) => {

                return (
                  <> {daysString[dayNumber - 1]}{scheduledTask.runDays.length > index + 1 && ','} </>
                )
              })}
            </>
          )
        },
      },
      {
        Header: 'Time',
        id: 'scheduledTask_runHour',
        accessor: 'scheduledTask',
        Cell: (props: any) => {
          const scheduledTask = props.cell.value;

          return (
            <>
              {scheduledTask.runHour}hs
            </>
          )
        },
      },
      {
        Header: 'Project Status Filter',
        accessor: 'projectStatusFilter',
        Cell: (props: any) => {
          const projectStatusFilter = props.cell.value

          return (
            <>
              {projectStatusFilter.map((statusId: string) => {
                return (
                  <Badge variant="solid" size='' m={1}>
                    {projectStatus[statusId] ? projectStatus[statusId].name : statusId}
                  </Badge>)
              })}
            </>
          )
        },
      },
      {
        Header: 'Recipents',
        id: 'scheduledTask_notifEmail',
        accessor: 'scheduledTask',
        Cell: (props: any) => {
          const scheduledTask = props.cell.value

          return (
            <>
              {
                scheduledTask.notifEmail.map((email: string) => {
                  return (
                    <Badge variant="outline" colorScheme="blue" textTransform="lowercase" fontSize='0.9em' m={1}>
                      {email}
                    </Badge>
                  )
                })
              }
            </>
          )
        },
      }
    ],
    [organisationsById]
  )

  const initialStateTable: any = {
    columns: columns,
    data: dataTable
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  return (
    <Box>
      <BreadcrumbsKs
        data={
          [
            { name: 'Home', link: '/app/projects' },
            { name: 'Insights', link: '/app/insights' },
            { name: 'Reports', link: null }
          ]
        }
      />
      <HeaderKs
        title='Project Overview'
        subtitle='The project overview report shows all projects in your organization. Admins can schedule delivery of the PDF report to be sent to a list of email addresses.'
      >
        {!createMode ?
          <ButtonKs.Create
            onClick={() => setCreateMode(!createMode)}
            text={'Add report'}
            ml={2}
          />
          :
          <ButtonKs.Cancel
            onClick={() => setCreateMode(!createMode)}
            text={'Cancel'}
            ml={2}
          />
        }
        <ButtonKs.Refresh
          onClick={() => refreshTable()}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      {
        (!loading && !organisationsLoading && !insightsLoading) &&
        <Box maxWidth='100%' minHeight='100vh' overflow='auto'>
          <Table variant="striped" mt={4}  {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup: any) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <Th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span style={{ marginLeft: '5px' }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownIcon />
                            : <ArrowUpIcon />
                          : ''}
                      </span>
                    </Th>
                  ))}
                  <Th>
                    Options
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {createMode &&
                <EditReportProject mode='new' />
              }
              {
                rows.map((row: any, index: number) => {
                  prepareRow(row)

                  if (editModeReportId && editModeReportId === row.original.id) {
                    return (
                      <EditReportProject mode='edit' data={row.original} cancelEdit={() => setEditModeReportId(null)} />
                    )
                  }

                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <Td {...cell.getCellProps()} headerTitle={cell.column.Header}>
                            {cell.render('Cell')}
                          </Td>
                        )
                      })}
                      <Td headerTitle='Options'>
                        <ButtonKs.OptionsMenu bgColor={index % 2 ? 'gray.100' : 'white'}>
                          {superUserUI &&
                            <MenuGroup title="Super User">
                              <MenuItem
                                isDisabled={!superUserUI}
                                as={Link}
                                to='/app/superuser/scheduled'
                              >
                                View task scheduled linked
                              </MenuItem>
                            </MenuGroup>
                          }
                          <MenuGroup title="Options">
                            <MenuItem
                              isDisabled={!superUserUI}
                              as={Link}
                              target="_blank"
                              to={`/private/ingishts/project-report/${row.original.id}?jwt=${row.original.jwt}`}
                            >
                              View (Magic Link)
                            </MenuItem>
                            <MenuItem
                              isDisabled={!superUserUI}
                              onClick={() => setEditModeReportId(row.original.id)}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              isDisabled={!superUserUI}
                              onClick={() => sendNow(row.original.id)}
                            >
                              Send Now
                            </MenuItem>
                          </MenuGroup>
                          <MenuDivider />
                          <MenuGroup title="Coming Soon">
                            <MenuItem
                              isDisabled={true}
                            >
                              Download
                            </MenuItem>
                            <MenuItem
                              isDisabled={true}
                            >
                              View past reports
                            </MenuItem>
                            <MenuItem
                              isDisabled={true}
                            >
                              Delete
                            </MenuItem>
                          </MenuGroup>
                        </ButtonKs.OptionsMenu>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>
      }
      {
        (loading || organisationsLoading || insightsLoading) &&
        <LoadingTable columns={5} />
      }
    </Box >
  );
}

export default InsightsReports;