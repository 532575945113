import { Grid, GridItem, WrapItem, Divider } from '@chakra-ui/layout';
import React from 'react';
import DateBadge from '../Time/DateBadge';
import UserBadge from '../User/UserBadge';

function index({ resource }: any) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" mt={12} gap={6}>
      <GridItem colSpan={3}>
        <UserBadge uid={resource?.createdBy} type='created'>
          <DateBadge seconds={resource?.createdAt?._seconds} format='fromnow' />
        </UserBadge>
      </GridItem>
      <GridItem colSpan={3}>
        <WrapItem>
          <UserBadge uid={resource?.updatedBy} type='updated'>
            <DateBadge seconds={resource?.updatedAt?._seconds} format='fromnow' />
          </UserBadge>
        </WrapItem>
      </GridItem>
      <GridItem colSpan={6}>
        <Divider />
      </GridItem>
    </Grid>
  );
}

export default index;