import { countries } from 'countries-list'

export const countriesList: any = countries;

export const countriesSelect = () => {
  const res: Array<any> = Object.keys(countriesList).map((key) => {
    return {
      key: key, value: key, text: countriesList[key].name
    }
  });

  return res;
}

export const countryByName = (name: string) => {
  let result: any = {};

  Object.keys(countriesList).map((key) => {
    if (name === countriesList[key].name) {
      result = countriesList[key]
    }
    return true
  });

  return result;
}