import {
  Heading,
  Text,
  Image,
  Divider,
} from '@chakra-ui/react';
import svg116 from 'assets/themeisle/116.svg'
import React from 'react';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import { useIntl } from 'react-intl';

function BoxInfo() {

  const intl = useIntl()

  return (
    <BoxShadow>
      <Heading fontSize="xl">
        {intl
          .formatMessage(
            {
              id: 'ks.superuser.home.info.title'
            }
          )
        }
      </Heading>
      <Text mt={1} mb={6} fontSize="sm">
        {intl
          .formatMessage(
            {
              id: 'ks.superuser.home.info.subtitle'
            }
          )
        }
      </Text>
      <Image src={svg116} maxHeight="300px" margin="0 auto" mb={6} />
      <Text>
        {intl
          .formatMessage(
            {
              id: 'ks.superuser.home.info.text_a'
            }
          )
        }
      </Text>
      <Divider mt={6} mb={6} />
      <b>
        {intl
          .formatMessage(
            {
              id: 'ks.superuser.home.info.text_b'
            }
          )
        }
      </b>
      {intl
        .formatMessage(
          {
            id: 'ks.superuser.home.info.text_c'
          }
        )
      }
    </BoxShadow>
  );
}

export default BoxInfo;