import * as organisations from './organisation/organisation.actions'
import * as projects from './project/project.actions'
import * as places from './place/place.actions'
import * as users from './user/user.actions'
import * as scheduledtasks from './scheduledtasks/scheduledtasks.actions'
import * as insights from './insights/insights.actions'

export const actions = {
  organisations,
  projects,
  places,
  scheduledtasks,
  users,
  insights
}