import React, { useEffect, useState } from 'react';
import { Skeleton, FormControl, FormErrorMessage, Box, Select, FormLabel, Heading, Input, Text, useToast, Grid, GridItem } from '@chakra-ui/react'
import { Controller, useForm } from "react-hook-form";
import { projectConfidence } from 'helpers/kshub';
import { useHistory, useParams } from 'react-router-dom';
import capitalize from 'capitalize'
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from 'reactfire';
import { avoidEmptyArray } from 'helpers/mini';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { actions } from 'services/redux/actions'
import CenterBox from 'pages/Components/generic/CenterBox';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import AlertNoAccess from 'pages/Components/generic/Alerts/AlertNoAccess';
import SelectMultiple from 'pages/Components/generic/SelectMultiple'
import { toastTypes } from 'helpers/alerts';

interface Props {
  typeForm: 'create' | 'edit'
}

function ProjectsGroupEdit({ typeForm }: Props) {

  const { register, handleSubmit, reset, control, setValue, formState: { errors, isSubmitting } } = useForm();

  const { projectGroupId } = useParams<any>(); // only in case of 'edit'
  const { data: { superUserUI } } = useContextUIHook()

  const toast = useToast()
  const user: any = useUser();
  const history = useHistory();

  const stateRedux = useSelector((store: any) => store.projectReducer);
  const dispatch = useDispatch();

  const [projectGroup, setProjectGroup] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const onSubmit = (values: any) => {
    toast(toastTypes.processing)

    return new Promise((resolve, reject) => {
      const payload = values;
      payload.projects = avoidEmptyArray(
        values.projects ?
          values.projects.map((project: any) => project?.value ? project.value : null)
          : []
      )


      if (typeForm === 'create') {
        dispatch(actions.projects.createProjectGroup(payload, (status: any, info: any) => {
          if (status === true) {

            toast(toastTypes.created)

            setTimeout(() => {
              reset();
              resolve(true)
              history.push(`/app/projects-groups`);
            }, 2000)

          } else {
            toast(toastTypes.error)
            reject(false)
          }
        }))
      } else if (typeForm === 'edit') {
        dispatch(actions.projects.updateProjectGroup(projectGroupId, payload, (status: any, info: any) => {
          if (status === true) {
            toast(toastTypes.updated)
            history.push(`/app/projects-groups`);
            resolve(true)
          } else {
            toast(toastTypes.error)
          }
        }))
      }
    })
  }

  useEffect(() => {
    dispatch(actions.projects.getProjects())
    if (typeForm === 'edit') {
      setLoading(true)
      dispatch(actions.projects.getProjectGroupById(projectGroupId, false, null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeForm === 'edit' && stateRedux?.projectsGroupsById && stateRedux.projectsGroupsById[projectGroupId] && stateRedux.projects) {

      const availableFields = ['name', 'projects', 'confidence']
      const projectGroupData = stateRedux.projectsGroupsById[projectGroupId];

      setProjectGroup(projectGroupData)
      Object.keys(projectGroupData).map((key: string) => {
        if (availableFields.includes(key)) {
          return setValue(key, projectGroupData[key])
        }
        return false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.projectsGroupsById, stateRedux.projects]);

  useEffect(() => {
    if (stateRedux.loadingProjectsGroups !== null) {
      setLoading(stateRedux.loadingProjectsGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.loadingProjectsGroups]);

  const MainContent = () => <Box mt={8}>
    <Skeleton isLoaded={!loading}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Grid templateColumns="repeat(3, 1fr)" mt={4} gap={6}>
          <GridItem colSpan={3}>
            <FormControl isInvalid={errors.name}>
              <FormLabel>Project Group Name</FormLabel>
              <Input
                type="text"
                id="name"
                {...register("name", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <FormControl mt={4}>
          <FormLabel>Projects</FormLabel>
          <Controller
            control={control}
            name='projects'
            render={({ field }) => {
              return (
                <SelectMultiple
                  isMulti
                  defaultValue={
                    stateRedux.projects.map((project: any) => {
                      if (project.id && field?.value?.includes(project.id)) {
                        return { 'value': project.id, 'label': project.name, 'colorScheme': 'blue' }
                      }
                      return false
                    })
                  }
                  options={
                    stateRedux.projects.map((project: any) => {
                      if (project.id) {
                        return { 'value': project.id, 'label': project.name, 'colorScheme': 'blue' }
                      }
                      return false
                    })
                  }
                  onChange={(projects: any) => {
                    field.onChange(projects)
                  }}
                  placeholder="Select some projects..."
                  closeMenuOnSelect={false}
                />)
            }
            } />
        </FormControl>

        <FormControl mt={4} isInvalid={errors.confidence}>
          <FormLabel>Confidence</FormLabel>
          <Select
            id="confidence"
            placeholder="Select a confidence level"
            {...register("confidence", {
              required: "This is required"
            })}
            defaultValue='normal'
          >
            {Object.keys(projectConfidence).map((key: string, index: number) => {
              return (
                <option value={projectConfidence[key].value} key={index}>
                  {projectConfidence[key].name}
                </option>
              )
            })}
          </Select>
          <FormErrorMessage>
            {errors.confidence && errors.confidence.message}
          </FormErrorMessage>
        </FormControl>

        <ButtonKs.Success
          text={typeForm === 'create' ? 'Create Project Group' : 'Save Changes'}
          isLoading={isSubmitting}
          type="submit"
          mt={10} />

        <ButtonKs.Cancel
          isDisabled={isSubmitting}
          ml={4}
          mt={10}
          to='/app/projects-groups'
        />
      </form>
    </Skeleton>
  </Box>

  return (
    <>
      <BreadcrumbsKs
        data={
          [
            { name: 'Project Groups', link: '/app/projects-groups' },
            { name: 'Directory', link: '/app/projects-groups' },
            { name: capitalize(typeForm), link: null }
          ]
        }
      />
      <CenterBox>
        <Heading>
          {typeForm === 'create' && 'New Project Group'}
          {typeForm === 'edit' && 'Edit Project Group'}
        </Heading>
        <Text>
          {typeForm === 'create' && 'Add a new project group to Kitspace Hub'}
          {typeForm === 'edit' && 'Update all the necessary fields'}
        </Text>

        {
          (projectGroup && (projectGroup.createdBy === user.data.uid || projectGroup.ownedBy === user.data.uid))
            || superUserUI === true
            || typeForm === 'create' ?
            <MainContent /> :
            <AlertNoAccess />
        }
      </CenterBox>
    </>
  );
}

export default ProjectsGroupEdit;