import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useUserHook } from 'services/hooks/useUserHook';
import { usePlaceHook } from 'services/hooks/usePlaceHook';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import DateBadge from 'pages/Components/generic/Time/DateBadge';
import { projectStatus } from 'helpers/kshub';
import { Avatar, Badge, Box, Grid, Heading, Wrap, WrapItem, Select, Flex, Tooltip, useToast } from '@chakra-ui/react';
import LoadingGeneric from 'pages/Components/generic/Loading/LoadingGeneric';
import { actions } from 'services/redux/actions'
import { ButtonKs } from 'pages/Components/generic/Buttons';
import CenterBox from 'pages/Components/generic/CenterBox';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import { toastTypes } from 'helpers/alerts';

function Introduction({ projId, projData, projAccessWrite, projAccessRead }: any) {

  const dispatch = useDispatch();
  const stateRedux = useSelector((store: any) => store.projectReducer);

  const [loading, setLoading] = useState(true)
  const [newStatus, setNewStatus] = useState(projData.currentStatus);
  const [newStatusEditMode, setnewStatusEditMode] = useState(false);
  const [projAccessSummary, setProjAccessSummary] = useState<Array<string>>([]);
  const toast = useToast();

  const { functions: { getNameByUID } } = useUserHook()
  const { fun: { getPlaceById } } = usePlaceHook()
  const { fun: { getOrgById } } = useOrganisationHook()

  useEffect(() => {
    if (newStatus !== projData.currentStatus) {
      setnewStatusEditMode(true)
    } else {
      setnewStatusEditMode(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStatus])

  useEffect(() => {
    !projData ? setLoading(true) : setLoading(false)
  }, [projData])

  useEffect(() => {
    const summary: Array<string> = [];
    projAccessWrite.map((uid: string) => summary.push(uid))
    projAccessRead.map((uid: string) => !summary.includes(uid) && summary.push(uid))
    setProjAccessSummary(summary)
  }, [projAccessWrite, projAccessRead])

  const saveChangeNewStatus = () => {
    toast(toastTypes.processing)
    setLoading(true)
    dispatch(actions.projects.updateProjectStatus(projId, { currentStatus: newStatus }, () => {
      setLoading(false)
      setnewStatusEditMode(false)
      toast(toastTypes.saved)
    }))
  }

  const updateData = () => {
    dispatch(actions.projects.getProjectById(projId, false, null))
  }

  useEffect(() => {
    if (loading !== stateRedux.loading) setLoading(stateRedux.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.loading]);

  const respProps = [
    `col-start / span 4`,
    `col-start / span 4`,
    `col-start / span 4`,
    `col-start / span 2`
  ]

  const respProps2 = [
    `col-start / span 4`,
    `col-start / span 4`,
    `col-start / span 4`,
    `col-start 3/ span 4`
  ]

  return (
    <>
      {loading === true ?
        <CenterBox fullWidth>
          <HeaderKs
            size='lg'
            title='Project Home'
          >
            <ButtonKs.Refresh
              disabled
              ml={2}
              mr={3}
            />
          </HeaderKs>
          <LoadingGeneric boxes={2} />
        </CenterBox>
        :
        <CenterBox fullWidth height='100%'>
          <Box display='flex' flexDirection='column' height='100%'>
            <Box flex='1 0 auto'>
              <Heading as="h3" size="lg">
              </Heading>
              <HeaderKs
                size='lg'
                title={`Project Home: ${projData.name}`}
              >
                <ButtonKs.Refresh
                  onClick={() => updateData()}
                  ml={2}
                  mr={3}
                />
              </HeaderKs>
              <Box mt="20px" mb="20px" display='grid' gridGap='0px' gridAutoFlow='dense' gridTemplateColumns='repeat(4, [col-start] 1fr)'>
                <Box gridColumn={respProps} p={2}>
                  <Wrap spacing="30px" align="center">
                    <WrapItem>
                      Originator:
                    </WrapItem>
                    <WrapItem>
                      <Badge mr={2} mt={1}>{getNameByUID(projData.createdBy)}</Badge>
                      <DateBadge seconds={projData.createdAt ? projData.createdAt._seconds : null} withoutP />
                    </WrapItem>
                  </Wrap>
                </Box>
                <Box gridColumn={respProps2} p={2}>
                  <Wrap spacing="30px" align="center">
                    <WrapItem>
                      Last updated by:
                    </WrapItem>
                    <WrapItem>
                      <Badge mr={2} mt={1}>{getNameByUID(projData.updatedBy)}</Badge>
                      <DateBadge seconds={projData.updatedAt ? projData.updatedAt._seconds : null} withoutP />
                    </WrapItem>
                  </Wrap>
                </Box>
                <Box gridColumn={respProps} p={2}>
                  Owned by organization:
                  <Badge ml={4}>{getOrgById(projData.orgId)}</Badge>
                </Box>
                <Box gridColumn={respProps2} p={2}>
                  Project Owner:
                  <Badge ml={4}>{getNameByUID(projData.projectOwnerUid)}</Badge>
                </Box>
                <Box gridColumn={respProps} p={2}>
                  Channel:
                  <Badge ml={4}>{
                    projData.channelId ?
                      getOrgById(projData.channelId) :
                      'Without Channel'
                  }
                  </Badge>
                </Box>
                <Box gridColumn={respProps2} p={2}>
                  {/* Site:
                <Badge ml={4}>Gran via 12</Badge> */}
                </Box>
                <Box gridColumn={respProps} p={2}>
                  Place:
                  <Badge ml={4}>{getPlaceById(projData.placeId)}</Badge>
                </Box>
              </Box>
              <Grid templateColumns="repeat(3, 1fr)" gap={1} mt={6}>
                <Box w="100%" h="10">
                  Target completion<br />
                  {
                    projData.dateProjectedCompletion &&
                    moment
                      .utc(
                        projData.dateProjectedCompletion._seconds ?
                          projData.dateProjectedCompletion._seconds * 1000 :
                          moment(projData.dateProjectedCompletion))
                      .format("DD/MM/YY")
                  }
                </Box>
                <Box w="100%" h="10">
                  Projected completion<br />
                  {
                    projData.dateTargetCompletion &&
                    moment
                      .utc(
                        projData.dateTargetCompletion._seconds ?
                          projData.dateTargetCompletion._seconds * 1000 :
                          moment(projData.dateTargetCompletion))
                      .format("DD/MM/YY")
                  }
                </Box>
              </Grid>
              <Grid templateColumns="repeat(1, 1fr)" gap={1} mt={12}>
                <Box w="100%" h="10">
                  Project Memebers
                </Box>
                <Box w="100%" h="10" mb={10}>
                  <Wrap>
                    {
                      projAccessSummary.map((uid: string, index: number) => {
                        return (
                          <Tooltip key={index} label={getNameByUID(uid)} fontSize="md">
                            <Avatar name={getNameByUID(uid)} cursor='pointer' />
                          </Tooltip>
                        )
                      })
                    }
                  </Wrap>
                </Box>
              </Grid>
            </Box>
            <footer style={{ flexShrink: 0 }}>
              <Grid templateColumns="repeat(1, 1fr)" gap={1}>
                <Box w="100%" h="10">
                  <Flex>
                    {/* {newStatusEditMode && projData &&
                      <Select
                        id="currentStatus"
                        placeholder="Select a Status"
                        disabled={!newStatusEditMode}
                        value={newStatus}
                        onChange={(e: any) => {
                          setNewStatus(e.target.value)
                        }}
                      >
                        {Object.keys(projectStatus).map((key: string, index: number) => {
                          return (
                            <option
                              // selected={projectStatus[key].value === projData.currentStatus}
                              key={index}>
                              {projectStatus[key].name}
                            </option>
                          )
                        })}
                      </Select>
                    } */}
                    {
                      projData &&
                      <Select
                        id="currentStatus"
                        placeholder="Select a Status"
                        value={newStatus}
                        onChange={(e: any) => {
                          if (e.target.value) {
                            setNewStatus(e.target.value)
                          } else {
                            setNewStatus(projData.currentStatus)
                            setnewStatusEditMode(false)
                          }
                        }}
                      >
                        {Object.keys(projectStatus).map((key: string, index: number) => {
                          return (
                            <option
                              key={index}
                              value={projectStatus[key].value}
                              selected={projectStatus[key].value === projData.currentStatus}
                            >
                              {projectStatus[key].name}
                            </option>
                          )
                        })}
                      </Select>
                    }
                    <ButtonKs.Default ml={4} onClick={() => {
                      setnewStatusEditMode(!newStatusEditMode)
                      setNewStatus(projData.currentStatus)
                    }}>
                      {!newStatusEditMode ? 'Edit' : 'Cancel'}
                    </ButtonKs.Default>
                    {
                      newStatusEditMode &&
                      <ButtonKs.SaveChanges
                        ml={2}
                        onClick={saveChangeNewStatus}
                        shortVersion
                      />
                    }
                  </Flex>
                </Box>
              </Grid>
            </footer>
          </Box>
        </CenterBox>
      }
    </>
  );
}

export default Introduction;