import React from 'react';
import { Badge } from '@chakra-ui/react'
import { useIntl } from 'react-intl';

function UserActive({ ...props }: any) {
  const intl = useIntl()

  return (
    <Badge variant="solid" colorScheme="green" {...props}>
      {
        intl.formatMessage(
          { id: 'ks.app.active' }
        )
      }
    </Badge>
  );
}

function UserSuspend({ ...props }: any) {
  const intl = useIntl()

  return (
    <Badge variant="solid" colorScheme="red" {...props}>
      {
        intl.formatMessage(
          { id: 'ks.app.suspend' }
        )
      }
    </Badge>
  );
}

function UserWithoutAccount({ ...props }: any) {
  const intl = useIntl()

  return (
    <Badge variant="solid" colorScheme="yellow" {...props}>
      {
        intl.formatMessage(
          { id: 'ks.app.without_account' }
        )
      }
    </Badge>
  );
}

function InvitationInvited({ ...props }: any) {
  const intl = useIntl()

  return (
    <Badge variant="solid" colorScheme="blue" {...props}>
      {
        intl.formatMessage(
          { id: 'ks.app.invited' }
        )
      }
    </Badge>
  );
}

function InvitationCanceled({ ...props }: any) {
  const intl = useIntl()

  return (
    <Badge variant="solid" colorScheme="red" {...props}>
      {
        intl.formatMessage(
          { id: 'ks.app.canceled' }
        )
      }
    </Badge>
  );
}

function InvitationStatusAccepted({ ...props }: any) {
  const intl = useIntl()

  return (
    <Badge variant="solid" colorScheme="green" textDecoration={props.strike && 'line-through'} {...props}>
      Yes, {
        intl.formatMessage(
          { id: 'ks.app.accepted' }
        )
      }
    </Badge>
  );
}

function InvitationStatusOpen({ ...props }: any) {
  const intl = useIntl()

  return (
    <Badge variant="solid" colorScheme="yellow" textDecoration={props.strike && 'line-through'} {...props}>
      No, {
        intl.formatMessage(
          { id: 'ks.app.open' }
        )
      }
    </Badge>
  );
}

const BadgesAccess = {
  UserActive,
  UserSuspend,
  UserWithoutAccount,
  InvitationInvited,
  InvitationCanceled,
  InvitationStatusAccepted,
  InvitationStatusOpen,
}

export default BadgesAccess;