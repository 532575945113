const prefix = '@KS_HUB/';

export const LOCAL_ST: any = {
  firstUrlOpen: 'firstUrlOpen',
  settingsLanguage: 'settingsLanguage'
}

const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const localSt = {
  clear: () => {
    return localStorage.clear();
  },
  defineNow: (key: string, value: any) => {
    let payload: any = value;

    if (typeof payload === 'object' && payload !== null) {
      payload = JSON.stringify({ [key]: value })
    }

    //console.info(`DEFINE: '${LOCAL_ST[key]}' -> ${payload}`);
    return localStorage.setItem(prefix + LOCAL_ST[key], payload);
  },
  mergeNow: (key: string, value: any) => {
    let payload: any = value;

    let res: any = localStorage.getItem(prefix + LOCAL_ST[key]);
    let result = {}
    if (res) {
      result = { [key]: { ...JSON.parse(res)[key], ...payload } };
    } else {
      result = { [key]: { ...payload } };
    }
    let final = JSON.stringify(result);

    return localStorage.setItem(prefix + LOCAL_ST[key], final);
  },
  obtainNow: (key: string) => {
    if (LOCAL_ST[key]) {
      let res: string = localStorage.getItem(prefix + LOCAL_ST[key]) || '';
      if (isJson(res)) {
        // Every json must to save with same key like storage key
        // i.e myKey : { myKey : { ...info }}
        return JSON.parse(res)[key];
      }
      return res;
    } else {
      console.warn(`ERROR IN OBT: ${key} not exist in constants`);
    }
  },
}

export const sessionSt = {
  clear: () => {
    return sessionStorage.clear()
  },
  clearByKey: (items = []) => {
    items.forEach((key) => {
      sessionStorage.removeItem(key);
    })
    return;
  }
}