// Import FirebaseAuth and firebase.
import React, { useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { useAuth } from 'reactfire';
import { LOCAL_ST, localSt } from 'helpers/local-session-storage'
import { Box, Heading, FormControl, Grid, VStack } from "@chakra-ui/react"
import { useHistory } from "react-router-dom";
import { ColorModeSwitcher } from "pages/Components/layout/ColorModeSwitcher"

interface LoginProps {
  path?: any;
}

export function LoginScreen(props: LoginProps) {

  const history = useHistory();
  const authFirebase = useAuth();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId: '721396587607-hkqqhs7cdddue0h4oub1n7am2bla8mo5.apps.googleusercontent.com',
        // customParameters: {
        //   hd: 'kitspace.com'
        // }
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any) => {
        const defaultOrg = localSt.obtainNow(LOCAL_ST.defaultOrganisation);
        const lastUrl = localSt.obtainNow(LOCAL_ST.firstUrlOpen);

        if (lastUrl) {
          history.push(lastUrl)
        } else if (defaultOrg) {
          history.push('/app/org/' + defaultOrg + '/questions');
        } else {
          history.push('/');
        }

        return false;
      },
      signInFailure: (error: any) => {

      },
      uiShown: () => {

      },
    },
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: 'platerocm.com',
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign('wikipedia.org');
    }
  };

  useEffect((): any => {
    // if (window.location.pathname !== '/login') history.push('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Box>
              <Heading>
                Welcome <br />
                Kitspace Hub
              </Heading>
              <FormControl size='large'>
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={authFirebase}
                />
              </FormControl>
            </Box>
          </VStack>
        </Grid>
      </Box>
    </>
  );
};