import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions'

export function useUserHook() {

  const stateRedux = useSelector((store: any) => store.userReducer);
  const dispatch = useDispatch();

  const getNameByUID = (uid: string) => {
    if (stateRedux.usersById) {
      if (stateRedux.usersById[uid] && stateRedux.usersById[uid].displayName) return stateRedux.usersById[uid].displayName
      return 'Unknown user'
    }
    return 'Error User Data'
  }

  const refreshUsers = () => {
    dispatch(actions.users.getAllUsers())
  }

  return {
    data: {
      usersUpdatedAt: stateRedux.updatedAt ? moment(stateRedux.updatedAt).fromNow() : 'Invalid Date',
      usersById: stateRedux.usersById,
      users: stateRedux.users,
      usersForMultiSelect: stateRedux.users.map((user: any) => {
        return { 'value': user.id, 'label': user.displayName, 'colorScheme': 'green' }
      }),
      usersForSelect: stateRedux.users.map((user: any) => {
        return { 'value': user.id, 'text': user.displayName }
      }),
    },
    functions: {
      getNameByUID,
      refreshUsers,
    }
  }
}