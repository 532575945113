import { Avatar } from '@chakra-ui/avatar';
import { IconButton } from '@chakra-ui/button';
import { Heading, Badge, Grid, GridItem, Flex, Box, Spacer } from '@chakra-ui/layout';
import { Tooltip, Textarea, Select } from '@chakra-ui/react';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import React from 'react';
import { useUser } from 'reactfire';
import { FaInfo } from 'react-icons/fa';
import { useUserHook } from 'services/hooks/useUserHook';

function Comments() {
  const user = useUser()
  const { functions: { getNameByUID } } = useUserHook()

  return (
    <>
      <Heading as="h3" size={'xl'} pt={2} mb={8}>
        Comments <Badge>Coming soon</Badge>
      </Heading>

      <Grid
        templateColumns='repeat(12, 1fr)'
        gap={4}
      >
        <GridItem rowSpan={1} colSpan={12} >
          <Flex>
            <Tooltip label={getNameByUID(user.data.uid)} fontSize="md">
              <Avatar name={getNameByUID(user.data.uid)} cursor='pointer' size="sm" mr={4} />
            </Tooltip>
            <Textarea placeholder='Write a comment...' disabled />
          </Flex>

          <Flex>
            <Box>

            </Box>
            <Spacer />
            <Box>
              <Flex>
                <Tooltip label='The comment can be related to a specific version of the file.'>
                  <IconButton icon={<FaInfo />} size='sm' m={2} borderRadius='lg' aria-label='information' />
                </Tooltip>
                <Select size='sm' placeholder='Select option' m={2} mr={4} width='auto'>
                  <option value='1' selected>V1</option>
                </Select>
                <ButtonKs.Primary size='sm' m={2} mr={0} disabled>Comment</ButtonKs.Primary>
              </Flex>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
}

export default Comments;