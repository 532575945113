import { useFirestore, useUser } from 'reactfire'
import firebase from 'firebase/app'
import 'firebase/firestore';

export function useFirestoreHook() {

  // Keep this like only instance of Firestore in the entire App
  const firestore = useFirestore();
  const { data: USER }: any = useUser();

  const SDK: any = {
    documentId() {
      return firebase.firestore.FieldPath.documentId()
    },
    arrayUnion(value: any) {
      return firebase.firestore.FieldValue.arrayUnion(value)
    },
    arrayRemove(value: any) {
      return firebase.firestore.FieldValue.arrayRemove(value)
    }
  }

  const KSHUBFS: any = { // KSHUBFS = Kitspace Hub Firestore
    'nousers': firestore.collection('nousers'),
    'nousers_doc'(email: string) {
      return this.nousers.doc(email)
    },
    'nousers_doc_invitations'(email: string) {
      return this.nousers.doc(email).collection('invitations')
    },
    'public': firestore.collection('public'),
    'public_authorized'() {
      return this.public.doc('authorized')
    },
  }

  const FSorganisations = firestore.collection('organisations');

  const addDoc = (path: any, dataX: any, meta?: any) => {

    let data = dataX;

    data.createdBy = USER.uid;
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    if (!dataX.status) {
      data.status = 200;
    }

    return new Promise((resolve, reject) => {

      if (meta && meta.id) {
        path.doc(meta.id).set(data).then((res: any) => {
          resolve(res)
        }).catch((err: any) => {
          reject(err);
        })
      } else {
        path.add(data).then((res: any) => {
          resolve(res)
        }).catch((err: any) => {
          reject(err);
        })
      }

    })
  }

  const getDoc = (path: any) => {
    return new Promise((resolve, reject) => {
      path.get().then((res: any) => {
        resolve(res.data());
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  const softDeleteDoc = (path: any) => {
    return new Promise((resolve, reject) => {
      path.update({ status: 404 }).then((res: any) => {
        resolve(res)
      }).catch((err: any) => {
        reject(err);
      })
    })
  }

  const updateDoc = (path: any, dataX: any, meta?: any) => {
    let data = dataX;

    data.modificatedBy = USER.uid;
    data.modificatedAt = firebase.firestore.FieldValue.serverTimestamp()
    data.status = 200;

    return new Promise((resolve, reject) => {

      path.update(data).then((res: any) => {
        resolve(res)
      }).catch((err: any) => {
        reject(err);
      })

    })
  }

  const deleteDoc = (path: any) => {
    return new Promise((resolve, reject) => {
      path.delete().then((res: any) => {
        resolve(res)
      }).catch((err: any) => {
        reject(err);
      })
    })
  }

  const getSubCollection = (oid: string, subcollection: string) => {
    return new Promise((resolve, reject) => {
      FSorganisations.doc(oid).collection(subcollection).get().then((snapshot: any) => {
        const tempResult: Array<any> = [];
        snapshot.forEach((doc: any) => {
          let data = doc.data();
          let temp = { id: doc.id, ...data };

          if (data.status && data.status !== 404) {
            tempResult.push(temp);
          } else if (!data.status) {
            tempResult.push(temp);
          }

        });
        resolve(tempResult);
      });
    })
  }

  return {
    data: {
      SDK
    },
    firestore: {
      KSHUBFS
    },
    functions: {
      addDoc,
      getDoc,
      softDeleteDoc,
      deleteDoc,
      updateDoc,
      getSubCollection
    }
  }
}