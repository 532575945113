import { MenuGroup, MenuItem } from '@chakra-ui/menu';
import { Avatar, Tooltip, useToast, Text, Box } from '@chakra-ui/react';
import { toastTypes } from 'helpers/alerts';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import { Table, Thead, Tr, Th, Tbody, Td } from 'pages/Components/generic/ResponsiveTable/table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useUser } from 'reactfire';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { useUserHook } from 'services/hooks/useUserHook';
import { actions } from 'services/redux/actions';

function List({ projId }: any) {

  const [documents, setDocuments] = useState<any>({})
  const stateRedux = useSelector((store: any) => store.projectReducer);
  const dispatch = useDispatch();
  const user: any = useUser()
  const { data: { superUserUI } } = useContextUIHook()
  const { functions: { getNameByUID } } = useUserHook()
  const toast = useToast();

  const updateData = () => {
    dispatch(actions.projects.getDocumentsByProject(projId, true))
  }

  const downloadDocument = (documentId: string, fileName: string, refStorage: string) => {
    toast(toastTypes.downloading)
    dispatch(actions.projects.downloadDocument(projId, fileName, documentId, refStorage, (status: boolean) => {
      status ? toast(toastTypes.success) : toast(toastTypes.error)
    }))
  }

  const archiveDocument = (documentId: string) => {
    dispatch(actions.projects.archiveDocument(projId, documentId))
  }

  useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (stateRedux.documentsByProject[projId]) {
      setDocuments(stateRedux.documentsByProject[projId])
    }
  }, [stateRedux.documentsByProject, projId])

  return (
    <>
      {
        stateRedux.loadingDocuments ?
          <LoadingTable />
          :
          <Box maxWidth='100%' overflow='auto'>
            <Table size="sm" >
              <Thead>
                <Tr>
                  {/* <Th>File</Th> */}
                  <Th>Name</Th>
                  <Th isNumeric>Version</Th>
                  <Th>Last Update</Th>
                  <Th>Summary</Th>
                  <Th>Created</Th>
                  <Th>Options</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  Object.keys(documents).map((index: any) => {
                    const d: any = documents[index];
                    if (documents[index].archived === false) {
                      return (
                        <Tr key={index}>
                          <Td>
                            <Text cursor='pointer' as={Link} to={`/app/projects/${projId}/document/${d.id}`}>
                              {d.name}
                            </Text>
                          </Td>
                          <Td isNumeric>{d.lastVersion}</Td>
                          <Td textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' maxWidth='0' cursor='pointer'>
                            {d.description}
                          </Td>
                          <Td textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' maxWidth='0' cursor='pointer'>
                            {d.summary}
                          </Td>
                          <Td>
                            <Tooltip label={getNameByUID(d.createdBy)} fontSize="md">
                              <Avatar name={getNameByUID(d.createdBy)} cursor='pointer' size="sm" />
                            </Tooltip>
                          </Td>
                          <Td>
                            <ButtonKs.IconInfo
                              m={1}
                              as={Link}
                              to={`/app/projects/${projId}/document/${d.id}`}
                            />
                            <ButtonKs.OptionsMenu bgColor={index % 2 ? 'gray.100' : 'white'} m={1}>
                              <MenuGroup>
                                <MenuItem as={Link} to={`/app/projects/${projId}/document/${d.id}`}> Open </MenuItem>
                                {(superUserUI === true || user.data.uid === d.createdBy) &&
                                  <>
                                    <MenuItem onClick={() => archiveDocument(d.id)}> Archive </MenuItem>
                                  </>
                                }
                                <MenuItem onClick={() => downloadDocument(d.id, d.fileName, d.refStorage)}> Download last version </MenuItem>
                              </MenuGroup>
                            </ButtonKs.OptionsMenu>
                          </Td>
                        </Tr>
                      )
                    }
                    return <></>
                  })}
              </Tbody>
            </Table>
          </Box>
      }
    </>
  );
}

export default List;