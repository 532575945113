import React, { useState } from 'react';
import {
  ChakraProvider,
  extendTheme,
  ThemeConfig
} from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"
import "@fontsource/roboto"

function ChakraBase(props: any) {

  const config: ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: false,
    fonts: {
      heading: "Roboto",
      body: "Roboto",
    },
    global: (props: any) => ({
    }),
    components: {
      Heading: {
        baseStyle: (props: any) => ({
          color: mode("#37373C", "white")(props),
        }),
        sizes: {
          "3xl": { fontSize: '40px' },
          "2xl": { fontSize: '32px' },
          "xl": { fontSize: '28px' },
          "lg": { fontSize: '24px' },
          "md": { fontSize: '20px' },
          "sm": { fontSize: '12px' },
        },
      },
      Text: {
        baseStyle: (props: any) => ({
          color: mode("#212529", "white")(props),
          fontSize: '16px'
        }),
        variants: {
          'ks-muted': (props: any) => ({
            color: mode("#6C757D", "#6C757D")(props),
            fontSize: '16px'
          })
        },
      },
      Button: {
        variants: {
          'ks-primary': (props: any) => ({
            backgroundColor: mode("#2185D0", "#2C5282")(props),
            color: mode("white", "white")(props),
            fontSize: '16px'
          }),
          'ks-danger': (props: any) => ({
            backgroundColor: mode("#DB2828", "#822727")(props),
            color: mode("white", "white")(props),
            fontSize: '16px'
          })
        },
      },
    }
  }

  const [theme] = useState(extendTheme(config))

  return (
    <ChakraProvider theme={theme}>
      {props.children}
    </ChakraProvider>
  );
}

export default ChakraBase;