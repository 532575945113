import { cleanDocNative } from 'helpers/kshub';
import { ApiKs } from 'services/api/API';
import { LOADING, UPDATE_ALL, UPDATE_BY_ID, LOADING_SITE, UPDATE_ALL_SITES_BY_PLACE, UPDATE_SITES_BY_PLACE, } from 'services/redux/place/place.type'
import { patchEntitiesCollection, patchNewEntityCollection } from '../helper';

export const getPlaces = (cache: boolean = false, callback: any = null) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });
  const { placeReducer }: any = getState();

  if (cache && placeReducer.places.length > 0) {
    dispatch({
      type: UPDATE_ALL,
      payload: { data: placeReducer.places, placesById: placeReducer.placesById }
    })

    callback && callback()
  } else {

    ApiKs().apiPrivate.places.list().then((res: any) => {

      const placesById: any = {}
      res.data.data.map((proj: any) => {
        return placesById[proj.id] = proj;
      })

      dispatch({
        type: UPDATE_ALL,
        payload: { data: res.data.data, placesById: placesById }
      })

      callback && callback()
    })
  }
}

export const getPlaceById = (placeId: string, cache: boolean = false, callback: any = null) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { placeReducer }: any = getState();

  if (cache && placeReducer.placesById[placeId]) {
    dispatch({
      type: UPDATE_BY_ID,
      id: placeId,
      places: patchEntitiesCollection(placeId, placeReducer.placesById[placeId], placeReducer.places),
      payload: placeReducer.placesById[placeId]
    })

    callback && callback()
  } else {
    ApiKs().apiPrivate.places.get(placeId).then((res: any) => {
      if (res?.data?.id) {
        const place = { id: placeId, ...res.data.data }
        dispatch({
          type: UPDATE_BY_ID,
          id: placeId,
          places: patchEntitiesCollection(placeId, place, placeReducer.places),
          payload: place
        })

        callback && callback()
      }
    })
  }
}

export const createPlace = (payload: any, callback: any = null) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { placeReducer }: any = getState();

  const payloadEdited = { ...payload }
  if (payload.qtySites) delete payloadEdited.qtySites

  ApiKs().apiPrivate.places.create(payloadEdited).then((res: any) => {

    dispatch({
      type: UPDATE_BY_ID,
      id: res.data.data.id,
      places: patchNewEntityCollection(res.data.data.id, res.data.data, placeReducer.places),
      payload: { ...res.data.data }
    })
    callback(true)
  }).catch((err: any) => {
    console.error(err)
    callback(false)
  });
}

export const updatePlace = (placeId: string, payload: any, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const payloadEdited = { ...payload }
  if (payloadEdited.qtySites) delete payloadEdited.qtySites
  const { placeReducer }: any = getState();

  ApiKs().apiPrivate.places.update(placeId, cleanDocNative(payloadEdited)).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      id: placeId,
      places: patchEntitiesCollection(placeId, payloadEdited, placeReducer.places),
      payload: payload
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    callback(false, err)
  })
}

export const getSites = (placeId: string, callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_SITE });

  ApiKs().apiPrivate.sites.list(placeId).then((res: any) => {

    const sitesById: any = {}
    res.data.data.map((site: any) => {
      return sitesById[site.id] = site;
    })

    dispatch({
      type: UPDATE_ALL_SITES_BY_PLACE,
      placeId: placeId,
      payload: { data: res.data.data, sitesById: sitesById }
    })

    callback()
  })
}

const mergeSiteInPlace = (newSiteId: string, currentSites: Array<any>, newSite: any, type: 'new' | 'update') => {
  let result: Array<any> = [];
  const siteData = { id: newSiteId, ...newSite };

  if (type === 'new') {
    result = [...currentSites, siteData]
  } else {
    if (currentSites.length === 0) {
      result = [siteData]
    } else {
      result = currentSites.map((site: any) => {
        if (site.id === newSiteId || site.siteId === newSiteId) return siteData
        return site
      })
    }
  }

  return result;
}

export const createSite = (placeId: string, payload: any, callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_SITE });

  const { placeReducer }: any = getState();
  let sitesByPlace: any = placeReducer.sitesByPlace[placeId] || []

  const payloadEdited = { ...payload }

  ApiKs().apiPrivate.sites.create(placeId, payloadEdited).then((res: any) => {
    dispatch({
      type: UPDATE_SITES_BY_PLACE,
      placeId: placeId,
      siteId: res.data.data.siteId,
      siteData: payloadEdited,
      payload: mergeSiteInPlace(res.data.data.siteId, sitesByPlace, payloadEdited, 'new')
    })
    callback()
  }).catch((err: any) => {
    callback()
  });
}

export const getSiteById = () => async (dispatch: any, getState: any) => {

}

export const updateSite = (placeId: string, siteId: string, payload: any, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_SITE });

  const { placeReducer }: any = getState();
  let sitesByPlace: any = placeReducer.sitesByPlace[placeId] || []

  const payloadEdited = { ...payload }

  ApiKs().apiPrivate.sites.update(placeId, siteId, cleanDocNative(payloadEdited)).then((res: any) => {

    dispatch({
      type: UPDATE_SITES_BY_PLACE,
      placeId: placeId,
      siteId: siteId,
      payload: mergeSiteInPlace(siteId, sitesByPlace, payloadEdited, 'update')
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    callback(false, err)
  })
}