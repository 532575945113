import { ApiKs } from 'services/api/API';
import { LOADING, UPDATE_ALL, UPDATE_BY_ID } from 'services/redux/insights/insights.type'

type CallbackSimple = (status: boolean) => void

export const getProjectsReports = (callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiPrivate.insights.projects.list().then((res: any) => {

    const projectReportsById: any = {}
    res.data.data.map((proj: any) => {
      return projectReportsById[proj.id] = proj;
    })

    dispatch({
      type: UPDATE_ALL,
      payload: { projectReportsById: projectReportsById }
    })

    callback()
  })
}

export const sendNow = (projectReportId: string, callback: CallbackSimple) => async (dispatch: any, getState: any) => {
  ApiKs().apiPrivate.insights.projects.sendNow(projectReportId).then((res: any) => {
    callback(true)
  }).catch(() => {
    callback(false)
  })
}

export const createProjectReport = (payload: any, callback: CallbackSimple) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiPrivate.insights.projects.create(payload).then((res: any) => {

    dispatch({
      type: UPDATE_BY_ID,
      id: res.data.data.id,
      payload: res.data.data
    })

    callback(true)
  }).catch(() => {
    callback(false)
  })
}

export const updateProjectReport = (projectReportId: string, payload: any, callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiPrivate.insights.projects.update(projectReportId, payload).then((res: any) => {

    dispatch({
      type: UPDATE_BY_ID,
      id: res.data.data.id,
      payload: res.data.data
    })

    callback()
  })
}