import { Editable, EditableInput, EditablePreview, useEditableControls } from "@chakra-ui/editable"
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons"
import { Box, ButtonGroup, IconButton } from "@chakra-ui/react"
import React from "react"


function EditableSimple({ defaultValue, textSize, callback, controlSide }: any) {
  /* Here's a custom control */
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()



    return isEditing ? (
      <ButtonGroup ml={4} mt={1} justifyContent="center" size="sm">
        <IconButton borderRadius="5px" icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton borderRadius="5px" icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <ButtonGroup ml={4} mt={1} justifyContent="center" size="sm">
        <IconButton borderRadius="5px" size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </ButtonGroup>
    )
  }

  return (
    <>
      <Editable
        defaultValue={defaultValue}
        fontSize={textSize || 'md'}
        width="100%"
        // isPreviewFocusable={false}
        display='inline-flex'
        marginTop={0}
        onSubmit={callback}
      >
        {controlSide === 'left' &&
          <>
            <Box mr={3} ml={-2}>
              <EditableControls />
            </Box>
            <EditablePreview width='100%' />
            <EditableInput />
          </>
        }
        {!controlSide &&
          <>
            <EditablePreview width='100%' />
            <EditableInput />
            <EditableControls />
          </>
        }

      </Editable>
    </>
  )
}

export default EditableSimple