import React, { useState, useEffect } from 'react';
import { ApiKs } from 'services/api/API';
import InvitePeople from './InvitePeople';
import moment from 'moment'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Avatar,
  Flex,
  Spacer,
  Text,
  Box,
  Heading,
  MenuItem,
  MenuGroup
} from "@chakra-ui/react"
import { useIntl } from 'react-intl';
import { ButtonKs } from 'pages/Components/generic/Buttons'
import BadgesAccess from 'pages/Components/generic/Badges/BadgesAccess';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';

function Invitations() {

  const intl = useIntl()

  const { apiSuper } = ApiKs();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false)

  useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateData = () => {
    setLoading(true)
    setData([])
    apiSuper.nousers.getAll().then((res: any) => {
      setData(res.data.data)
      setLoading(false)
    }).catch((err: any) => {
      console.error(err)
    })
  }

  const cancelInvite = (email: string) => {
    apiSuper.nousers.invite.cancel(email).then((res) => {
      updateData();
    }).catch((err: any) => {
      console.error(err)
    })
  }

  const restoreInvite = (email: string) => {
    apiSuper.nousers.invite.open(email).then((res) => {
      updateData();
    }).catch((err: any) => {
      console.error(err)
    })
  }

  return (
    <Box>
      <Flex mb={5}>
        <Box>
          <Heading as="h1" size="xl">
            {intl
              .formatMessage(
                {
                  id: 'ks.superuser.access.title'
                }
              )
            }
          </Heading>
          <Text as="h2" size="m" mb={3}>
            {intl
              .formatMessage(
                {
                  id: 'ks.superuser.access.subtitle'
                }
              )
            }
          </Text>
        </Box>
        <Spacer />
        <Box>
          <ButtonKs.Default mr={3} onClick={() => setOpenForm(!openForm)}>
            {intl
              .formatMessage(
                {
                  id: 'ks.superuser.access.btn.invite'
                }
              )
            }
          </ButtonKs.Default>
          <ButtonKs.Refresh
            mr={3}
            isLoading={loading}
            onClick={updateData}
          />
        </Box>
      </Flex>
      <Flex>
        <Box overflow='auto' width={openForm ? '75%' : '100%'}>
          {!loading &&
            <Table variant="striped" colorScheme="gray" overflowX="auto">

              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Email</Th>
                  <Th>Full Name</Th>
                  <Th>App Invitation</Th>
                  <Th>Accepted?</Th>
                  <Th>Last Update</Th>
                  <Th>Options</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  data && data.map && data.map((nouser: any, index: number) => {
                    return (
                      <Tr key={index}>
                        <Td>
                          <Avatar size="sm" bg="teal.500" />
                        </Td>
                        <Td>{nouser.data.email}</Td>
                        <Td>{nouser.data.fullName}</Td>
                        <Td>
                          {nouser.data.appInvitation ?
                            <BadgesAccess.InvitationInvited mr={1} /> :
                            <BadgesAccess.InvitationCanceled mr={1} />
                          }
                        </Td>
                        <Td>
                          {nouser.data.appInvitationAccepted ?
                            <BadgesAccess.InvitationStatusAccepted strike={!nouser.data.appInvitation} ml={1} /> :
                            <BadgesAccess.InvitationStatusOpen strike={!nouser.data.appInvitation} ml={1} />
                          }
                        </Td>
                        <Td>
                          {
                            nouser?.data?.updatedAt?._seconds &&
                            moment.utc(nouser.data.updatedAt._seconds * 1000).fromNow()
                          }
                        </Td>
                        <Td>
                          <ButtonKs.OptionsMenu bgColor={index % 2 ? 'gray.100' : 'white'}>
                            <MenuGroup title="Invitation">
                              <MenuItem onClick={() => cancelInvite(nouser.data.email)} isDisabled={!nouser.data.appInvitation}> Cancel Invite </MenuItem>
                              <MenuItem onClick={() => restoreInvite(nouser.data.email)} isDisabled={nouser.data.appInvitation}> Restore Invite </MenuItem>
                            </MenuGroup>
                          </ButtonKs.OptionsMenu>
                        </Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User</Th>
                  <Th>Email</Th>
                  <Th>Full Name</Th>
                  <Th>App Invitation</Th>
                  <Th>Last Update</Th>
                  <Th>Options</Th>
                </Tr>
              </Tfoot>
            </Table>
          }
          {loading &&
            <LoadingTable columns={6} />
          }
        </Box>
        {openForm &&
          <InvitePeople
            data={data}
            setOpenForm={setOpenForm}
            updateData={updateData}
          />
        }
      </Flex>
    </Box>
  );
}

export default Invitations;