import React, { useState } from 'react';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import {
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  Image,
  Flex,
  Spacer,
  Box,
  Skeleton
} from '@chakra-ui/react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import svg44 from 'assets/themeisle/44.svg'
import { useIntl } from 'react-intl';
import { ButtonKs } from 'pages/Components/generic/Buttons';

function SuperUsers({ dataSuperUsers, updateSuperUsersList }: any) {

  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const refresh = () => {
    setLoading(true)
    updateSuperUsersList().then(() => {
      setLoading(false)
    })
  }

  return (
    <Skeleton isLoaded={!loading} borderRadius={15}>
      <BoxShadow>
        <Heading fontSize="xl">
          {
            intl.formatMessage(
              { id: 'ks.superuser.superusers.header' }
            )
          }
        </Heading>
        <Text mt={1} fontSize="sm">
          {
            intl.formatMessage(
              { id: 'ks.superuser.superusers.subheader' }
            )
          }
        </Text>
        <Image src={svg44} maxHeight="150px" margin="0 auto" mb={0} />
        <List spacing={2} mt={6} mb={2}>
          {dataSuperUsers.map && dataSuperUsers.map((superuser: string, key: number) => (
            <ListItem key={key}>
              <ListIcon as={RiCheckboxCircleFill} color="green.500" />
              {superuser}
            </ListItem>
          ))}
        </List>
        <Flex>
          <Spacer />
          <Box>
            <ButtonKs.Refresh
              float="right"
              mt={4}
              onClick={refresh}
            />
          </Box>
        </Flex>
      </BoxShadow>
    </Skeleton>
  );
}

export default SuperUsers;