import React from 'react';
import { Box, Tabs, TabList, TabPanel, TabPanels, Tab } from '@chakra-ui/react'
import General from './General'
import { useIntl } from 'react-intl';

function Settings() {
  const intl = useIntl()

  return (
    <Box padding='2em' minHeight='calc(100vh - 90px)'>
      <Tabs>
        <TabList>
          <Tab>
            {intl.formatMessage({ id: 'ks.settings.menu.general' })}
          </Tab>
          <Tab>
            {intl.formatMessage({ id: 'ks.settings.menu.personal' })}
          </Tab>
          <Tab>
            {intl.formatMessage({ id: 'ks.settings.menu.organisations' })}
          </Tab>
        </TabList>

        <TabPanels paddingTop="2em">
          <TabPanel>
            <General />
          </TabPanel>
          <TabPanel>

          </TabPanel>
          <TabPanel>

          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Settings;