import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

function AlertNoAccess({ typeError = 'default' }: any) {
  return (
    <Alert
      mt={8}
      mb={8}
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        You don't have access
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        {typeError === 'default' &&
          <> This screen is reserved only for the owner or admins of the resource </>
        }
        {typeError === 'superuser' &&
          <> This screen is reserved only for super users </>
        }
      </AlertDescription>
    </Alert>
  );
}

export default AlertNoAccess;