import { WrapItem, Avatar, Box, FormLabel, Text } from '@chakra-ui/react';
import React from 'react';
import { useUserHook } from 'services/hooks/useUserHook';

interface Props {
  uid: string;
  type?: 'created' | 'updated' | 'author' | null;
  children?: any;
}

function UserBadge({ uid, type = null, children }: Props) {

  const { functions: { getNameByUID } } = useUserHook()

  return (
    <Box>
      <FormLabel>
        {type === 'created' && <>Created by&nbsp;</>}
        {type === 'updated' && <>Updated by&nbsp;</>}
      </FormLabel>
      <WrapItem>
        <Avatar
          size="xs"
          name={getNameByUID(uid) || '?'}
          mr={2}
        />

        <Text mr={1}>
          {getNameByUID(uid)}
        </Text>

        {children && <>{children}</>}
      </WrapItem>
    </Box>
  );
}

export default UserBadge;