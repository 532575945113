import React, { useState, useEffect } from 'react';
import { Box, MenuGroup, ListItem, ListIcon, List, Text, MenuItem, useColorModeValue, Icon } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td } from "pages/Components/generic/ResponsiveTable/table";
import { ArrowDownIcon, ArrowUpIcon, } from '@chakra-ui/icons'
import { Link, useRouteMatch } from "react-router-dom";
import { useTable, useSortBy } from 'react-table'
import HeaderKs from 'pages/Components/generic/HeaderKs';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions'
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { useUser } from 'reactfire';
import { RiFolder2Line, RiLock2Fill } from 'react-icons/ri'

function ProjectsGroupDirectory(props: any) {

  let { path } = useRouteMatch();
  const { data: { superUserUI } } = useContextUIHook()
  const user = useUser();

  const stateRedux = useSelector((store: any) => store.projectReducer);
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const updateData = () => {
    dispatch(actions.projects.getProjectsGroups())
    dispatch(actions.projects.getProjects())
  }

  useEffect(() => {
    if (stateRedux.projectsGroups.length < 1) updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(stateRedux.loadingProjectsGroups)
  }, [stateRedux.loadingProjectsGroups]);

  const refreshTable = () => {
    if (stateRedux.projectsGroupsById && Object.keys(stateRedux.projectsGroupsById).length !== 0) {
      const tmp = Object.keys(stateRedux.projectsGroupsById).map((index: any) => stateRedux.projectsGroupsById[index])
      setDataTable(tmp)
    }
  }

  useEffect(() => {
    refreshTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superUserUI, stateRedux.projectsGroupsById, stateRedux.projectsById]);

  const getProjectById = (projId: string) => {
    if (stateRedux.projectsById && stateRedux.projectsById[projId]) {
      return stateRedux.projectsById[projId]
    } else {
      return { id: '', name: null }
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Project Group',
        accessor: 'name',
      },
      {
        Header: 'Projects',
        accessor: 'projects',
        Cell: (props: any) => {
          const colorText = useColorModeValue('blue.400', 'white')

          return (
            <>
              <List spacing={3}>
                {props.cell.value.map((projectId: string) => {
                  const project = getProjectById(projectId)
                  return (
                    <ListItem>
                      {project.name ?
                        <ListIcon as={RiFolder2Line} mr={2} color="green.500" />
                        :
                        <Icon as={RiLock2Fill} mr={2} color='red.200' />
                      }
                      {
                        project.name ?
                          <Text as={Link} color={colorText} to={project.id ? `/app/projects/${project.id}/data` : ''}>
                            {project.name || <> Loading... <Icon as={RiLock2Fill} /></>}
                          </Text>
                          :
                          <Text color={colorText} display='inline'>
                            Private project
                          </Text>
                      }
                    </ListItem>
                  )
                })}
              </List>
            </>
          )
        }
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateRedux.projects]
  )

  const initialStateTable: any = {
    columns: columns,
    data: dataTable
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  return (
    <Box>
      <BreadcrumbsKs
        data={
          [
            { name: 'Projects Groups', link: 'projects-groups' },
            { name: 'Directory', link: 'projects-groups' }
          ]
        }
      />
      <HeaderKs
        title='Projects Groups'
        subtitle='Projects can be grouped into groups. Each project within a group inherits the groups configuration.'
      >
        <ButtonKs.Create
          to={`${path}/new`}
          ml={2}
        />
        <ButtonKs.Refresh
          onClick={() => updateData()}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      {
        !loading &&
        <Box maxWidth='100%' overflow='auto'>
          <Table variant="striped" mt={4}  {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup: any) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <Th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span style={{ marginLeft: '5px' }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownIcon />
                            : <ArrowUpIcon />
                          : ''}
                      </span>
                    </Th>
                  ))}
                  <Th>
                    Options
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {
                rows.map((row: any, index: number) => {
                  prepareRow(row)
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <Td {...cell.getCellProps()} headerTitle={cell.column.Header}>
                            {cell.render('Cell')}
                          </Td>
                        )
                      })}
                      <Td headerTitle='Options'>
                        <ButtonKs.OptionsMenu bgColor={index % 2 ? 'gray.100' : 'white'}>
                          <MenuGroup title="Options">
                            {(superUserUI === true || user.data.uid === row.original.createdBy) &&
                              <>
                                <MenuItem as={Link} to={`${path}/${row.original.id || row.original.projectGroupId}/edit`}>
                                  Configuration
                                </MenuItem>
                              </>
                            }
                          </MenuGroup>
                        </ButtonKs.OptionsMenu>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>
      }
      {
        loading &&
        <LoadingTable columns={3} />
      }
    </Box >
  );
}

export default ProjectsGroupDirectory;