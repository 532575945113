import React from 'react';
import { Grid, GridItem, Heading, Box, Text, } from '@chakra-ui/react'

interface Props {
  title: string,
  subtitle?: string,
  children: any,
  size?: string,
  multipleChildren?: boolean,
}

function HeaderKs(props: Props) {
  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={5} mb={8}>
      <GridItem colSpan={2}>
        {
          props.title &&
          <Heading as="h3" size={props.size ? props.size : 'xl'} pt={3}>
            {props.title}
          </Heading>
        }
        {
          props.subtitle &&
          <Text fontSize='sm' ml='2px'>
            {props.subtitle}
          </Text>
        }
        {props.multipleChildren && props.children[0]}
      </GridItem>
      <GridItem colStart={3} colEnd={5}>
        <Box float='right' paddingTop='5px'>
          {props.multipleChildren ? props.children[1] : props.children}
        </Box>
      </GridItem>
    </Grid>
  );
}

export default HeaderKs;