import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import React from 'react';
import SuperUsers from './Boxes/SuperUsers'
import BoxInfo from './Boxes/Info'
import SafeList from './Boxes/SafeList'
import LabelsInvitations from './Boxes/LabelsInvitations'
import LabelsUsers from './Boxes/LabelsUsers'

function Home({ dataSafeList, updateDataSafeList, dataSuperUsers, updateSuperUsersList, loading }: any) {

  return (
    <>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={6}
      >
        <GridItem rowSpan={2} colSpan={1}>
          <Skeleton isLoaded={!loading} borderRadius={15}>
            <BoxInfo />
          </Skeleton>
        </GridItem>
        <GridItem colSpan={3}>
          <Skeleton isLoaded={!loading} borderRadius={15}>
            <SafeList
              updateDataSafeList={updateDataSafeList}
              dataSafeList={dataSafeList} />
          </Skeleton>
        </GridItem>
        <GridItem colSpan={1}>
          <Skeleton isLoaded={!loading} borderRadius={15}>
            <SuperUsers dataSuperUsers={dataSuperUsers} updateSuperUsersList={updateSuperUsersList} />
          </Skeleton>
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton isLoaded={!loading} borderRadius={15}>
            <LabelsInvitations />
          </Skeleton>
        </GridItem >
        <GridItem colSpan={2}>
          <Skeleton isLoaded={!loading} borderRadius={15}>
            <LabelsUsers />
          </Skeleton>
        </GridItem>
      </Grid>
    </>
  );
}

export default Home;