import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, Text, FormControl, FormErrorMessage, Heading, FormLabel, Grid, GridItem, Select, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Table, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import { useParams, } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { actions } from 'services/redux/actions'
import { RiPencilLine, RiCloseLine } from 'react-icons/ri';
import { useTable, useSortBy } from 'react-table';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import pluralize from 'pluralize';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { usePlaceHook } from 'services/hooks/usePlaceHook';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import CenterBox from 'pages/Components/generic/CenterBox';
import LoadingGeneric from 'pages/Components/generic/Loading/LoadingGeneric';
import OptionsTypeSite, { OptionsToText } from 'pages/Components/generic/Selects/OptionsTypeSite';
import { toastTypes } from 'helpers/alerts';

function Sites() {

  const { register, handleSubmit, reset, setValue, formState: { errors, isSubmitting } } = useForm();
  const { placeId } = useParams<any>();
  const { data: { superUserUI } } = useContextUIHook()
  const { data: { placesById }, dataSites: { sitesByPlace, sitesLoading } } = usePlaceHook();

  const [editMode, setEditMode] = useState(false)
  const [editSiteData, setEditSiteData] = useState<any>({})
  const containerRef: any = useRef(null);


  const [dataTable, setDataTable] = useState<any>([])
  const [placeData, setPlaceData] = useState<any>({})
  const dispatch = useDispatch();
  const toast = useToast()

  const activateEditSite = (siteData: any) => {
    if (!siteData) {
      setEditMode(false)
      setEditSiteData({})
      reset()
    } else {
      setEditMode(true)
      setEditSiteData(siteData)
      containerRef.current.focus();

      setValue('name', siteData.name)
      setValue('description', siteData.description)
      setValue('typeSite', siteData.typeSite)
    }
  }

  const updateData = () => {
    dispatch(actions.places.getSites(placeId))
  }

  useEffect(() => {
    updateData()
    if (!placesById[placeId]) dispatch(actions.places.getPlaceById(placeId, true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (placesById[placeId]) setPlaceData(placesById[placeId])
  }, [placeId, placesById]);

  const refreshTable = () => {
    if (sitesByPlace && sitesByPlace[placeId]) {
      setDataTable(sitesByPlace[placeId])
    }
  }

  useEffect(() => {
    refreshTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superUserUI, sitesByPlace]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Site',
        accessor: 'name',
        Cell: (props: any) => {
          const name = props.cell.value;
          return (
            <Text>
              {name}
            </Text>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (props: any) => {
          const description = props.cell.value;
          return (
            <Box maxWidth={200} cursor='pointer'>
              <Popover>
                <PopoverTrigger>
                  <Text noOfLines={3}>
                    {description ? description : 'X'}
                  </Text>
                </PopoverTrigger>
                <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                  <PopoverHeader pt={4} fontWeight="bold" border="0">
                    Description
                  </PopoverHeader>

                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    {
                      description ? description : 'Without description'
                    }
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'typeSite',
        Cell: (props: any) => {
          const typeSite = props.cell.value;

          return (
            <>
              {OptionsToText(typeSite)}
            </>
          )
        },
      },
    ],
    []
  )

  const initialStateTable: any = {
    columns: columns,
    data: dataTable
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  const onSubmit = (data: any) => {
    toast(toastTypes.processing)
    if (editMode) {
      if (editSiteData && editSiteData.id) {
        dispatch(actions.places.updateSite(placeId, editSiteData.id, data, () => {
          reset()
          setEditSiteData({})
          setEditMode(false)
          toast(toastTypes.updated)
        }))
      } else {
        toast(toastTypes.error)
      }
    } else {
      dispatch(actions.places.createSite(placeId, data, () => {
        reset()
        toast(toastTypes.created)
      }))
    }
  }



  return (
    <Box>
      {
        placeData && placeData.name &&
        <BreadcrumbsKs
          data={
            [
              { name: 'Places Directory', link: `/app/places` },
              { name: `${placeData.name ? placeData.name : 'Place'}`, link: `/app/places/${placeId}/edit?mode=read` },
              { name: 'Sites', link: null }
            ]
          }
        />
      }
      <HeaderKs
        title={`Sites in ${placeData?.name}`}
        subtitle={` ${sitesByPlace[placeId] ? sitesByPlace[placeId].length : 0} ${pluralize('site', sitesByPlace[placeId] ? sitesByPlace[placeId].length : 0)} available in this place`}
      >
        <ButtonKs.Refresh
          onClick={() => updateData()}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      <Input ref={containerRef} width='100%' height='0px' left='-9999px' />
      <CenterBox>
        {sitesLoading &&
          <LoadingGeneric boxes={2} />
        }
        {!sitesLoading &&
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns="repeat(12, 1fr)" mt={4} gap={6}>
                {
                  editMode &&
                  <GridItem colSpan={12}>
                    <Heading as="h3" size="lg">
                      Editing {editSiteData?.name ? `"${editSiteData.name}"` : 'site'}
                    </Heading>
                  </GridItem>
                }
                <GridItem colSpan={4}>
                  <FormControl isInvalid={errors.name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      id="name"
                      placeholder="Site name"
                      {...register("name", {
                        required: "This is required",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={5}>
                  <FormControl isInvalid={errors.description}>
                    <FormLabel>Description</FormLabel>
                    <Input
                      type="text"
                      id="description"
                      placeholder="A short description about this site"
                      {...register("description", {
                        required: "This is required",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl isInvalid={errors.typeSite}>
                    <FormLabel>Type</FormLabel>
                    <Select
                      id="typeSite"
                      placeholder="Select a Type"
                      {...register("typeSite", {
                        required: "This is required",
                      })}
                    >
                      <OptionsTypeSite />
                    </Select>
                    <FormErrorMessage>
                      {errors.typeSite && errors.typeSite.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={12}>
                  {
                    editMode ?
                      <ButtonKs.SaveChanges
                        float={'right'}
                        type="submit"
                      />
                      :
                      <ButtonKs.Create
                        isDisabled={isSubmitting}
                        type="submit"
                        float={'right'}
                      />
                  }
                  {
                    editMode &&
                    <ButtonKs.Cancel
                      float={'right'}
                      mr={4}
                      onClick={() => {
                        reset()
                        setEditMode(false)
                        setEditSiteData({})
                      }}
                    />
                  }
                </GridItem>
                <GridItem colSpan={12}>
                  <Divider />
                </GridItem>
              </Grid>
            </form>
            <Grid>
              <Table variant="striped" mt={4}  {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup: any) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any, index: number) => (
                        <Th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          <span style={{ marginLeft: '5px' }}>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? <ArrowDownIcon />
                                : <ArrowUpIcon />
                              : ''}
                          </span>
                        </Th>
                      ))}
                      <Th>
                        Options
                      </Th>
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {
                    rows.map((row: any, index: number) => {
                      prepareRow(row)

                      return (
                        <Tr {...row.getRowProps()}>
                          {row.cells.map((cell: any) => {
                            return (
                              <Td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </Td>
                            )
                          })}
                          <Td headerTitle='Edit'>
                            <ButtonKs.IconCustom
                              icon={editSiteData.id === row.original.id ? <RiCloseLine color='black' /> : <RiPencilLine color='black' />}
                              bgColor={index % 2 ? 'gray.100' : 'white'}
                              onClick={() => editSiteData.id === row.original.id ? activateEditSite(null) : activateEditSite(row.original)}
                            />
                          </Td>
                        </Tr>
                      )
                    })
                  }
                </Tbody>
              </Table>
            </Grid>
          </>
        }
      </CenterBox>
    </Box >
  );
}

export default Sites;