let API_CONFIG: any = {};

if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'staging') {
  API_CONFIG = {
    fullURL: process.env.REACT_APP_KS_API,
  }
} else {
  API_CONFIG = {
    fullURL: process.env.REACT_APP_KS_API,
  }
}

export const apiConfig = API_CONFIG;