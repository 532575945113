import React, { useEffect, useState } from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Box,
  Spacer,
  Heading,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { validateEmail } from 'helpers/mini'
import { ApiKs } from 'services/api/API';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { useIntl } from 'react-intl';
import { toastTypes } from 'helpers/alerts';


function InvitePeople({ data, setOpenForm, updateData }: any) {

  const { apiSuper } = ApiKs();
  const toast = useToast()
  const intl = useIntl()
  const [emailsWithInv, setEmailsWithInv] = useState<Array<string>>([])

  const [emailAdressError, setEmailAdressError] = useState<string | null>(null)
  const [formDisabled, setFormDisabled] = useState<boolean>(true)
  const [formLoading, setFormLoading] = useState<boolean>(false)

  const [emailAdress, setEmailAdress] = useState<string>('')
  const [fullname, setFullName] = useState<string>('')

  useEffect(() => {
    const summary = data.map((nouser: any) => nouser.data.email);
    setEmailsWithInv(summary)
  }, [data]);

  useEffect(() => {
    if (emailsWithInv.includes(emailAdress)) {
      setEmailAdressError(`${emailAdress} have an open invitation, you can't send another. Please restore that invitation or cancel.`);
    } else {
      setEmailAdressError(null)
    }

    setFormDisabled(!validateEmail(emailAdress))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAdress]);

  const sendInvitation = () => {
    if (!emailsWithInv.includes(emailAdress) && validateEmail(emailAdress)) {
      toast(toastTypes.processing)
      setFormLoading(true)
      apiSuper.nousers.invite.create(emailAdress, fullname).then((res) => {
        if (res.data.status === true) {
          toast(toastTypes.created)
        }
        setEmailAdress('')
        setFullName('')
        setFormLoading(false)
        updateData();
      }).catch((err: any) => {
        setFormLoading(false)
      })
    }
  }

  return (
    <Box margin={5} padding={3} width='25%'>
      <Stack spacing={3}>
        <Box p={5} shadow="md" borderWidth="1px">
          <Flex mb={5}>
            <Box>
              <Heading>
                {intl
                  .formatMessage(
                    {
                      id: 'ks.superuser.access.btn.invite'
                    }
                  )
                }
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <ButtonKs.Close onClick={() => setOpenForm(false)} />
            </Box>
          </Flex>
          <FormControl isRequired id="email" mt={7}>
            <FormLabel>Email address</FormLabel>
            <Input disabled={formLoading} type="email" value={emailAdress} onChange={(e) => setEmailAdress(e.target.value)} />
            {
              emailAdressError ?
                <FormHelperText color='red'>{emailAdressError}</FormHelperText> :
                <FormHelperText>We'll never share this with members of the app</FormHelperText>
            }
          </FormControl>
          <FormControl id="email" mt={5} mb={5}>
            <FormLabel>Full Name (Optional)</FormLabel>
            <Input disabled={formLoading} type="fullname" value={fullname} onChange={(e) => setFullName(e.target.value)} />
            <FormHelperText>This helps identify the invitation over time</FormHelperText>
          </FormControl>
          <ButtonKs.Success
            float="right"
            isDisabled={formDisabled || emailAdressError ? true : false}
            onClick={sendInvitation}
          >
            Send Invitation
          </ButtonKs.Success>
        </Box>
      </Stack>
    </Box>
  );
}

export default InvitePeople