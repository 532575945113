import { Box, Center, Divider, Flex, Grid, GridItem, SimpleGrid, Spacer, Text, } from '@chakra-ui/layout';
import { Icon, IconButton, Select, Tooltip, useToast } from '@chakra-ui/react';
import { toastTypes } from 'helpers/alerts';
import { getFileIcon, getFileTypeSimple } from 'helpers/files';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import CenterBox from 'pages/Components/generic/CenterBox';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import LoadingGeneric from 'pages/Components/generic/Loading/LoadingGeneric';
import randomstring from 'randomstring';
import React, { useEffect, useState } from 'react';
import { FaHistory, FaInfo } from 'react-icons/fa';
import { HiDocumentDownload, HiUpload } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useUserHook } from 'services/hooks/useUserHook';
import { actions } from 'services/redux/actions';
import Comments from './Comments';
import UploadFiles from '../UploadFiles';
import Timeline from './Timeline';
import { RiCloseFill } from 'react-icons/ri';
import { sortObjectByVersion } from 'helpers/mini';
import EditableSimple from 'pages/Components/generic/Editable/EditableSimple';

function Document() {

  const { projId, documentId } = useParams<any>();
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [showTimeLine, setShowTimeline] = useState(false)
  const [showNewVersion, setShowNewVersion] = useState<boolean>(false)
  const [document, setDocument] = useState<any>({})
  const [currentVersion, setCurrentVersion] = useState<number>(1);

  const { functions: { getNameByUID } } = useUserHook()
  const toast = useToast()

  const dispatch = useDispatch()

  const stateRedux = useSelector((store: any) => store.projectReducer);


  useEffect(() => {
    if (stateRedux?.documentsByProject[projId] && stateRedux?.documentsByProject[projId][documentId]) {
      const documentUpdated = stateRedux?.documentsByProject[projId][documentId];
      getDocByVersion(documentUpdated.lastVersion, documentUpdated).then((docVersion: any) => {
        console.log(docVersion)
        if (docVersion && docVersion.archived === false) {
          setCurrentVersion(documentUpdated.lastVersion)
        }
      })

      setDocument(stateRedux.documentsByProject[projId][documentId])
      setShowNewVersion(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, projId, stateRedux.documentsByProject])

  const changeVersion = (eve: any) => {
    setCurrentVersion(+eve.target.value)
  }

  const updateData = (cache: boolean) => {
    setLoading(true)
    dispatch(actions.projects.getDocumentById(projId, documentId, cache, () => {
      setLoading(false)
    }))
  }

  const getDocByVersion = (version: number | string, documentData: any = null) => {
    return new Promise((resolve) => {
      const allVersions: any = documentData ? documentData.versions : document.versions;
      if (allVersions) {
        let versionNecessary: any = null
        Object.keys(allVersions).map((index: string) => {
          if (allVersions[index].version === version) versionNecessary = allVersions[index]
          return true
        })
        resolve(versionNecessary)
      } else {
        resolve(null)
      }
    })
  }

  const downloadDocumentWithVersion = async (versionSelected: number | null) => {
    toast(toastTypes.downloading)
    const version_ = versionSelected || currentVersion
    const documentVersion: any = await getDocByVersion(version_)

    dispatch(actions.projects.downloadDocumentByVersion(
      projId,
      documentId,
      documentVersion.fileName,
      version_,
      documentVersion.refStorage,
      (status: boolean) => {
        status ? toast(toastTypes.success) : toast(toastTypes.error)
      }))
  }

  useEffect(() => {
    updateData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BreadcrumbsKs
        data={
          [
            { name: 'Projects', link: '/app/projects' },
            { name: 'Project Home', link: `/app/projects/${projId}/home` },
            { name: 'Document Detail', link: null }
          ]
        }
      />
      {loading ?
        <CenterBox fullWidth>
          <HeaderKs
            size='lg'
            title='Document Detail'
          >
            <ButtonKs.Refresh
              disabled
              ml={2}
              mr={3}
            />
          </HeaderKs>
          <LoadingGeneric boxes={3} />
        </CenterBox>
        :
        <>
          <CenterBox smallWidth>
            <HeaderKs
              size='lg'
              title=''
              multipleChildren
            >
              <EditableSimple
                controlSide='left'
                textSize='xl'
                defaultValue={document.name ?
                  document.name :
                  'Document Detail'
                }
                callback={(text: string) => {
                  toast(toastTypes.processing)
                  dispatch(actions.projects.updateDocument(projId, documentId, { name: text }, (status: boolean) => {
                    toast(status ? toastTypes.updated : toastTypes.error)
                  }))
                }}
              />
              <ButtonKs.Refresh
                onClick={() => updateData(false)}
                ml={2}
                mr={3}
              />
            </HeaderKs>
            <Box>
              <Grid
                templateRows='repeat(2, 1fr)'
                templateColumns='repeat(12, 1fr)'
                gap={4}
              >
                {/* Introduction */}
                <GridItem rowSpan={1} colSpan={12} >
                  <Center>
                    <Icon boxSize='12em' as={getFileIcon(document.fileType)} />
                  </Center>
                  <Center>
                    <SimpleGrid columns={[1, 1, 3, 3]} spacing='10px' width='75%' maxWidth='700px' mb={6}>
                      <Box textAlign='center'>
                        <Text color='gray.500' > &bull; Format {getFileTypeSimple(document.fileType)} &bull; </Text>
                      </Box>
                      <Box textAlign='center'>
                        {
                          document.updatedBy === document.createdBy ?
                            <>
                              <Tooltip hasArrow label='User who generated the first version of the document and the last version' bg='gray.300' color='black'>
                                <Text color='gray.500' fontSize='sm'>&bull; Posted and updated by <br /> {getNameByUID(document.createdBy)} &bull; </Text>
                              </Tooltip>
                            </>
                            :
                            <>
                              <Tooltip hasArrow label='User who generated the version' bg='gray.300' color='black'>
                                <Text color='gray.500' >&bull; Updated by {getNameByUID(document.updatedBy)} &bull;</Text>
                              </Tooltip>
                              <Tooltip hasArrow label='User who generated the first version of the document' bg='gray.300' color='black'>
                                <Text color='gray.500' fontSize='sm'>&bull; Posted by {getNameByUID(document.createdBy)} &bull; </Text>
                              </Tooltip>
                            </>
                        }
                      </Box>
                      <Box color='gray.500' textAlign='center'>
                        &bull; Version {document.lastVersion} &bull;
                      </Box>
                    </SimpleGrid>
                  </Center>
                  <Center>
                    <SimpleGrid columns={[1, 1, 1, 1]} spacing='10px' width='100%' maxWidth='700px' mb={6}>
                      <Divider />
                      <Box>
                        <EditableSimple defaultValue={document.summary ?
                          document.summary :
                          'Write a description...'
                        }
                          callback={(text: string) => {
                            toast(toastTypes.processing)
                            dispatch(actions.projects.updateDocument(projId, documentId, { summary: text }, (status: boolean) => {
                              toast(status ? toastTypes.updated : toastTypes.error)
                            }))
                          }} />
                      </Box>
                      <Divider />
                    </SimpleGrid>
                  </Center>
                </GridItem>

                {/* Actions Buttons */}
                <GridItem colSpan={12} textAlign='center'>
                  <Box maxWidth='400px' width='auto' margin='0 auto'>
                    <Select placeholder='Select option' mb={2} onChange={changeVersion}>
                      {
                        document.versions && Object.keys(document.versions.sort(sortObjectByVersion)).map((index) => {
                          console.log(currentVersion)
                          return (
                            <option
                              value={document.versions[index].version}
                              selected={document.versions[index].version === currentVersion}
                              disabled={document.versions[index].archived === true}
                            >
                              Version {document.versions[index].version} {document.versions[index].archived === true && '(Archived)'}
                            </option>
                          )
                        })
                      }
                    </Select>
                    <ButtonKs.Default m={2} rightIcon={<FaHistory />} onClick={() => setShowTimeline(!showTimeLine)}>
                      {showTimeLine ? 'Hide' : 'Show'} Timeline
                    </ButtonKs.Default>
                    <ButtonKs.Success m={2} rightIcon={<HiDocumentDownload />} onClick={() => downloadDocumentWithVersion(null)}>
                      Download V{currentVersion}
                    </ButtonKs.Success>
                    <ButtonKs.Primary m={2} rightIcon={showNewVersion ? <RiCloseFill /> : <HiUpload />} onClick={() => setShowNewVersion(!showNewVersion)}>
                      {showNewVersion ? 'Cancel new version' : 'New version'}
                    </ButtonKs.Primary>
                  </Box>
                </GridItem>

                {showNewVersion &&
                  <GridItem colSpan={12} mt={-20}>
                    <UploadFiles
                      documentId={documentId}
                      projId={projId}
                      multipleFiles={false}
                      typeDropZone='versionOfDocument'
                      refStorageFn={() => {
                        return `/projects/${projId}/documents/RANDOM__${randomstring.generate(20)}__DOC__T__${Date.now()}__V__${document.lastVersion + 1}`;
                      }}
                    />
                  </GridItem>
                }

                {/* Timeline */}
                <GridItem colSpan={12}>
                  {showTimeLine &&
                    <Timeline
                      document={document}
                      projId={projId}
                      downloadDocumentWithVersion={downloadDocumentWithVersion}
                      onClickPlusButton={() => setShowNewVersion(!showNewVersion)}
                    />
                  }
                </GridItem>

                {/* Danger button */}
                <GridItem colSpan={12}>
                  <Flex>
                    <Box p='4'>
                      <Tooltip label='Documents have an immutable history, each version is stored on the server. When you archive a version it will just mark the file with a flag.'>
                        <IconButton icon={<FaInfo />} borderRadius='lg' size='sm' aria-label='information' />
                      </Tooltip>
                    </Box>
                    <Spacer />
                    <Box p='4'>
                      <ButtonKs.Warning
                        onClick={() => {
                          toast(toastTypes.processing)
                          dispatch(actions.projects.archiveDocument(projId, documentId, () => {
                            toast(toastTypes.archived)
                            history.push(`/app/projects/${projId}/home`)
                          }))
                        }}
                        m={1}
                        size='sm'
                      >
                        Archive document
                      </ButtonKs.Warning>
                    </Box>
                  </Flex>
                </GridItem>
              </Grid>
            </Box>
          </CenterBox>

          <CenterBox smallWidth mt={6}>
            <Comments />
          </CenterBox>
        </>
      }
    </>
  );
}

export default Document;