import React from 'react';
import {
  Box,
  Text,
  VStack,
  Code,
  Grid,
  Divider,
} from "@chakra-ui/react"
import { useLocation } from 'react-router';

interface Props {
  extraText?: string;
}

function NotFound({ extraText = "We can't find the resource in the app" }: Props) {

  const location = useLocation();

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid p={3}>
        <VStack spacing={8} style={{ marginTop: '4em' }}>
          <Code fontSize="xl">404 | Not Found</Code>
          <Text>
            Sorry, this page not exist
            <br />
            {extraText}
          </Text>
          <Divider />
          <Text>
            No match for <code>{location.pathname}</code>
          </Text>
        </VStack>
      </Grid>
    </Box>
  );
}

export default NotFound;