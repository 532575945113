import React, { useState, useEffect, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import lang_enUS from 'lang/compiled/en-US.json';
import lang_es from 'lang/compiled/es.json'
import { LOCAL_ST, localSt } from 'helpers/local-session-storage'
import UIContext from 'services/contexts/ContextUI'

function ReactIntl(props: any) {

  const [locale, setLocale] = useState(localSt.obtainNow(LOCAL_ST.settingsLanguage) || 'en-US');
  const cttUI = useContext(UIContext);

  useEffect(() => {
    setLocale(cttUI.data.language)
  }, [cttUI.data]);

  const getMessages = () => {
    switch (locale) {
      case 'en-US': return lang_enUS
      case 'es': return lang_es
      default: return lang_enUS
    }
  }

  return (
    <IntlProvider
      locale={locale}
      messages={getMessages()}
    >
      {props.children}
    </IntlProvider>
  );
}

export default ReactIntl;