/* eslint-disable no-useless-escape */
export function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formatDate(datePure: any) {
  let date: any = datePure;

  if (datePure.seconds) {
    date = new Date(datePure.seconds * 1000);
  }

  let month = date.getMonth()
  let day = date.getDate()
  let year = date.getFullYear()
  return month + "/" + day + "/" + year;
}

export function onlyUniqueArray(arrayX: any) {
  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  }

  let unique = arrayX.filter(onlyUnique);
  unique = avoidEmpty(unique);

  return unique;
}

export function avoidEmptyArray(arrayX: any) {
  return arrayX.filter(function (el: string) {
    return el != null;
  });
}

export function avoidSpacesArray(arrayX: any) {
  return arrayX.map((item: string) => item.replace(' ', ''))
}

export function deepEqualObjects(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqualObjects(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

export function isObject(object: any) {
  return object != null && typeof object === 'object';
}

export function cleanFunct(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}


export function isoWeekdayConvert(day: number) {
  // momentjs.com/docs/#/get-set/iso-weekday/
  if (daysString[day]) return daysString[day];
  return 'INVALID DAY';
}

export function isEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Mon = 1, Sun = 7
export const daysString = ['Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const daysExtString = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const daysExtObj = daysExtString.map((day: string, index: number) => {
  return { label: day, value: index + 1, colorScheme: 'blue' }
})

export const removeSpaces = (str: string) => str.replace(/\s/g, '');


export function sortObjectByVersion(a: any, b: any) {
  if (a.version < b.version) return 1;
  if (a.version > b.version) return -1;
  return 0;
}