import React, { useEffect, useState } from 'react';
import { countriesList } from 'helpers/countries'

interface Props {
  simple: boolean
}

function OptionsCountries({ simple }: Props) {
  const countries = countriesList;
  const [countriesArray, setCountriesArray] = useState<Array<any>>([])
  const simpleList = ['US', 'AU', 'CA', 'GB', 'IT'];

  useEffect(() => {
    const countriesArray: Array<any> = Object.keys(countries).map((key: string) => ({ code: key, ...countries[key] }))
    setCountriesArray(countriesArray.sort((a, b) => (a.name > b.name) ? 1 : -1))

    if (simple) {
      setCountriesArray(
        countriesArray.filter((country) => {
          if (simpleList.includes(country.code)) return true
          return false
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        countriesArray.map((country: any, index: number) => {
          return (
            <option value={country.name} key={index}>
              {country.name} ({country.native}) {country.emoji}
            </option>
          )
        })
      }
    </>
  );
}

export default OptionsCountries;