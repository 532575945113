import { Text, Box, Icon, MenuGroup, Popover, PopoverTrigger, PopoverArrow, PopoverContent, PopoverCloseButton, PopoverHeader, PopoverBody, TableCaption, MenuItem, Button } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import { ArrowDownIcon, ArrowUpIcon, } from '@chakra-ui/icons'
import { Link, useRouteMatch } from "react-router-dom";
import { useTable, useSortBy } from 'react-table'
import { useSelector } from 'react-redux';
import { countryByName } from 'helpers/countries';
import { RiFileInfoFill } from 'react-icons/ri';
import { FaSitemap } from 'react-icons/fa'
import pluralize from 'pluralize';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { usePlaceHook } from 'services/hooks/usePlaceHook';
import { Table, Thead, Tbody, Tr, Th, Td } from "pages/Components/generic/ResponsiveTable/table";
import HeaderKs from 'pages/Components/generic/HeaderKs';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';

function PlacesDirectory(props: any) {

  const { path } = useRouteMatch();
  const { data: { superUserUI } } = useContextUIHook()
  const { data: { placesLoading, placesUpdatedAt, places }, fun: { updatePlaces } } = usePlaceHook();

  const stateRedux = useSelector((store: any) => store.placeReducer);

  const [dataTable, setDataTable] = useState<any>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(stateRedux.loading)
  }, [stateRedux.loading]);

  const refreshTable = () => {
    updatePlaces()
  }

  useEffect(() => {
    setDataTable(places)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Place',
        accessor: 'name',
        Cell: (props: any) => {
          const name = props.cell.value;
          return (
            <Text>
              {name}
            </Text>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (props: any) => {
          const description = props.cell.value;
          return (
            <Box maxWidth={200} cursor='pointer'>
              <Popover>
                <PopoverTrigger>
                  <Text noOfLines={3}>
                    {description ? description : 'X'}
                  </Text>
                </PopoverTrigger>
                <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                  <PopoverHeader pt={4} fontWeight="bold" border="0">
                    <Icon
                      fontSize="xl"
                      color="current"
                      mr={1}
                      aria-label='Only for super users'
                      as={RiFileInfoFill}
                    /> Description
                  </PopoverHeader>

                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    {
                      description ? description : 'Without comment'
                    }
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          );
        },
      },
      {
        Header: 'Country',
        accessor: 'country',
        Cell: (props: any) => {
          const country = props.cell.value;
          const data = countryByName(country);

          return (
            <>
              {data.name} {data.emoji}
            </>
          )
        },
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell: (props: any) => {
          const city = props?.cell?.value;
          const cityState = props?.cell?.row?.original?.cityState
          const zipCode = props?.cell?.row?.original?.zipCode

          return (
            <>
              {city}, {cityState} {zipCode && `(${zipCode})`}
            </>
          )
        },
      },
      {
        Header: 'Sites',
        accessor: 'qtySites',
        Cell: (props: any) => {
          const qtySites = props?.cell?.value || 0;
          const placeId = props?.cell?.row?.original?.id

          return (
            <>
              <Button
                rightIcon={<Icon as={FaSitemap} />}
                colorScheme="blue"
                variant="outline"
                as={Link}
                to={`${path}/${placeId}/sites`}
              >
                {qtySites} {pluralize('Site', qtySites)}
              </Button>
            </>
          )
        },
      },
    ],
    [path]
  )

  const initialStateTable: any = {
    columns: columns,
    data: dataTable
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  return (
    <Box>
      <BreadcrumbsKs
        data={
          [
            { name: 'Places', link: 'places' },
            { name: 'Directory', link: 'places' }
          ]
        }
      />
      <HeaderKs
        title='Places Directory'
        subtitle='Places available with their own sites'
      >
        <ButtonKs.Create
          isDisabled={!superUserUI}
          to={`${path}/new`}
          ml={2}
        />
        <ButtonKs.Refresh
          onClick={() => refreshTable()}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      {
        !loading && !placesLoading &&
        <Box overflow='auto'>
          <Table variant="striped" mt={4}  {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup: any) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <Th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span style={{ marginLeft: '5px' }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownIcon />
                            : <ArrowUpIcon />
                          : ''}
                      </span>
                    </Th>
                  ))}
                  <Th>
                    Options
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {
                rows.map((row: any, index: number) => {
                  prepareRow(row)

                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <Td {...cell.getCellProps()} headerTitle={cell.column.Header}>
                            {cell.render('Cell')}
                          </Td>
                        )
                      })}
                      <Td headerTitle='Options'>
                        <ButtonKs.OptionsMenu bgColor={index % 2 ? 'gray.100' : 'white'}>
                          <MenuGroup title="Options">
                            <MenuItem
                              isDisabled={!superUserUI}
                              as={Link}
                              to={`${path}/${row.original.id || row.original.placeId}/edit?mode=read`}
                            >
                              Edit Place
                            </MenuItem>
                            <MenuItem
                              isDisabled={!superUserUI}
                              as={Link}
                              to={`${path}/${row.original.id || row.original.placeId}/sites`}
                            >
                              Edit Sites
                            </MenuItem>
                          </MenuGroup>
                        </ButtonKs.OptionsMenu>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
            <TableCaption>Updated: {placesUpdatedAt}</TableCaption>
          </Table>
        </Box>
      }
      {
        (loading || placesLoading) &&
        <LoadingTable columns={5} />
      }
    </Box >
  );
}

export default PlacesDirectory;