import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  useColorModeValue
} from '@chakra-ui/react'
import { Link, useRouteMatch } from 'react-router-dom'
import { ChevronRightIcon } from '@chakra-ui/icons'

const dataDemo = [
  { 'name': 'This', 'link': 'this' },
  { 'name': 'Is', 'link': 'is' },
  { 'name': 'My', 'link': 'is' },
  { 'name': 'Demo!', 'link': 'demo' },
]

function BreadcrumbsKs(props: any) {

  const [dataBreadcrum, setDataBradcrum] = useState(props.data)
  const { url } = useRouteMatch();

  useEffect(() => {
    if (!props.data) setDataBradcrum(dataDemo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const colorBread = useColorModeValue("blackAlpha.500", "white")

  return (
    <div>
      <Breadcrumb
        mb={4}
        mt={-3}
        separator={<ChevronRightIcon color="gray.500" />}
        color="blackAlpha.500"
        fontSize="sm"
        ml={1}
      >
        {
          dataBreadcrum && dataBreadcrum.map((item: any, key: number) => {
            return (
              <BreadcrumbItem key={key}>
                <BreadcrumbLink as={Link} to={item.link || url} color={colorBread} >{item.name}</BreadcrumbLink>
              </BreadcrumbItem>
            )
          })
        }
      </Breadcrumb>
      <Divider mb={8} />
    </div>
  );
}

export default BreadcrumbsKs;