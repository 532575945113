import React, { useEffect, useState } from 'react';
import { Skeleton, FormControl, FormErrorMessage, Box, Select, FormLabel, Heading, Input, Text, useToast, Grid, GridItem, } from '@chakra-ui/react'
import { useForm } from "react-hook-form";
import CenterBox from 'pages/Components/generic/CenterBox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import capitalize from 'capitalize'
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions'
import AlertNoAccess from 'pages/Components/generic/Alerts/AlertNoAccess';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import OptionsCountries from 'pages/Components/generic/Selects/OptionsCountries';
import { RiEyeLine, RiPencilLine } from 'react-icons/ri';
import HeaderCreatedBy from 'pages/Components/generic/HeaderCreatedBy';
import { toastTypes } from 'helpers/alerts';

interface Props {
  typeForm: 'create' | 'edit'
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PlacesEdit({ typeForm }: Props) {

  const { register, handleSubmit, reset, setValue, formState: { errors, isSubmitting } } = useForm();

  const { placeId } = useParams<any>(); // only in case of 'edit'
  const { data: { superUserUI } } = useContextUIHook()

  const toast = useToast()
  const history = useHistory();
  const query = useQuery();

  const stateRedux = useSelector((store: any) => store.placeReducer);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  const [placeData, setPlaceData] = useState<any>({})
  const [viewMode, setViewMode] = useState(query.get("mode") === 'read' || false)

  const onSubmit = (values: any) => {
    toast(toastTypes.processing)

    return new Promise((resolve, reject) => {
      const payload = values;

      if (typeForm === 'create') {
        dispatch(actions.places.createPlace(payload, (status: any) => {
          if (status === true) {

            toast(toastTypes.custom({
              title: "Place created correctly!",
              description: "You will be redirected to your directory",
              status: "success",
            }))

            setTimeout(() => {
              reset();
              resolve(true)
              history.push(`/app/places`);
            }, 2000)

          } else {
            toast(toastTypes.error)
            reject(false)
          }
        }))
      } else if (typeForm === 'edit') {
        dispatch(actions.places.updatePlace(placeId, payload, (status: any, info: any) => {
          if (status === true) {
            toast(toastTypes.updated)
            history.push(`/app/places`);
            resolve(true)
          } else {
            toast(toastTypes.error)
          }
        }))
      }
    })
  }

  useEffect(() => {
    if (typeForm === 'edit') {
      setLoading(true)
      dispatch(actions.places.getPlaceById(placeId, false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateRedux.placesById[placeId]) {
      const placeData = stateRedux.placesById[placeId];
      setPlaceData(placeData)
      Object.keys(placeData).map((key: string) => {
        return setValue(key, placeData[key])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.placesById, stateRedux.places]);

  useEffect(() => {
    if (stateRedux.loadingPlaces !== null) {
      setLoading(stateRedux.loadingPlaces)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.loadingPlaces]);

  const MainContent = () => <Box mt={8}>
    <Skeleton isLoaded={!loading}>
      {viewMode &&
        <>
          <HeaderCreatedBy resource={placeData} />
          <Grid templateColumns="repeat(6, 1fr)" mt={4} gap={6}>
            <GridItem colSpan={6}>
              <FormLabel>Name</FormLabel>
              {placeData?.name}
            </GridItem>
            <GridItem colSpan={6}>
              <FormLabel>Description</FormLabel>
              {placeData?.description}
            </GridItem>
            <GridItem colSpan={6}>
              <FormLabel>Adress Line 1</FormLabel>
              {placeData?.adressline1}
            </GridItem>
            <GridItem colSpan={5}>
              <FormLabel>Adress Line 2</FormLabel>
              {placeData?.adressline2}
            </GridItem>
            <GridItem colSpan={1}>
              <FormLabel>Zip Code</FormLabel>
              {placeData?.zipCode}
            </GridItem>
            <GridItem colSpan={6}>
              <FormLabel>Country</FormLabel>
              {placeData?.country}
            </GridItem>
            <GridItem colSpan={5}>
              <FormLabel>City</FormLabel>
              {placeData?.city}
            </GridItem>
            <GridItem colSpan={1}>
              <FormLabel>State</FormLabel>
              {placeData?.cityState}
            </GridItem>
          </Grid>
        </>
      }
      {!viewMode &&
        <form onSubmit={handleSubmit(onSubmit)}>

          <Grid templateColumns="repeat(6, 1fr)" mt={4} gap={6}>
            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  id="name"
                  {...register("name", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>


            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.description}>
                <FormLabel>Description</FormLabel>
                <Input
                  type="text"
                  id="description"
                  {...register("description", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.description && errors.description.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={5}>
              <FormControl isInvalid={errors.adressline1}>
                <FormLabel>Adress Line 1</FormLabel>
                <Input
                  type="text"
                  id="adressline1"
                  {...register("adressline1", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.adressline1 && errors.adressline1.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isInvalid={errors.zipCode}>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  type="text"
                  id="zipCode"
                  {...register("zipCode", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.zipCode && errors.zipCode.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={6}>
              <FormControl isInvalid={errors.adressline2}>
                <FormLabel>Adress Line 2</FormLabel>
                <Input
                  type="text"
                  id="adressline2"
                  {...register("adressline2", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.adressline2 && errors.adressline2.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>


            <GridItem colSpan={6}>
              <FormControl mt={4} isInvalid={errors.country}>
                <FormLabel>Country</FormLabel>
                <Select
                  id="country"
                  placeholder="Select a Country"
                  {...register("country", {
                    required: "This is required",
                  })}
                >
                  <OptionsCountries simple={true} />
                </Select>
                <FormErrorMessage>
                  {errors.country && errors.country.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={3}>
              <FormControl isInvalid={errors.city}>
                <FormLabel>City</FormLabel>
                <Input
                  type="text"
                  id="city"
                  {...register("city", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.city && errors.city.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl isInvalid={errors.cityState}>
                <FormLabel>State</FormLabel>
                <Input
                  type="text"
                  id="cityState"
                  {...register("cityState", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.cityState && errors.cityState.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>


          <ButtonKs.Success
            text={typeForm === 'create' ? 'Create place' : 'Save Changes'}
            isLoading={isSubmitting}
            type="submit"
            mt={10} />

          <ButtonKs.Cancel
            isDisabled={isSubmitting}
            ml={4}
            mt={10}
            to='/app/places'
          />
        </form>
      }
    </Skeleton>
  </Box>

  return (
    <>
      <BreadcrumbsKs
        data={
          [
            { name: 'Places', link: '/app/places' },
            { name: 'Directory', link: '/app/places' },
            { name: capitalize(typeForm), link: null }
          ]
        }
      />
      <CenterBox>
        <Grid templateColumns="repeat(6, 1fr)" mt={4} gap={6}>
          <GridItem colSpan={5}>
            <Heading>
              {typeForm === 'create' && 'New Place'}
              {typeForm === 'edit' && !viewMode && 'Edit Place'}
              {typeForm === 'edit' && viewMode && 'View Place'}
            </Heading>
            <Text>
              {typeForm === 'create' && 'Add a new place to Kitspace Hub'}
              {typeForm === 'edit' && !viewMode && 'Update all the necessary fields'}
              {typeForm === 'edit' && viewMode && 'Edit this resource with the necessary privileges'}
            </Text>
          </GridItem>
          <GridItem colSpan={1}>
            {typeForm === 'edit' &&
              <ButtonKs.IconCustom float='right' onClick={() => {
                viewMode === false ? history.push('?mode=read') : history.push('?mode=edit')
                setViewMode(!viewMode)
              }} icon={viewMode ? <RiPencilLine /> : <RiEyeLine />} />
            }
          </GridItem>
        </Grid>
        {
          superUserUI === true ?
            <MainContent /> :
            <AlertNoAccess typeError='superuser' />
        }
      </CenterBox>
    </>
  );
}

export default PlacesEdit;