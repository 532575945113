import React, { useState, useContext } from 'react';
import { Select, Box, Heading, Divider, Spacer, Flex, useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import UIContext from 'services/contexts/ContextUI'
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook'
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { toastTypes } from 'helpers/alerts';

function SettingsGeneral() {

  const { functions: { updateLanguage } } = useContextUIHook();

  const toast = useToast()
  const intl = useIntl()
  const cttUI = useContext(UIContext);
  const [language, setLanguage] = useState(cttUI.data.language);

  const saveChanges = () => {
    const lang = language || 'en-US';
    updateLanguage(lang).then(() => {
      toast(toastTypes.saved)
    })
  }

  return (
    <>
      <Heading as="h1" size="xl">
        {intl
          .formatMessage(
            {
              defaultMessage: 'Platform',
              id: 'ks.settings.title.platform'
            }
          )
        }
      </Heading>
      <Divider />
      <Box w={400} p={4}>

        <Box marginBottom='1em'>
          <label>
            {intl
              .formatMessage(
                {
                  defaultMessage: 'Language',
                  id: 'ks.settings.select.language'
                }
              )
            }
          </label>
          <Select
            onChange={(e) => setLanguage(e.target.value)}
            placeholder={intl
              .formatMessage(
                {
                  id: 'ks.settings.select.language.placeholder'
                }
              )
            }
            value={language}
          >
            <option value="en-US">English (United States) 🇺🇸</option>
            <option value="es">Español 🇪🇸</option>
          </Select>
        </Box>

        <Box marginBottom='1em'>
          <label> {intl
            .formatMessage(
              {
                id: 'ks.settings.select.country'
              }
            )
          } </label>
          <Select placeholder={intl
            .formatMessage(
              {
                id: 'ks.settings.select.country.placeholder'
              }
            )
          } >
          </Select>
        </Box>

      </Box >
      <Box position='fixed' bottom='0' width='calc(100% - 2em - 4rem)'>
        <Divider />
        <Flex>
          <Box p="4">
            <ButtonKs.SaveChanges onClick={saveChanges} />
          </Box>
          <Spacer />
          <Box p="4">
            <ButtonKs.Cancel />
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default SettingsGeneral;