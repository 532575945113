export const projectPhases: any = {
  'prospect': { name: 'Prospect', value: 'prospect' },
  'requirements_capture': { name: 'Requirements Capture', value: 'requirements_capture' },
  'operational_design': { name: 'Operational Design', value: 'operational_design' },
  'functional_design': { name: 'Functional Design', value: 'functional_design' },
  'design_testing': { name: 'Design Testing', value: 'design_testing' },
  'production': { name: 'Production', value: 'production' },
  'installation': { name: 'Installation', value: 'installation' },
  'operation': { name: 'Operation', value: 'operation' },
}

export const projectStatus: any = {
  'in_progress': { name: 'In Progress', value: 'in_progress' },
  'blocked': { name: 'Blocked', value: 'blocked' },
  'complete': { name: 'Complete', value: 'complete' },
  'cancelled': { name: 'Cancelled', value: 'cancelled' },
  'on_hold': { name: 'On Hold', value: 'on_hold' },
  'needs_approval': { name: 'Needs approval', value: 'needs_approval' },
  'closed_out': { name: 'Closed out', value: 'closed_out' },
}

export const projectStatusMultiSelect = Object.keys(projectStatus).map((index) => {
  return { value: projectStatus[index].value, label: projectStatus[index].name, colorScheme: 'blue' }
}
)

export const projectConfidence: any = {
  'low': { name: 'Low', value: 'low' },
  'normal': { name: 'Normal', value: 'normal' },
  'high': { name: 'High', value: 'high' },
}

export const typesOrg: any = {
  'retail': { name: 'Retail', value: 'retail' },
  'property_developer': { name: 'Property Developer', value: 'property_developer' },
  'building_management_company': { name: 'Building management company', value: 'building_management_company' },
  'building_owner': { name: 'Building owner', value: 'building_owner' },
  'architect': { name: 'Architect', value: 'architect' },
  'interior_designer': { name: 'Interior designer', value: 'interior_designer' },
  'product_designer': { name: 'Product designer', value: 'product_designer' },
  'fabricator': { name: 'Fabricator', value: 'fabricator' },
  'distributor': { name: 'Distributor', value: 'distributor' },
  'freight_forwarder': { name: 'Freight forwarder', value: 'freight_forwarder' },
  'delivery_company': { name: 'Delivery company', value: 'delivery_company' },
  'installation_company': { name: 'Installation company', value: 'installation_company' },
  'installer': { name: 'Installer', value: 'installer' },
  'electrical_contractor': { name: 'Electrical contractor', value: 'electrical_contractor' },
  'mechanical_contractor': { name: 'Mechanical contractor', value: 'mechanical_contractor' },
  'general_contractor': { name: 'General contractor', value: 'general_contractor' },
  'warehouse_provider': { name: 'Warehouse provider', value: 'warehouse_provider' },
  'code_consultant': { name: 'Code consultant', value: 'code_consultant' },
  'client_representative': { name: 'Client representative', value: 'client_representative' },
  'marketing_company': { name: 'Marketing company', value: 'marketing_company' },
  'realtor': { name: 'Realtor', value: 'realtor' },
  'engineer': { name: 'Engineer', value: 'engineer' },
  'lightingconsultant': { name: 'Lighting consultant', value: 'lightingconsultant' },
  'it_provider': { name: 'IT provider', value: 'it_provider' },
  'cost_consultant': { name: 'Cost consultant', value: 'cost_consultant' },
  'retail_operator': { name: 'Retail operator', value: 'retail_operator' },
  'other': { name: 'Other', value: 'other' }
}

export const typeOrgById = (id: string) => {
  if (typesOrg[id]) return typesOrg[id]
  return { name: 'ERROR TYPE ORG', value: 'error_type_org' }
}

export const cleanDocNative = (payload: any) => {
  const tmpPayload = payload;
  if (tmpPayload.id) delete tmpPayload.id
  if (tmpPayload.createdBy) delete tmpPayload.createdBy
  if (tmpPayload.createdAt) delete tmpPayload.createdAt
  if (tmpPayload.updatedBy) delete tmpPayload.updatedBy
  if (tmpPayload.updatedAt) delete tmpPayload.updatedAt
  return tmpPayload;
}