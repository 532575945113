import moment from 'moment';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { actions } from 'services/redux/actions'
import { useOrganisationHook } from './useOrganisationHook';

export function usePlaceHook() {

  const statePlaces = useSelector((store: any) => store.placeReducer);
  const dispatch = useDispatch();
  const [placesLoading, setPlacesLoading] = useState(true)
  const [places, setPlaces] = useState([])

  const { data: { organisations } } = useOrganisationHook()
  const { data: { superUserUI } } = useContextUIHook()

  const updatePlaces = (cache: boolean = false) => {
    setPlacesLoading(true)
    dispatch(actions.places.getPlaces(cache, () => {
      setPlacesLoading(false)
    }))
  }

  useEffect(() => {
    updatePlaces(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (superUserUI === false) {
      const placesSummaryID: Array<string> = []

      organisations.map((orgData: any) => {
        orgData.placesRelated.map((placeId: string) => {
          return placesSummaryID.push(placeId)
        })
        return null
      })

      const filterPlaces = statePlaces.places.filter((placeData: any) => {
        if (placesSummaryID.includes(placeData.id)) return true
        return false
      });
      setPlaces(filterPlaces)
    } else {
      setPlaces(statePlaces.places)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superUserUI, organisations, statePlaces.places]);

  const getPlaceById = (placeId: string) => {
    if (statePlaces.placesById) {
      if (statePlaces.placesById[placeId] && statePlaces.placesById[placeId].name) return statePlaces.placesById[placeId].name
      return 'Unknown place'
    }
    return 'Error Place Data'
  }

  return {
    fun: {
      updatePlaces,
      getPlaceById
    },
    data: {
      placesUpdatedAt: statePlaces.updatedAt ? moment(statePlaces.updatedAt).fromNow() : 'Invalid Date',
      placesLoading: placesLoading,
      placesById: statePlaces.placesById,
      places: places,
      placesForMultiSelect: places.map((organisation: any) => {
        return { 'value': organisation.id, 'label': organisation.name, 'colorScheme': 'green' }
      }),
      placesForSelect: places.map((organisation: any) => {
        return { 'value': organisation.id, 'text': organisation.name }
      }),
    },
    dataSites: {
      sitesLoading: statePlaces.loadingSites,
      sitesById: statePlaces.sitesById,
      sitesByPlace: statePlaces.sitesByPlace,
    }
  }
}