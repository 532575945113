import React from 'react';
import { Box } from '@chakra-ui/react'

export const BoxShadow = ({ children, noBorder, ...props }: any) => (
  <Box
    p={5}
    shadow={noBorder === true ? '' : "md"}
    borderWidth={noBorder === true ? '0px' : '1px'}
    borderRadius="2xl"
    height={props.height ? props.height : "100%"}
    {...props}
  >
    {children}
  </Box>
)