import { Skeleton } from '@chakra-ui/skeleton';
import { useToast } from '@chakra-ui/toast';
import { toastTypes } from 'helpers/alerts';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { Tr, Td } from 'pages/Components/generic/ResponsiveTable/table';
import React, { useEffect, useState } from 'react';
import { FaPlus, } from 'react-icons/fa';
import { RiSaveFill, RiCloseCircleLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { actions } from 'services/redux/actions';
import { useImmer } from 'use-immer';
import { ChannelSelector, DeliverySelector, DaysSelector, TimeSelector, ProjectStatusFilterSelector, RecipentSelector } from './Helpers';

interface Props {
  mode: 'new' | 'edit';
  data?: any; // report project data
  cancelEdit?: () => void;
}

function EditReportProject({ mode, data, cancelEdit }: Props) {

  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const bg = '' //useToken('colors', 'white')

  const initData = {
    channels: [],
    projectStatusFilter: [],
    active: true,
    runDays: [],
    runHour: 9,
    notifEmail: []
  }

  const getInitData = () => {
    if (mode === 'new') return initData
    if (mode === 'edit') {
      const base = initData;

      base.active = data.scheduledTask.active || false;
      base.channels = data.channels || [];
      base.projectStatusFilter = data.projectStatusFilter || [];
      base.notifEmail = data.scheduledTask.notifEmail || [];
      base.runDays = data.scheduledTask.runDays || [];
      base.runHour = data.scheduledTask.runHour || 0;

      return base
    }
  }

  const [reportData, setReportData] = useImmer<any>(getInitData())

  useEffect(() => {
    (reportData.channels.length > 0 &&
      reportData.projectStatusFilter.length > 0 &&
      reportData.runDays.length > 0 &&
      reportData.runHour &&
      reportData.notifEmail.length > 0
    ) ? setDisabled(false) : setDisabled(true)
  }, [reportData]);

  const createNewReport = () => {
    setLoading(true)
    toast(toastTypes.processing)
    dispatch(actions.insights.createProjectReport(reportData, () => {
      setReportData(initData)
      setLoading(false)
      toast(toastTypes.created)
    }))
  }

  const updateReport = () => {
    setLoading(true)
    if (data.id) {
      toast(toastTypes.processing)
      dispatch(actions.insights.updateProjectReport(data.id, reportData, () => {
        cancelEdit && cancelEdit()
        toast(toastTypes.saved)
      }))
    } else {
      setLoading(false)
      toast(toastTypes.invalid)
    }
  }

  return (
    <>
      {
        loading &&
        <>
          <Td>
            <Skeleton height='30px' />
          </Td>
          <Td>
            <Skeleton height='30px' />
          </Td>
          <Td>
            <Skeleton height='30px' />
          </Td>
          <Td>
            <Skeleton height='30px' />
          </Td>
          <Td>
            <Skeleton height='30px' />
          </Td>
          <Td>
            <Skeleton height='30px' />
          </Td>
          <Td>
            <Skeleton height='30px' />
          </Td>
        </>
      }
      {
        !loading &&
        <Tr>
          <Td bg={bg + '!important'}>
            <ChannelSelector value={reportData.channels} setValue={setReportData} />
          </Td>
          <Td bg={bg + '!important'}>
            <DeliverySelector value={reportData.active} setValue={setReportData} />
          </Td>
          <Td bg={bg + '!important'}>
            <DaysSelector value={reportData.runDays} setValue={setReportData} />
          </Td>
          <Td bg={bg + '!important'}>
            <TimeSelector value={reportData.runHour} setValue={setReportData} />
          </Td>
          <Td bg={bg + '!important'}>
            <ProjectStatusFilterSelector value={reportData.projectStatusFilter} setValue={setReportData} />
          </Td>
          <Td bg={bg + '!important'}>
            <RecipentSelector value={reportData.notifEmail} setValue={setReportData} />
          </Td>
          <Td bg={bg + '!important'}>
            {mode === 'new' ?
              <ButtonKs.IconCustom
                isDisabled={disabled}
                icon={<FaPlus />}
                colorScheme='blue'
                onClick={createNewReport}
              />
              :
              <ButtonKs.IconCustom
                m={1}
                isDisabled={disabled}
                icon={<RiSaveFill />}
                colorScheme='blue'
                onClick={updateReport}
              />
            }
            {mode === 'edit' &&
              <ButtonKs.IconCustom
                m={1}
                icon={<RiCloseCircleLine />}
                colorScheme='red'
                onClick={() => { cancelEdit && cancelEdit() }}
              />
            }
          </Td>
        </Tr>
      }
    </>
  );
}

export default EditReportProject;