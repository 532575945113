import React, { useState, } from 'react';
import CenterBox from 'pages/Components/generic/CenterBox';
import { Grid, GridItem, } from '@chakra-ui/react';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import 'firebase/storage';
import randomstring from 'randomstring';
import BoxResponsiveTwo from 'pages/Components/layout/Box/BoxResponsiveTwo';
import List from './List';
import { useDispatch, } from 'react-redux';
import { actions } from 'services/redux/actions';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import UploadFiles from '../../UploadFiles';

function Documents({ projId }: any) {

  const dispatch = useDispatch();
  const [uploadMode, setUploadMode] = useState(true)

  return (
    <CenterBox fullWidth>
      <HeaderKs
        size='lg'
        title={`Documents`}
      >
        <ButtonKs.Refresh
          onClick={() => dispatch(actions.projects.getDocumentsByProject(projId))}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      <BoxResponsiveTwo boxA={uploadMode ? 6 : 12} boxB={uploadMode ? 6 : null}>
        <GridItem>
          <List projId={projId} />
        </GridItem>
        {uploadMode &&
          <GridItem>
            <Grid>
              <UploadFiles
                projId={projId}
                multipleFiles={true}
                typeDropZone='newDocument'
                refStorageFn={() => {
                  return `/projects/${projId}/documents/RANDOM__${randomstring.generate(20)}__DOC__T__${Date.now()}__V__1`;
                }}
              />
            </Grid>
          </GridItem>
        }
      </BoxResponsiveTwo>
      {
        !uploadMode ?
          <ButtonKs.Primary mt={6} onClick={() => setUploadMode(!uploadMode)}>
            Add
          </ButtonKs.Primary>
          :
          <ButtonKs.Cancel mt={6} onClick={() => setUploadMode(!uploadMode)} />
      }
    </CenterBox >
  );
}

export default Documents;