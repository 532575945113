import React, { useState, useEffect } from 'react';
import { Heading, Text, Alert, AlertIcon, Box, Skeleton, Input, InputGroup, InputRightElement, Button, FormLabel, Divider, List, ListItem, ListIcon, Badge, useToast } from '@chakra-ui/react'
import { useUser } from 'reactfire';
import pluralize from 'pluralize';
import { avoidEmptyArray, avoidSpacesArray, isEmail } from 'helpers/mini';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions'
import { useHistory, useParams } from 'react-router-dom';
import { RiUser3Fill, RiMailSendLine } from 'react-icons/ri'
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import CenterBox from 'pages/Components/generic/CenterBox';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import AlertNoAccess from 'pages/Components/generic/Alerts/AlertNoAccess';
import { toastTypes } from 'helpers/alerts';

function OrganisationsAddPeople() {

  const [usersCurated, setUsersCurated] = useState<Array<string>>([])
  const [usersInvitedByEmail, setusersInvitedByEmail] = useState<Array<string>>([])
  const [adminsByEmail, setAdminByEmail] = useState<Array<string>>([])
  const [inputEmails, setInputEmails] = useState<string>('')


  const [organisation, setOrganisation] = useState<any>({})
  const [offScreen, setOffScreen] = useState(true)
  const [loading, setLoading] = useState(false)

  const { data: { superUserUI } } = useContextUIHook()
  const { orgId } = useParams<any>(); // only in case of 'edit'
  const user: any = useUser();
  const history = useHistory();

  const stateRedux = useSelector((store: any) => store.organisationReducer);
  const dispatch = useDispatch();
  const toast = useToast()

  useEffect(() => {
    if (stateRedux.organisationsById[orgId]) {
      const orgData = stateRedux.organisationsById[orgId];
      setOrganisation(orgData)
      setOffScreen(false)
    } else {
      dispatch(actions.organisations.getOrganisationById(orgId, true, null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.organisationsById]);

  const removeItem = (emailDeleted: string) => {
    setusersInvitedByEmail(usersInvitedByEmail.filter((email: string) => email !== emailDeleted));
    setAdminByEmail([...adminsByEmail.filter((email: string) => email !== emailDeleted)])
  }

  const addPeopleByEmail = () => {
    const res = inputEmails.split(',');
    if (res.length > 0 && res[0] !== '') {

      const parsed: Array<string> = [
        ...usersInvitedByEmail,
        ...avoidSpacesArray(avoidEmptyArray(res))
      ]

      setusersInvitedByEmail(parsed);
      setUsersCurated(parsed.filter((email: string) => isEmail(email)))

      setInputEmails('')
    }
  }

  const saveChanges = () => {

    const payload = {
      adminsByEmail: adminsByEmail,
      adminsByUID: [],
      addUsersByEmail: usersCurated,
      addUsersByUID: [],
    }

    setLoading(true)
    dispatch(actions.organisations.addMembers(orgId, payload, (status: any, info: any) => {
      setLoading(false)
      toast(toastTypes.saved)
      setUsersCurated([])
      setusersInvitedByEmail([])
      history.push('../people')
    }))
  }

  return (
    <>
      {
        organisation.name &&
        <BreadcrumbsKs
          data={
            [
              { name: 'Organizations', link: '/app/organisations' },
              { name: 'Directory', link: '/app/organisations' },
              { name: organisation.name, link: `/app/organisations/${organisation.id}/edit` },
              { name: 'People', link: `/app/organisations/${organisation.id}/people` },
              { name: 'Add', link: `/app/organisations/${organisation.id}/people/edit` },
            ]
          }
        />
      }
      <CenterBox>
        <Heading>
          {organisation.name}{organisation.name && ':'} Invite people
        </Heading>
        <Text maxWidth='75%'>
          Invite users to join {organisation.name}.
          <br /><br />
          Users with email addresses already registered with Kitspace Hub will automatically join the organization. All other users will be invited to join Kitspace Hub. Once they register their details they will be invited to join the organization.
          <br /><br />
          As an Admin you can also edit parts of the users profile here
        </Text>
        {
          (organisation && organisation.state === 'active' && organisation.createdBy === user.data.uid) || (superUserUI === true && organisation.state !== 'draft') ?

            <Box mt={8}>
              <Skeleton isLoaded={!stateRedux.loading || !offScreen}>
                <FormLabel>Add new users</FormLabel>
                <InputGroup size="md">
                  <Input value={inputEmails} onChange={(e) => setInputEmails(e.target.value)} pr="4.5rem" placeholder='user@mail.com, otheruser@mail.com' />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={addPeopleByEmail}>
                      Add
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Divider mt={4} mb={4} />
                {usersCurated.length} {pluralize('Email', usersCurated.length)} &nbsp;
                {usersInvitedByEmail.length - usersCurated.length !== 0 &&
                  <>
                    | ({usersInvitedByEmail.length - usersCurated.length} Invalid emails)
                  </>
                }
                <ButtonKs.DangerPopOver text='Delete All' float='right' size={'sm'} callback={() => {
                  setusersInvitedByEmail([])
                  setUsersCurated([])
                }} />
                <List spacing={3} mt={10}>
                  {usersInvitedByEmail.map((userEmail: string, key: number) => {
                    return (
                      <ListItem key={key}>
                        <ListIcon as={RiMailSendLine} color="green" mr={0} />
                        <ListIcon as={RiUser3Fill} color="green" />

                        <Text as={isEmail(userEmail) ? 'abbr' : 'del'}>{userEmail}</Text>
                        {!isEmail(userEmail) &&
                          <Badge variant="outline" colorScheme="red" ml={3}>
                            Invalid Email
                          </Badge>
                        }

                        {isEmail(userEmail) &&
                          <>
                            {adminsByEmail.includes(userEmail) &&
                              <Badge
                                ml={3}
                                colorScheme="blue"
                                onClick={() => setAdminByEmail([...adminsByEmail.filter((email: string) => email !== userEmail)])}
                                cursor="pointer"
                              >
                                Admin
                              </Badge>
                            }
                            {!adminsByEmail.includes(userEmail) &&
                              <Badge
                                ml={3}
                                onClick={() => setAdminByEmail([...adminsByEmail, userEmail])}
                                cursor="pointer"
                              >
                                Member
                              </Badge>
                            }
                          </>
                        }

                        <Button size="xs" float='right' onClick={() => removeItem(userEmail)}>
                          Delete
                        </Button>

                        <Divider mt={4} />
                      </ListItem>
                    )
                  })}
                </List>
                <ButtonKs.Success
                  text='Send Invitations'
                  mt={6}
                  onClick={saveChanges}
                  disabled={usersInvitedByEmail.length === 0 || loading}
                  isLoading={loading}
                />
              </Skeleton>
            </Box>
            :
            <>
              {
                organisation.state === 'draft' ?
                  <Alert status="warning" mt={6}>
                    <AlertIcon />
                    You can't add people to an organization in draft
                  </Alert>
                  :
                  <AlertNoAccess />
              }
            </>
        }
      </CenterBox >
    </>
  );
}

export default OrganisationsAddPeople;