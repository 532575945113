import { UPDATE_ALL, LOADING } from './user.type'

const initialState = {
  loading: false,
  users: [],
  usersById: {},
  updatedAt: null,
}

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ALL:
      return {
        ...state,
        users: action.payload.data,
        usersById: action.payload.usersById,
        updatedAt: new Date(),
        loading: false,
      }
    default:
      return state;
  }
};
