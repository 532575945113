import UIContext from 'services/contexts/ContextUI'
import { useContext } from 'react'
import { LOCAL_ST, localSt } from 'helpers/local-session-storage'
import { useUser } from 'reactfire';
import AppContext from '../ContextApp';

export function useContextUIHook() {

  const cttUI = useContext(UIContext);
  const cttApp = useContext(AppContext);
  const user = useUser();

  const updateLanguage = (language: string) => {
    return new Promise((res) => {
      localSt.defineNow(LOCAL_ST.settingsLanguage, language)
      if (cttUI.setDataImmer) {
        cttUI.setDataImmer((draft: any) => {
          draft.data.language = language;
        })
      }
      setTimeout(() => res(true), 1000)
    })
  }

  const superUserSwitch = (val: boolean, validate = true) => {
    // Only available for super users, this simplificate the UI, is not a security feature
    if ((cttApp.data.superUserReference === true || validate === false) || window.location.hostname === "localhost") {
      if (cttUI.setDataImmer) {
        cttUI.setDataImmer((draft: any) => {
          draft.data.superUser = val;
        })
      }
      return val
    } else {
      return !val
    }
  }

  return {
    data: {
      superUserUI: cttUI.data.superUser,
      user: user
    },
    functions: {
      updateLanguage,
      superUserSwitch
    }
  }
}