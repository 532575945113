import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  Grid, GridItem, Icon, Stack, Badge, Button, Divider, InputLeftAddon,
  InputGroup,
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  FormControl,
  FormHelperText,
  FormLabel,
  Tooltip,
  useEditableControls,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  useToast,
  Select
} from '@chakra-ui/react';
import { toastTypes } from 'helpers/alerts';
import { timezonesMomentJs } from 'helpers/timezones';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import SelectMultiple, { CreatableSelect } from 'pages/Components/generic/SelectMultiple';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import React, { useState } from 'react';
import { RiCheckboxCircleLine, RiCheckboxBlankCircleLine, RiInformationLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useUserHook } from 'services/hooks/useUserHook';
import { actions } from 'services/redux/actions';

function TitleEdit({ title, updateTitle }: any) {
  /* Here's a custom control */
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()



    return isEditing ? (
      <ButtonGroup ml={4} mt={1} justifyContent="center" size="sm">
        <IconButton borderRadius="5px" icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton borderRadius="5px" icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <ButtonGroup ml={4} mt={1} justifyContent="center" size="sm">
        <IconButton borderRadius="5px" size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </ButtonGroup>
    )
  }

  return (
    <>
      <Editable
        defaultValue={title}
        fontSize="2xl"
        width="100%"
        isPreviewFocusable={false}
        display='inline-flex'
        marginTop={0}
        onSubmit={(newTitle) => {
          updateTitle(newTitle)
        }}
      >
        <EditablePreview />
        <EditableInput />
        <EditableControls />
      </Editable>
    </>
  )
}


function Task({ taskData }: any) {

  const [active, setActive] = useState(taskData.active)
  const [runDays, setRunDays] = useState(taskData.runDays);
  const [runHour, setRunHour] = useState(parseInt(taskData.runHour));
  const [title, setTitle] = useState(taskData.title)
  const [timezone, setTimezone] = useState(taskData.timezone)

  const [usersByEmail, setUsersByEmail] = useState(taskData.notifEmail);
  const [usersByUID, setUsersByUID] = useState(taskData.notifUser)

  const [editMode, setEditMode] = useState(false);

  const { data: { usersForMultiSelect, usersById } } = useUserHook();
  const dispatch = useDispatch();
  const toast = useToast();

  const updateDays = (day: number) => {
    // 1 mon - 7 sun
    if (editMode) {
      runDays.includes(day) ?
        setRunDays(runDays.filter((num: number) => num !== day)) :
        setRunDays([...runDays, day])
    }
  }

  const updateHour = (hour: any) => {
    if (editMode) setRunHour(parseInt(hour))
  }

  const updateTitle = (newTitle: any) => {
    setTitle(newTitle)
    toast(toastTypes.processing)
    dispatch(actions.scheduledtasks.updateScheduledTasks(taskData.id, { title: newTitle }, (status: boolean) => {
      status ? toast(toastTypes.success) : toast(toastTypes.error)
    }))
  }

  const updateActive = (activeVal: boolean) => {
    toast(toastTypes.processing);
    dispatch(actions.scheduledtasks.updateScheduledTasks(taskData.id, { active: activeVal }, (status: boolean) => {
      status ? toast(toastTypes.success) : toast(toastTypes.error)
    }))
  }

  const updateTaskData = () => {

    let users = usersByUID.map((dataSelect: any) => dataSelect.value)
    if (users.length !== 0 && users.every((element: any) => element === undefined)) {
      users = taskData.notifUser
    }

    let emails = usersByEmail.map((dataSelect: any) => dataSelect.value)
    if (emails.length !== 0 && emails.every((element: any) => element === undefined)) {
      emails = taskData.notifEmail
    }

    toast(toastTypes.processing);
    const payload = {
      runDays: runDays,
      runHour: runHour,
      notifEmail: emails,
      notifUser: users,
      timezone: timezone
    }

    dispatch(actions.scheduledtasks.updateScheduledTasks(taskData.id, payload, (status: boolean) => {
      status ? toast(toastTypes.success) : toast(toastTypes.error)
    }))
  }

  const handleEmailsChange = (newValue: any, actionMeta: any) => {
    setUsersByEmail(newValue)
  };

  const handleUsersChange = (newValue: any) => {
    setUsersByUID(newValue)
  };

  return (
    <BoxShadow mt={6} pb={2}>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={10}>
          <Stack direction="row">
            <Icon
              cursor='pointer'
              as={!active ? RiCheckboxBlankCircleLine : RiCheckboxCircleLine}
              onClick={() => {
                updateActive(!active)
                setActive(!active)
              }
              }
              boxSize='2em'
              color='green'
              marginTop={1}
            />
            <TitleEdit title={title} updateTitle={updateTitle} />
          </Stack>
        </GridItem>
        <GridItem colSpan={2}>
          <Box float='right'>
            {
              active ?
                <Badge colorScheme='green' ml={4}>
                  Active
                </Badge> :
                <Badge colorScheme='red' ml={4}>
                  Paused
                </Badge>
            }
          </Box>
        </GridItem>
        <GridItem colSpan={12} mt={2} mb={6}>
          <Divider />
        </GridItem>
        <GridItem colSpan={12} mb={6}>
          <Select
            isDisabled={!editMode}
            placeholder="Please select a timezone..."
            value={timezone}
            onChange={(timezone) => { setTimezone(timezone.target.value) }}
          >
            {timezonesMomentJs.map((timezone: string, key: number) => {
              return (<option value={timezone} key={key}>{timezone}</option>)
            })}
          </Select>
        </GridItem>
        <GridItem colSpan={12}>
          <Box display='inline-block' maxWidth='200px' mr={4}>
            <InputGroup>
              <InputLeftAddon children="HS" />
              <NumberInput
                isDisabled={!editMode}
                borderRadius='0px'
                defaultValue={9}
                min={0}
                max={23}
                clampValueOnBlur={false}
                value={runHour}
                onChange={(valueString) => updateHour(valueString)}
              >
                <NumberInputField borderRadius='0px' />
                <NumberInputStepper >
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputGroup>
          </Box>
          <Button isDisabled={!editMode} onClick={() => updateDays(1)} colorScheme={runDays.includes(1) ? 'blue' : 'gray'} mr={1}>Mon</Button>
          <Button isDisabled={!editMode} onClick={() => updateDays(2)} colorScheme={runDays.includes(2) ? 'blue' : 'gray'} mr={1}>Tue</Button>
          <Button isDisabled={!editMode} onClick={() => updateDays(3)} colorScheme={runDays.includes(3) ? 'blue' : 'gray'} mr={1}>Wed</Button>
          <Button isDisabled={!editMode} onClick={() => updateDays(4)} colorScheme={runDays.includes(4) ? 'blue' : 'gray'} mr={1}>Thu</Button>
          <Button isDisabled={!editMode} onClick={() => updateDays(5)} colorScheme={runDays.includes(5) ? 'blue' : 'gray'} mr={1}>Fri</Button>
          <Button isDisabled={!editMode} onClick={() => updateDays(6)} colorScheme={runDays.includes(6) ? 'blue' : 'gray'} mr={1}>Sat</Button>
          <Button isDisabled={!editMode} onClick={() => updateDays(7)} colorScheme={runDays.includes(7) ? 'blue' : 'gray'} mr={1}>Sun</Button>
        </GridItem>
        <GridItem colSpan={12}>
          <FormControl mt={6} isInvalid={false}>
            <FormLabel>
              Users
              <Tooltip
                hasArrow
                label="Users can unsubscribe whenever they want"
                bg="gray.300"
                color="black"
              >
                <Box display='inline-block' height='1em' width='1em' paddingTop='3px' lineHeight='1em' ml={2}>
                  <RiInformationLine display='inline-block' />
                </Box>
              </Tooltip>
            </FormLabel>
            <SelectMultiple
              isMulti
              isDisabled={!editMode}
              options={usersForMultiSelect}
              placeholder="Select users..."
              closeMenuOnSelect={false}
              onChange={handleUsersChange}
              defaultValue={usersByUID && usersByUID.map((uid: any) => {
                const usr = usersById[uid]
                return { 'value': uid, 'label': usr?.displayName || uid, 'colorScheme': 'blue' }
              })}
            />
            <FormHelperText>
              Registered users in the application
            </FormHelperText>
          </FormControl>
          <FormControl mt={6} isInvalid={false}>
            <FormLabel>
              Emails
              <Tooltip
                hasArrow
                label="Users can unsubscribe whenever they want"
                bg="gray.300"
                color="black"
              >
                <Box display='inline-block' height='1em' width='1em' paddingTop='3px' lineHeight='1em' ml={2}>
                  <RiInformationLine display='inline-block' />
                </Box>
              </Tooltip>
            </FormLabel>
            <CreatableSelect
              isMulti
              isDisabled={!editMode}
              placeholder="Select or write emails..."
              onChange={handleEmailsChange}
              // onInputChange={handleEmailsInputChange}
              options={[]}
              defaultValue={usersByEmail.map((email: any) => {
                return { 'value': email, 'label': email, 'colorScheme': 'blue' }
              })}
              formatCreateLabel={(val: string) => `Add ${val}`}
            />
            <FormHelperText>
              Emails do not need to be registered in the application
            </FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem colSpan={12}>
          <Box mt={5} float='right'>
            {editMode ?
              <>
                <ButtonKs.SaveChanges mr='2' onClick={updateTaskData} />
                <ButtonKs.Cancel onClick={() => setEditMode(!editMode)} />
              </>
              :
              <ButtonKs.Primary text='Edit' onClick={() => setEditMode(!editMode)} />
            }
          </Box>
        </GridItem>
      </Grid>
    </BoxShadow >
  );
}

export default Task;