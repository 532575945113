import { Box } from '@chakra-ui/react';
import NotFound from 'pages/Open/NotFound';
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';
import ProjectReport from './ProjectReport';

function Private() {
  let { path } = useRouteMatch();

  return (
    <Box flex="1" padding='50px 2em' minHeight='calc(100vh - 90px)'>
      <Switch>
        <Route exact path={[path, `${path}/ingishts/project-report/:reportProjectId`]}>
          <ProjectReport />
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </Box>
  );
}

export default Private;