import React from 'react';
import {
  Box,
  Text,
  VStack,
  Grid,
} from "@chakra-ui/react"

function index() {
  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <VStack spacing={8} style={{ marginTop: '4em' }}>
          <Text>
            Example of open url on Kitspace Hub
          </Text>
        </VStack>
      </Grid>
    </Box>
  );
}

export default index;