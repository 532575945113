
import firebase from 'firebase/app'
import 'firebase/auth';

export function useFirebaseHook() {

  const logout = () => {
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
    }).catch(function (error: any) {
      // An error happened.
    });
  }

  return {
    functions: {
      logout
    }
  }
}