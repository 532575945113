import React from 'react';
import { Grid, Box, useBoolean } from '@chakra-ui/react'
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';

function CenterBox({ fullWidth, smallWidth, noBorder, children, withHover, ...rest }: any) {

  const [flag, setFlag] = useBoolean()

  return (
    <Grid {...rest} >
      <Box
        onMouseEnter={setFlag.on} onMouseLeave={setFlag.off}
        boxShadow={withHover ? flag ? 'lg' : '' : ''}
        cursor={withHover ? 'pointer' : ''}
        justifySelf='center'
        width={smallWidth ? '70%' : fullWidth ? '100%' : '80%'}
        overflow='auto'
      >
        <BoxShadow noBorder={noBorder}>
          {children}
        </BoxShadow>
      </Box>
    </Grid>
  );
}

export default CenterBox;