import React, { useEffect, useState } from 'react';
import { Skeleton, FormControl, FormErrorMessage, Box, Alert, Select, FormLabel, Heading, Input, Text, useToast, Grid, GridItem, Divider, Icon, FormHelperText, Tooltip, AlertIcon, } from '@chakra-ui/react'
import { Controller, useForm } from "react-hook-form";
import { projectStatus, projectConfidence, projectPhases } from 'helpers/kshub';
import CenterBox from 'pages/Components/generic/CenterBox';
import { useHistory, useParams } from 'react-router-dom';
import capitalize from 'capitalize'
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from 'reactfire';
import { RiShieldStarFill, RiInformationLine } from 'react-icons/ri'
import pluralize from 'pluralize';
import { actions } from 'services/redux/actions'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { useUserHook } from 'services/hooks/useUserHook';
import { usePlaceHook } from 'services/hooks/usePlaceHook';
import AlertNoAccess from 'pages/Components/generic/Alerts/AlertNoAccess';
import DatePicker from 'pages/Components/generic/Calendar';
import SelectMultiple from 'pages/Components/generic/SelectMultiple';
import HeaderCreatedBy from 'pages/Components/generic/HeaderCreatedBy';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { toastTypes } from 'helpers/alerts';

interface Props {
  typeForm: 'create' | 'edit'
}

function ProjectsEdit({ typeForm }: Props) {

  const { register, handleSubmit, reset, control, setValue, watch, formState: { errors, isSubmitting } } = useForm();

  const { projId } = useParams<any>(); // only in case of 'edit'
  const { data: { superUserUI } } = useContextUIHook()
  const { data: { organisationsLoading, organisationsById, organisations, organisationsForSelect, organisationsForMultiSelect, organisationsAdminForSelect } } = useOrganisationHook();
  const { data: { usersForMultiSelect, usersForSelect } } = useUserHook();
  const { data: { placesForSelect } } = usePlaceHook();

  const toast = useToast()
  const user: any = useUser();
  const history = useHistory();

  const stateRedux = useSelector((store: any) => store.projectReducer);
  const dispatch = useDispatch();

  const projectOwnerOrgId = watch('orgId')
  const [projAccessWrite, setProjAccessWrite] = useState<any>([])
  const [projAccessRead, setProjAccessRead] = useState<any>([])

  const [project, setProject] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)

  const [organisationsInvolved, setOrganisationsInvolved] = useState<any>([])
  const [usersAdminAvailable, setUserAdminAvailable] = useState([]);
  const [usersFromOrgsInvolved, setUsersFromOrgsInvolved] = useState([]);

  const [configurationMode, setConfigurationMode] = useState(false)
  const [managmentMode, setManagmentMode] = useState(false)

  const onSubmit = (values: any) => {
    toast(toastTypes.processing)

    return new Promise((resolve, reject) => {
      const payload = { ...values, organisationsInvolved: organisationsInvolved };

      if (typeForm === 'create') {
        dispatch(actions.projects.createProject(payload, (status: any, info: any) => {

          if (status === true) {

            toast(toastTypes.custom({
              title: "Project created correctly!",
              description: "You will be redirected to your directory",
              status: "success",
            }))

            setTimeout(() => {
              reset();
              resolve(true)
              history.push(`/app/projects`);
            }, 2000)

          } else {
            toast(toastTypes.error)
            reject(false)
          }
        }))
      } else if (typeForm === 'edit') {
        setSaved(true)
        payload.project_access = { accessRead: projAccessRead, accessWrite: projAccessWrite }

        const checkResponse = (status: any, type: string) => {
          if (status === true) {
            toast(toastTypes.custom({
              title: type === 'config' ? "Project updated correctly!" : "Project managment updated correctly!",
              status: "success",
            }))
            history.push(`/app/projects`);
            resolve(true)
          } else {
            toast(toastTypes.error)
          }
        }

        if (configurationMode) {
          dispatch(actions.projects.updateProject(projId, payload, (status: any) => {
            checkResponse(status, 'config')
          }))
        } else {
          dispatch(actions.projects.updateProjectManagment(projId, payload, (status: any) => {
            checkResponse(status, 'managment')
          }))
        }
      }
    })
  }


  useEffect(() => {
    if (organisationsById[projectOwnerOrgId]) {
      setUserAdminAvailable(
        usersForSelect.filter((user: any) => {
          return organisationsById[projectOwnerOrgId]?.organisation_access?.adminsByUID?.includes(user.value)
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOwnerOrgId, organisationsById, project, superUserUI])

  useEffect(() => {
    setUsersFromOrgsInvolved(usersForMultiSelect.map((user: any) => {
      const trigger: Array<string> = [];
      organisations.map((orgData: any) => {
        if (organisationsInvolved.includes(orgData.id) && orgData?.organisation_access?.users?.includes(user.value)) {
          trigger.push(orgData.name)
        }
        return null
      })

      if (trigger.length > 0) return { ...user, label: `${user.label} (${trigger.join(', ')})` };
      return { ...user, isDisabled: true }
    }).filter((user: any) => {
      if (user.isDisabled) return false
      return true
    }))

    project?.projectOwnerUid && setValue('projectOwnerUid', project.projectOwnerUid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationsInvolved, project]);

  useEffect(() => {
    if (typeForm === 'edit') {
      setLoading(true)
      dispatch(actions.projects.getProjectById(projId, false, null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (
      (project && project.projectOwnerUid === user.data.uid) ||
      superUserUI ||
      typeForm === 'create'
    ) ? setConfigurationMode(true) : setConfigurationMode(false);

    (
      (project && project.project_access?.accessWrite?.includes(user.data.uid)) ||
      (project && project.project_access?.projectOwnerUid?.includes(user.data.uid)) ||
      typeForm === 'create' ||
      superUserUI
    ) ? setManagmentMode(true) : setManagmentMode(false)

  }, [superUserUI, project, user.data.uid, typeForm]);

  useEffect(() => {
    if (typeForm === 'edit' && project === null && stateRedux?.projectsById && stateRedux.projectsById[projId]) {
      const projData = stateRedux.projectsById[projId];

      setProject(projData)
      setOrganisationsInvolved(projData.organisationsInvolved)
      setProjAccessWrite(projData?.project_access?.accessWrite)
      setProjAccessRead(projData?.project_access?.accessRead)
      //projectOwnerUid

      const dateFields = ["dateProjectedCompletion", "dateTargetCompletion"]
      Object.keys(projData).map((key: string) => {
        if (dateFields.includes(key)) {
          return setValue(key, projData[key]._seconds ? new Date(projData[key]._seconds * 1000) : new Date(projData[key]))
        }
        return setValue(key, projData[key])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.projectsById]);

  useEffect(() => {
    if (loading !== stateRedux.loading) setLoading(stateRedux.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.loading]);

  const updateOrganisationInvolved = (orgsSelected: any) => {
    setOrganisationsInvolved(orgsSelected.map((orgData: any) => orgData.value))
  }

  const updateProjectWrite = (usersSelected: any) => {
    setProjAccessWrite(usersSelected.map((userOption: any) => userOption.value))
  }

  const updateProjectRead = (usersSelected: any) => {
    setProjAccessRead(usersSelected.map((userOption: any) => userOption.value))
  }


  return (
    <>
      <BreadcrumbsKs
        data={
          [
            { name: 'Projects', link: '/app/projects' },
            { name: 'Directory', link: '/app/projects' },
            { name: capitalize(typeForm), link: null }
          ]
        }
      />
      <CenterBox>
        <Heading>
          <Icon
            fontSize="2xl"
            color="current"
            mr={2}
            mb={1}
            aria-label='Only for super users'
            as={RiShieldStarFill}
          />
          {typeForm === 'create' && 'New project'}
          {typeForm === 'edit' && 'Project configuration'}
        </Heading>
        <Text>
          {typeForm === 'create' && 'Add a new project to Kitspace Hub'}
          {typeForm === 'edit' && 'Only available for super users and project owner'}
        </Text>


        {typeForm === 'edit' &&
          <HeaderCreatedBy resource={project} />
        }

        {
          organisationsAdminForSelect.length === 0 && superUserUI === false &&
          <Alert status="error" mt={6}>
            <AlertIcon />
            You do not have admin permissions in any organization, therefore you cannot create new projects
          </Alert>
        }

        {
          (project &&
            (
              project.project_access.accessRead.includes(user.data.uid) ||
              project.project_access.accessWrite.includes(user.data.uid) ||
              project.projectOwnerUid === user.data.uid
            )
          )
            || superUserUI === true
            || typeForm === 'create' ?
            <Box mt={8}>
              <Skeleton isLoaded={!loading}>
                <form onSubmit={handleSubmit(onSubmit)}>

                  <Grid templateColumns="repeat(3, 1fr)" mt={4} gap={6}>
                    <GridItem colSpan={3}>
                      <FormControl isInvalid={errors.name}>
                        <FormLabel>Project name</FormLabel>
                        <Input
                          type="text"
                          id="name"
                          isDisabled={!configurationMode}
                          {...register("name", {
                            required: "This is required",
                            minLength: { value: 4, message: "Minimum length should be 4" }
                          })}
                        />
                        <FormErrorMessage>
                          {errors.name && errors.name.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>



                  <Grid templateColumns="repeat(7, 1fr)" mt={4} gap={6}>
                    <GridItem colSpan={4}>
                      <FormControl mt={4} isInvalid={errors.orgId}>
                        <FormLabel>
                          Organization</FormLabel>
                        {organisationsLoading &&
                          <Skeleton height="38px" />
                        }
                        {!organisationsLoading &&
                          <Select
                            id="orgId"
                            isDisabled={!configurationMode}
                            placeholder="Select a Organization"
                            {...register("orgId", {
                              required: "This is required",
                            })}
                          >
                            {organisationsAdminForSelect.map((orga: any, key: number) => {
                              return (<option value={orga.value} key={key}>{orga.text}</option>)
                            })
                            }
                          </Select>
                        }
                        <FormErrorMessage>
                          {errors.orgId && errors.orgId.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={3}>
                      <FormControl mt={4} isInvalid={errors.orgId}>
                        <FormLabel>Project owner</FormLabel>
                        {organisationsLoading &&
                          <Skeleton height="38px" />
                        }
                        {!organisationsLoading &&
                          <>
                            <Select
                              id="projectOwnerUid"
                              isDisabled={!configurationMode}
                              placeholder="Select a project owner"
                              {...register("projectOwnerUid", {
                                required: "This is required",
                              })}
                            >
                              {usersAdminAvailable.map((user: any, key: number) => {
                                return (<option value={user.value} key={key}>{user.text}</option>)
                              })
                              }
                            </Select>
                          </>
                        }
                        <FormErrorMessage>
                          {errors.projectOwnerUid && errors.projectOwnerUid.message}
                        </FormErrorMessage>
                        <FormHelperText>
                          {usersAdminAvailable.length > 0 ?
                            <>
                              {usersAdminAvailable.length} {pluralize('Admin', usersAdminAvailable.length)} available in the organization selected
                            </>
                            :
                            'Please select first a organization owner'
                          }
                        </FormHelperText>
                      </FormControl>
                    </GridItem>
                  </Grid>

                  <FormControl mt={4} isInvalid={errors.orgId}>
                    <FormLabel>
                      Organisations involved
                    </FormLabel>
                    {organisationsLoading &&
                      <Skeleton height="38px" />
                    }
                    {(!organisationsLoading && (project || typeForm === 'create')) &&
                      <SelectMultiple
                        isDisabled={!configurationMode}
                        isMulti
                        defaultValue={organisationsForMultiSelect.filter((org: any) => {
                          if (org.value && project?.organisationsInvolved?.includes(org.value)) return true
                          return false
                        })}
                        options={organisationsForMultiSelect}
                        onChange={(organisationsId: any) => updateOrganisationInvolved(organisationsId)}
                        placeholder="Select organisations..."
                        closeMenuOnSelect={false}
                      />
                    }
                    <FormErrorMessage>
                      {errors.orgId && errors.orgId.message}
                    </FormErrorMessage>
                  </FormControl>



                  <FormControl mt={6} isInvalid={errors.orgId}>
                    <FormLabel>
                      Read permission
                      <Tooltip
                        hasArrow
                        label="Members with read permission can see the project in the directory and can view it"
                        bg="gray.300"
                        color="black"
                      >
                        <Box display='inline-block' height='1em' width='1em' paddingTop='3px' lineHeight='1em' ml={2}>
                          <RiInformationLine display='inline-block' />
                        </Box>
                      </Tooltip>
                    </FormLabel>
                    {organisationsLoading &&
                      <Skeleton height="38px" />
                    }
                    {(!organisationsLoading && (project || typeForm === 'create')) &&
                      <SelectMultiple
                        isMulti
                        isDisabled={!configurationMode}
                        defaultValue={usersForMultiSelect.filter((org: any) => {
                          if (org.value && project?.project_access?.accessRead?.includes(org.value)) return true
                          return false
                        })}
                        options={usersFromOrgsInvolved}
                        onChange={(userId: any) => updateProjectRead(userId)}
                        placeholder="Select organisations..."
                        closeMenuOnSelect={false}
                      />
                    }
                    <FormHelperText>
                      {usersFromOrgsInvolved.length > 0 ?
                        <> {usersFromOrgsInvolved.length} {pluralize('User', usersFromOrgsInvolved.length)} available from the organisations involved </>
                        :
                        'Please first select organisations involved'
                      }
                    </FormHelperText>
                  </FormControl>

                  <FormControl mt={6} isInvalid={errors.orgId}>
                    <FormLabel>
                      Write permission
                      <Tooltip
                        hasArrow
                        label="Members with write permission can see the project in the directory and can edit project management fields (change status, change comment, etc.)"
                        bg="gray.300"
                        color="black"
                      >
                        <Box display='inline-block' height='1em' width='1em' paddingTop='3px' lineHeight='1em' ml={2}>
                          <RiInformationLine display='inline-block' />
                        </Box>
                      </Tooltip>
                    </FormLabel>
                    {organisationsLoading &&
                      <Skeleton height="38px" />
                    }
                    {(!organisationsLoading && (project || typeForm === 'create')) &&
                      <SelectMultiple
                        isMulti
                        isDisabled={!configurationMode}
                        defaultValue={usersForMultiSelect.filter((org: any) => {
                          if (org.value && project?.project_access?.accessWrite?.includes(org.value)) return true
                          return false
                        })}
                        options={usersFromOrgsInvolved}
                        onChange={(userId: any) => updateProjectWrite(userId)}
                        placeholder="Select organisations..."
                        closeMenuOnSelect={false}
                      />
                    }
                    <FormHelperText>
                      {usersFromOrgsInvolved.length > 0 ?
                        <> {usersFromOrgsInvolved.length} {pluralize('User', usersFromOrgsInvolved.length)} available from the organisations involved </>
                        :
                        'Please first select organisations involved'
                      }
                    </FormHelperText>
                  </FormControl>

                  <Divider mt={16} mb={10} />

                  <Heading>
                    Project Managment
                  </Heading>
                  <Text>
                    Only available to users with write permissions
                  </Text>


                  <Grid templateColumns="repeat(2, 1fr)" mt={4} gap={6}>
                    <Box w="100%">
                      <FormControl>
                        <FormLabel htmlFor="dateTargetCompletion">Target completion</FormLabel>
                        {!saved &&
                          <Controller
                            control={control}
                            name='dateTargetCompletion'
                            render={({ field }) => (
                              <DatePicker
                                isDisabled={!managmentMode}
                                id="dateTargetCompletion"
                                selectedDate={field.value}
                                onChange={(date) => {
                                  field.onChange(date)
                                  setValue('dateProjectedCompletion', date)
                                }}
                                showPopperArrow={true}
                              />
                            )} />
                        }
                      </FormControl>
                    </Box>
                    <Box w="100%">
                      <FormControl>
                        <FormLabel htmlFor="dateProjectedCompletion">Projected completion</FormLabel>
                        {!saved &&
                          <Controller
                            control={control}
                            name='dateProjectedCompletion'
                            render={({ field }) => (
                              <DatePicker
                                isDisabled={!managmentMode}
                                id="dateProjectedCompletion"
                                selectedDate={field.value}
                                onChange={(date) => field.onChange(date)}
                                showPopperArrow={true}
                              />
                            )} />
                        }
                      </FormControl>
                    </Box>
                  </Grid>

                  <FormControl mt={4} isInvalid={errors.placeId}>
                    <FormLabel>Place</FormLabel>
                    <Select
                      id="placeId"
                      isDisabled={!managmentMode}
                      placeholder="Select a Place"
                      {...register("placeId", {
                        // required: "This is required",
                      })}
                    >
                      {placesForSelect.map((place: any, key: number) => {
                        return (<option value={place.value} key={key}>{place.text}</option>)
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.placeId && errors.placeId.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={4} isInvalid={errors.channelId}>
                    <FormLabel>Channel</FormLabel>
                    <Select
                      id="channelId"
                      isDisabled={!managmentMode}
                      placeholder="Select a Channel"
                      {...register("channelId", {
                        // required: "This is required"
                      })}
                    >
                      {
                        organisationsForSelect.filter((orgData) => {
                          if (projectOwnerOrgId && organisationsById[projectOwnerOrgId]?.organisation_access?.organisationsPartners) {
                            if (organisationsById[projectOwnerOrgId]?.organisation_access?.organisationsPartners.includes(orgData.value)) return true
                            return false
                          } else {
                            return false
                          }
                        }).map((orgData) => {
                          return (
                            <option value={orgData.value}>{orgData.text}</option>
                          )
                        })
                      }
                    </Select>
                    <FormHelperText>
                      {projectOwnerOrgId &&
                        <>Partner organizations of the selected organization owner</>
                      }
                      {!projectOwnerOrgId &&
                        <>Please select organization and project owner first</>
                      }
                    </FormHelperText>
                    <FormErrorMessage>
                      {errors.channelId && errors.channelId.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={4} isInvalid={errors.currentStatus}>
                    <FormLabel>Initial status</FormLabel>
                    <Select
                      id="currentStatus"
                      isDisabled={!managmentMode}
                      placeholder="Select a Status"
                      {...register("currentStatus", {
                        required: "This is required"
                      })}
                    >
                      {Object.keys(projectStatus).map((key: string, index: number) => {
                        return (
                          <option value={projectStatus[key].value} key={index}>
                            {projectStatus[key].name}
                          </option>
                        )
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.currentStatus && errors.currentStatus.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={4} isInvalid={errors.currentPhase}>
                    <FormLabel>Current Phase</FormLabel>
                    <Select
                      id="currentPhase"
                      isDisabled={!managmentMode}
                      placeholder="Select a Phase"
                      {...register("currentPhase", {
                        required: "This is required"
                      })}
                    >
                      {Object.keys(projectPhases).map((key: string, index: number) => {
                        return (
                          <option value={projectPhases[key].value} key={index}>
                            {projectPhases[key].name}
                          </option>
                        )
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.currentPhase && errors.currentPhase.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={4} isInvalid={errors.confidence}>
                    <FormLabel>Confidence</FormLabel>
                    <Select
                      id="confidence"
                      isDisabled={!managmentMode}
                      placeholder="Select a confidence level"
                      {...register("confidence", {
                        required: "This is required"
                      })}
                      defaultValue='normal'
                    >
                      {Object.keys(projectConfidence).map((key: string, index: number) => {
                        return (
                          <option value={projectConfidence[key].value} key={index}>
                            {projectConfidence[key].name}
                          </option>
                        )
                      })}
                    </Select>
                    <FormErrorMessage>
                      {errors.confidence && errors.confidence.message}
                    </FormErrorMessage>
                  </FormControl>


                  <Divider mt={7} mb={5} />

                  <ButtonKs.Success
                    text={typeForm === 'create' ? 'Create Project' : 'Save Changes'}
                    isLoading={isSubmitting}
                    isDisabled={!managmentMode && !configurationMode}
                    type="submit"
                    mt={10} />

                  <ButtonKs.Cancel
                    isDisabled={isSubmitting}
                    ml={4}
                    mt={10}
                    to='/app/projects'
                  />
                </form>
              </Skeleton>
            </Box> :
            <AlertNoAccess />
        }
      </CenterBox>
    </>
  );
}

export default ProjectsEdit;