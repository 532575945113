import React, { useState } from 'react';
import { Heading, Divider, Text } from '@chakra-ui/react';
import { ApiKs } from 'services/api/API';
import RequestDemo from './RequestDemo';
import { useUser } from 'reactfire';

function ApiDebugger() {

  const user: any = useUser()
  const { apiPublic, apiPrivate } = ApiKs();
  const [token, setToken]: any = useState('')

  return (
    <div>
      <Heading as="h1" size="xl">
        Private
      </Heading>
      <Divider />
      <Text>
        User : {user.data.displayName}
      </Text>
      <Text>
        UID : {user.data.uid}
      </Text>
      <Divider mb='2em' />
      <b> Don't share this information: </b>
      <Text mb='2em'>
        Current JWT Firebase: {token}
      </Text>
      <Divider mt='2em' mb='2em' />
      <RequestDemo
        setToken={setToken}
        endpoint={apiPrivate.firebase.me}
        description={'/firebase/me'}
      />
      <Divider mt='1em' />
      <Heading as="h1" size="xl">
        Public
      </Heading>
      <RequestDemo
        endpoint={apiPublic.get.test}
        description={'/test'}
      />
      <Divider mt='1em' />
      <RequestDemo
        endpoint={apiPublic.get.firebase.test}
        description={'/firebase/test'}
      />
    </div>
  );
}

export default ApiDebugger;