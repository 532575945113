import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Heading, Box } from '@chakra-ui/layout';
import { ButtonGroup, Editable, EditableInput, EditablePreview, Flex, IconButton, useEditableControls, useToast } from '@chakra-ui/react';
import { toastTypes } from 'helpers/alerts';
import LoadingGeneric from 'pages/Components/generic/Loading/LoadingGeneric';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'services/redux/actions';

function Overview({ projData, projId, loading }: any) {

  const [loadingOverview, setLoadingOverview] = useState(false)
  const dispatch = useDispatch()
  const toast = useToast();

  const updateOverview = (overviewText: string) => {
    setLoadingOverview(true)
    toast(toastTypes.processing)
    dispatch(actions.projects.updateProjectOverview(projId, { overviewText: overviewText }, () => {
      setLoadingOverview(false)
      toast(toastTypes.saved)
    }))
  }

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <Flex justifyContent="center">
        <ButtonGroup size="sm" mt={6}>
          <IconButton boxShadow="md" borderRadius='10px' icon={<CheckIcon />} {...getSubmitButtonProps()} />
          <IconButton boxShadow="md" borderRadius='10px' icon={<CloseIcon />} {...getCancelButtonProps()} />
        </ButtonGroup>
      </Flex>
    ) : (
      <Flex justifyContent="center">
        <IconButton boxShadow="md" borderRadius='10px' size="sm" mt={6} icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    )
  }

  return (
    <Box>
      <Heading as="h3" size="lg" mb={6}>Project Overview:</Heading>
      {!loading && !loadingOverview && (projData.overviewText || projData.overviewText === '') ?
        <Editable
          textAlign="left"
          defaultValue={projData.overviewText}
          placeholder="Describe the general project, this overview will be stored in the activity history."
          fontSize="md"
          isPreviewFocusable={false}
          onSubmit={updateOverview}
        >
          <EditablePreview />
          <EditableInput />
          <EditableControls />
        </Editable>
        :
        <LoadingGeneric boxes={1} />
      }
    </Box>
  );
}

export default Overview;
