import React, { useState, useEffect } from 'react';
import { Text, Badge, Box, MenuGroup, MenuItem, Popover, IconButton, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverTrigger, } from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon, } from '@chakra-ui/icons'
import { RiArchiveLine, RiPlayMiniFill } from 'react-icons/ri'
import { Link, useRouteMatch } from "react-router-dom";
import { useTable, useSortBy } from 'react-table'
import { countryByName } from 'helpers/countries';
import { typeOrgById } from 'helpers/kshub';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions'
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { useUser } from 'reactfire';
import { Table, Thead, Tbody, Tr, Th, Td } from "pages/Components/generic/ResponsiveTable/table";
import HeaderKs from 'pages/Components/generic/HeaderKs';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs, colorByIndex } from 'pages/Components/generic/Buttons';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import 'pages/Components/styles/responsive_tables.css'

function OrganisationsDirectory(props: any) {

  let { path } = useRouteMatch();
  const { data: { superUserUI } } = useContextUIHook()
  const user: any = useUser();


  const stateRedux = useSelector((store: any) => store.organisationReducer);
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const updateData = (cache: boolean = true) => {
    dispatch(actions.organisations.getOrganisations(cache))
  }

  useEffect(() => {
    if (stateRedux.organisations.length < 1) updateData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(stateRedux.loading)
  }, [stateRedux.loading]);

  const refreshTable = () => {
    if (stateRedux.organisationsById && Object.keys(stateRedux.organisationsById).length !== 0) {
      const tmp = Object.keys(stateRedux.organisationsById).map((index: any) => stateRedux.organisationsById[index])
      if (superUserUI === false) {
        setDataTable(tmp.filter((organisation: any) =>
          organisation.state === 'active' ||
          (organisation.state === 'draft' && organisation.createdBy === user.data.uid))
        )
      } else {
        setDataTable(tmp)
      }
    }
  }

  useEffect(() => {
    refreshTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superUserUI]);

  useEffect(() => {
    refreshTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.organisationsById])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Abbreviation',
        accessor: 'abbreviation',
        Cell: (props: any) => {
          const abrv = props.cell.value;
          return (
            <Text fontSize="xl" fontWeight="bold">
              <Badge ml="1" fontSize="0.8em">
                {abrv}
              </Badge>
            </Text>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (props: any) => {
          const name = props.cell.value;
          return (
            <Text>
              {name}
              {props.row.original.state === 'draft' && superUserUI === false &&
                <Badge colorScheme="orange" m={2}> Draft </Badge>
              }
            </Text>
          );
        },
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Country',
        accessor: 'country',
        Cell: (props: any) => {
          const country = props.cell.value;
          const data = countryByName(country);

          return (
            <>
              {data.name} {data.emoji}
            </>
          )
        },
      },
      {
        Header: 'Type Org',
        accessor: 'typeOrg',
        Cell: (props: any) => {
          const typeOrg = props.cell.value;
          const type = typeOrgById(typeOrg)

          return (
            <>
              {type.name}
            </>
          )
        },
      },
    ],
    [superUserUI]
  )

  const initialStateTable: any = {
    columns: columns,
    data: dataTable
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  return (
    <Box>
      <BreadcrumbsKs
        data={
          [
            { name: 'Organizations', link: 'organisations' },
            { name: 'Directory', link: 'organisations' }
          ]
        }
      />
      <HeaderKs
        title='Organization Directory'
        subtitle='Organizations we collaborate with and draft organizations you own'
      >
        <ButtonKs.Create
          to={`${path}/new`}
          ml={2}
        />
        <ButtonKs.Refresh
          onClick={() => updateData(false)}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      {
        !loading &&
        <Box overflow='auto'>
          <Table variant="striped" {...getTableProps()} mt={4}>
            <Thead>
              {headerGroups.map((headerGroup: any) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <Th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span style={{ marginLeft: '5px' }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownIcon />
                            : <ArrowUpIcon />
                          : ''}
                      </span>
                    </Th>
                  ))}
                  {
                    superUserUI &&
                    <Th>
                      State
                    </Th>
                  }
                  <Th>
                    Options
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {
                rows.map((row: any, index: number) => {
                  prepareRow(row)
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <Td {...cell.getCellProps()} headerTitle={cell.column.Header}>
                            {cell.render('Cell')}
                          </Td>
                        )
                      })}
                      {
                        superUserUI &&
                        <Td headerTitle='State'>
                          {row.original.state === 'draft' &&
                            <Badge colorScheme="orange" m={2}> Draft </Badge>
                          }
                          {row.original.state === 'active' &&
                            <Badge colorScheme="green" m={2}> Active </Badge>
                          }
                          {row.original.state === 'archived' &&
                            <Badge colorScheme="red" m={2}> Archived </Badge>
                          }

                        </Td>
                      }
                      <Td headerTitle='Options'>
                        <ButtonKs.OptionsMenu m='1' bgColor={index % 2 ? 'gray.100' : 'white'}>

                          {superUserUI === true &&
                            <MenuGroup title='State'>
                              {row.original.state === 'draft' &&
                                <MenuItem onClick={() => dispatch(actions.organisations.updateState(row.original.orgId, 'active'))} > Approve draft </MenuItem>
                              }
                              {row.original.state === 'active' &&
                                <>
                                  <MenuItem onClick={() => dispatch(actions.organisations.updateState(row.original.orgId, 'draft'))}> Move to Draft </MenuItem>
                                </>
                              }
                              {
                                row.original.state === 'archived' &&
                                <>
                                  <MenuItem onClick={() => dispatch(actions.organisations.updateState(row.original.orgId, 'draft'))}> Move to Draft </MenuItem>
                                </>
                              }
                            </MenuGroup>
                          }

                          <MenuGroup title="Options">
                            {(superUserUI === true || user.data.uid === row.original.createdBy) &&
                              <>
                                <MenuItem as={Link} to={`${path}/${row.original.orgId || row.original.orgId}/edit`}> Edit Information </MenuItem>
                              </>
                            }
                            <MenuItem as={Link} to={`${path}/${row.original.orgId || row.original.orgId}/people`}> People </MenuItem>
                          </MenuGroup>
                        </ButtonKs.OptionsMenu>

                        {superUserUI === true &&
                          <Popover>
                            <PopoverTrigger>
                              <IconButton
                                m={1}
                                boxShadow='md'
                                bgColor={colorByIndex(index)}
                                aria-label="Button with Icon"
                                icon={row.original.state === 'active' ? <RiArchiveLine color='black' /> : <RiPlayMiniFill color='black' />}
                                borderRadius='10px'
                                isDisabled={row.original.state === 'draft'}
                              />
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody pt={4}>
                                <Text fontWeight='bold'>
                                  {row.original.state === 'archived' && 'Activate'}
                                  {row.original.state === 'active' && 'Archive'}
                                  &nbsp; organization
                                </Text>
                                <br />
                                Are you sure you want to continue?
                              </PopoverBody>
                              <PopoverFooter d="flex" justifyContent="flex-end">
                                {row.original.state === 'active' &&
                                  <ButtonKs.Danger
                                    onClick={() => dispatch(actions.organisations.updateState(row.original.orgId, 'archived'))}
                                    size='xs'
                                    text='Archive'
                                  />
                                }
                                {row.original.state === 'archived' &&
                                  <ButtonKs.Primary
                                    onClick={() => dispatch(actions.organisations.updateState(row.original.orgId, 'active'))}
                                    size='xs'
                                    text='Activate'
                                  />
                                }
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        }
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>
      }
      {
        loading &&
        <LoadingTable columns={7} />
      }
    </Box >
  );
}

export default OrganisationsDirectory;