import { Box, Heading, Spacer, Grid, GridItem } from '@chakra-ui/layout';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import Task from './Task'
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions';
import { Skeleton } from '@chakra-ui/skeleton';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { useHistory } from 'react-router';

function ScheduledTasks() {

  const stateRedux = useSelector((store: any) => store.scheduledtasksReducer);
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.scheduledtasks.getScheduledTasks())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderKs
        title='Scheduled Tasks'
        subtitle='This feature is available only for Super Users'
      >
        <ButtonKs.IconInfo onClick={() => history.push('scheduled/logs')} />
        <ButtonKs.Refresh
          onClick={() => dispatch(actions.scheduledtasks.getScheduledTasks())}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      <Spacer />
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
        gap={6}
      >
        <GridItem colSpan={4}>
          <BoxShadow width='100%'>
            <Heading size="m">
              Project Reports
            </Heading>
            <Grid
              templateColumns="repeat(8, 1fr)"
              gap={6}
              pb={10}
              pt={4}
            >
              {stateRedux.loading &&
                <>
                  <GridItem colSpan={4}>
                    <Skeleton height="400px" />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Skeleton height="400px" />
                  </GridItem>
                </>
              }
              {
                !stateRedux.loading && Object.keys(stateRedux.scheduledtasksById).map((id: any, key: number) => {
                  if (stateRedux.scheduledtasksById[id]) {
                    return (
                      <GridItem colSpan={4} key={key}>
                        <Task taskData={stateRedux.scheduledtasksById[id]} />
                      </GridItem>
                    )
                  }
                  return <Box key={key}></Box>
                })
              }
            </Grid>
          </BoxShadow>
        </GridItem>
      </Grid>
    </Box >
  );
}

export default ScheduledTasks;