import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions'
// import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';

export function useInsightsHook() {

  const stateInsights = useSelector((store: any) => store.insightsReducer);
  const dispatch = useDispatch();
  const [insightsLoading, setInsightsLoading] = useState(true)

  // const { data: { superUserUI } } = useContextUIHook()

  const updateProjectsReports = () => {
    setInsightsLoading(true)
    dispatch(actions.insights.getProjectsReports(() => {
      setInsightsLoading(false)
    }))
  }

  useEffect(() => {
    updateProjectsReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fun: {
      updateProjectsReports,
    },
    data: {
      insightsLoading: insightsLoading,
      projectReportsById: stateInsights.projectReportsById,
    },
  }
}