// ARRAY COLLECTION

export const patchEntitiesCollection = (entityId: string, entityPayload: any, collection: Array<any>) => {
  const projectsPatched = collection.map((entity: any) => {
    if (entity.id !== entityId) return entity
    return { ...entity, ...entityPayload }
  })
  return projectsPatched
}

export const patchNewEntityCollection = (entityId: string, entityPayload: any, collection: Array<any>) => {
  const projectsPatched = collection
  projectsPatched.push({ ...entityPayload, id: entityId, })
  return projectsPatched
}

// OBJECT COLLECTION

export const patchNewEntityCollectionObject = (entityId: string, entityPayload: any, collection: any) => {
  const projectsPatched = { ...collection }
  projectsPatched[entityId] = { ...entityPayload, id: entityId, }
  return projectsPatched
}

export const patchEntitiesCollectionObject = (entityId: string, entityPayload: any, collection: any) => {
  const projectsPatched = { ...collection }
  projectsPatched[entityId] = { ...entityPayload, id: entityId, }
  return projectsPatched
}

export const removeEntityCollectionObject = (entityId: string, collection: Array<any>) => {
  const projectsPatched: any = { ...collection }
  delete projectsPatched[entityId];
  return projectsPatched
}

