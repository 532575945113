import {
  LOADING, UPDATE_ALL, UPDATE_BY_ID,
  LOADING_COLLECT, UPDATE_ALL_COLLECT, UPDATE_BY_ID_COLLECT,
  LOADING_DOCUMENTS, UPDATE_ALL_DOCUMENTS_BY_PROJ,
} from 'services/redux/project/project.type'

const initialState = {
  projects: [],
  projectsById: {},
  updatedAt: new Date(),
  loading: false,

  projectsGroups: [],
  projectsGroupsById: {},
  loadingProjectsGroups: false,

  documentsByProject: {},
  documentsById: {},
  loadingDocuments: false,
}

// type stateType = keyof typeof initialState;

export const projectReducer = (state = initialState, action: any) => {
  switch (action.type) {

    // DOCUMENTS

    case LOADING_DOCUMENTS: return {
      ...state,
      loadingDocuments: true,
    }

    case UPDATE_ALL_DOCUMENTS_BY_PROJ: return {
      ...state,
      loadingDocuments: false,
      documentsByProject: {
        ...state.documentsByProject,
        [action.projectId]: action.payload
      }
    }

    // PROJECTS GROUPS

    case UPDATE_ALL_COLLECT:
      return {
        ...state,
        loadingProjectsGroups: false,
        projectsGroups: action.payload.data,
        projectsGroupsById: action.payload.projectsGroupsById,
        updatedAt: new Date(),
      };

    case UPDATE_BY_ID_COLLECT:
      return {
        ...state,
        loadingProjectsGroups: false,
        projectsGroups: [...action.projectsGroups],
        projectsGroupsById: {
          ...state.projectsGroupsById,
          [action.id]: action.payload
        }
      }

    case LOADING_COLLECT:
      return {
        ...state,
        loadingProjectsGroups: true
      }


    // PROJECTS

    case UPDATE_ALL:
      return {
        ...state,
        projects: action.payload.data,
        projectsById: action.payload.projectsById,
        updatedAt: new Date(),
        loading: false,
      };

    case UPDATE_BY_ID:
      return {
        ...state,
        loading: false,
        projects: [...action.projects],
        projectsById: {
          ...state.projectsById,
          [action.id]: action.payload
        }
      }

    case LOADING:
      return {
        ...state,
        loading: true
      }

    default:
      return state;
  }
};
