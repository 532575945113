import React from 'react';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire'
import { firebaseConfig } from 'config/firebase.config'

function Firebase(props: any) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SuspenseWithPerf
        fallback={<p>Loading firebase...</p>}
        traceId="kshub-workspace-main"
      >
          {props.children}
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  );
}

export default Firebase;