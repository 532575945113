import { Tooltip, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

interface Props {
  seconds: number;
  format?: 'normal' | 'fromnow' | 'complete';
  withoutP?: boolean;
  onlyText?: boolean;
}

function DateBadge({ seconds, format, withoutP, onlyText }: Props) {
  const dateFormatted_0 = moment.utc(seconds * 1000).format("MMM DD, YYYY | hh:mm:ss")
  const dateFormatted_1 = moment.utc(seconds * 1000).format("MMM DD, YYYY")
  const dateFormatted_2 = moment.utc(seconds * 1000).fromNow()

  const typeFormat = () => {
    switch (format) {
      case 'normal':
        return dateFormatted_1
      case 'fromnow':
        return dateFormatted_2
      case 'complete':
        return `${dateFormatted_0} - ${dateFormatted_2}`
      default:
        return dateFormatted_1
    }
  }

  if (onlyText) {
    return (
      <Text marginTop='0 !important'>
        {!withoutP ?
          `(${typeFormat()})` :
          typeFormat()
        }
      </Text>
    )
  }

  return (
    <>
      <Tooltip label={dateFormatted_0}>
        <Text cursor='pointer'>
          {!withoutP ?
            `(${typeFormat()})` :
            typeFormat()
          }
        </Text>
      </Tooltip>
    </>
  );
}

export default DateBadge;