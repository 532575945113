import React, { useEffect, useState } from 'react';
import { RiArrowUpLine, } from 'react-icons/ri';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Icon, useColorModeValue, Text, Heading, useColorMode, Avatar, Badge, Tooltip, Box, Divider, useToast } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa';
import { useUserHook } from 'services/hooks/useUserHook';
import DateBadge from 'pages/Components/generic/Time/DateBadge';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { getFileIcon } from 'helpers/files';
import { sortObjectByVersion } from 'helpers/mini';
import { toastTypes } from 'helpers/alerts';
import { actions } from 'services/redux/actions';
import { useDispatch } from 'react-redux';
import LoadingGeneric from 'pages/Components/generic/Loading/LoadingGeneric';
import EditableSimple from 'pages/Components/generic/Editable/EditableSimple';

interface Props {
  projId: string,
  document: any,
  downloadDocumentWithVersion: (version: number | null) => Promise<void>,
  onClickPlusButton: () => void
}

function Timeline({ projId, document, downloadDocumentWithVersion, onClickPlusButton }: Props) {

  const { functions: { getNameByUID } } = useUserHook()
  const colorBackground = useColorModeValue('white', '#2C5282')
  const colorTitles = useColorModeValue('black', 'white')

  const colorIcon = useColorModeValue('white', 'white')
  const colorBackgroundIcon = useColorModeValue('#2185D0', '#2C5282')

  const [colorLine, setColorLine] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const color = useColorMode()
  const dispatch = useDispatch()
  const toast = useToast()

  useEffect(() => {
    color.colorMode === 'light' ? setColorLine('#2185D0') : setColorLine('#2C5282')
  }, [color])

  const archiveDocumentVersion = (versionId: string) => {
    setLoading(true)
    toast(toastTypes.processing)
    dispatch(actions.projects.archiveDocumentVersion(projId, document.id, versionId, () => {
      toast(toastTypes.archived)
      setLoading(false)
    }))
  }

  return (
    <>
      {
        loading ?
          <>
            <LoadingGeneric />
          </>
          :
          <>
            <VerticalTimeline lineColor={colorLine}>
              <Tooltip label='test'>
                <VerticalTimelineElement
                  iconOnClick={onClickPlusButton}
                  iconStyle={{ background: colorBackgroundIcon, color: colorIcon, cursor: 'pointer' }}
                  icon={<Icon as={FaPlus} />}
                  style={{ marginBottom: '150px' }}
                />
              </Tooltip>
              {
                document?.versions && document?.versions.length && document?.versions.sort(sortObjectByVersion).map((version: any) => {
                  return (
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{ background: colorBackground, color: colorBackground }}
                      contentArrowStyle={{ borderRight: `7px solid ${colorBackground}` }}
                      date={<DateBadge seconds={version?.createdAt?._seconds} withoutP onlyText />}
                      iconStyle={{ background: colorBackgroundIcon, color: colorIcon }}
                      icon={<Icon as={getFileIcon(version.fileType)} />}
                    >
                      <Heading color={`${colorTitles} !important`}>
                        <Avatar
                          size="xs"
                          name={getNameByUID(version.createdBy) || '?'}
                          mr={2}
                        />{getNameByUID(version.createdBy)}
                      </Heading>
                      <Text as={version.archived ? 'del' : ''} color={`${colorTitles} !important`} fontSize='xs' fontWeight='100 !important' cursor='pointer'>
                        File name: {version.fileName ?
                          version.fileName :
                          <> Undefined </>
                        }
                      </Text>
                      <Divider mt={2} />
                      <Text color={`${colorTitles} !important`} fontSize='xs' fontWeight='100 !important' cursor='pointer'>
                        <EditableSimple defaultValue=
                          {version.description ?
                            version.description :
                            'Add a description...'
                          }
                          callback={(text: string) => {
                            toast(toastTypes.processing)
                            dispatch(actions.projects.updateDocumentVersion(projId, document.id, version.id, { description: text }, (status: boolean) => {
                              toast(status ? toastTypes.updated : toastTypes.error)
                            }))
                          }} />
                      </Text>
                      <Box mt={4}>
                        <Badge float='right' mt={1}>
                          Version {version.version}
                        </Badge>
                        {
                          document.lastVersion === version.version &&
                          <Badge float='right' mt={1} mr={1} colorScheme='green'>
                            Last Version
                          </Badge>
                        }
                        {
                          version.archived &&
                          <Badge float='right' mt={1} mr={1} colorScheme='red'>
                            Archived
                          </Badge>
                        }
                        {version.version !== 1 &&
                          < ButtonKs.Danger size='xs' fontSize='xs' float='left' mr={1}
                            isDisabled={version.archived === true}
                            onClick={() => {
                              archiveDocumentVersion(version.id)
                            }}>
                            Archive
                          </ButtonKs.Danger>
                        }
                        <ButtonKs.Success size='xs' float='left' onClick={() => downloadDocumentWithVersion(+version.version)}>
                          Download
                        </ButtonKs.Success>
                      </Box>
                    </VerticalTimelineElement>
                  )
                })
              }
              <VerticalTimelineElement
                iconStyle={{ background: colorBackgroundIcon, color: colorIcon }}
                icon={<Icon as={RiArrowUpLine} />}
              />
            </VerticalTimeline >
          </>
      }
    </>
  );
}

export default Timeline;
