import { LOADING, UPDATE_ALL, UPDATE_BY_ID } from 'services/redux/insights/insights.type'

const initialState = {
  projectReportsById: {},
  loading: false,
  updatedAt: new Date(),
}

// type stateType = keyof typeof initialState;

export const insightsReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case UPDATE_ALL:
      return {
        ...state,
        projectReportsById: action.payload.projectReportsById,
        updatedAt: new Date(),
        loading: false,
      };

    case UPDATE_BY_ID:
      return {
        ...state,
        loading: false,
        projectReportsById: {
          ...state.projectReportsById,
          [action.id]: action.payload
        }
      }

    case LOADING:
      return {
        ...state,
        loading: true
      }

    default:
      return state;
  }
};
