import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from '../Open/NotFound'
import Settings from './Settings'
import TopMenu from '../Components/layout/TopMenu';
import Debugger from './SuperUser/Debugger';
import SuperUserHome from './SuperUser/General';
import OrganisationsEdit from './Organisations/Edit'
import { useContextAppHook } from 'services/contexts/hooks/useContextAppHook';
import AppContext from 'services/contexts/ContextApp'
import { Box, Grid, Spinner, VStack, Text, GridItem, } from '@chakra-ui/react'
import OrganisationsHome from './Organisations/Directory';
import OrganisationsPeople from './Organisations/People';
import OrganisationsAddPeople from './Organisations/AddPeople';
import ProjectsDirectory from './Projects/Directory';
import ProjectsEdit from './Projects/Edit';
import ProjectsGroupDirectory from './ProjectsGroups/Directory';
import ProjectsGroupEdit from './ProjectsGroups/Edit';
import PlacesDirectory from './Places/Directory';
import PlacesEdit from './Places/Edit';
import Sites from './Places/Sites';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions';
import ScheduledTasks from './SuperUser/ScheduledTasks';
import LogsGeneral from './SuperUser/ScheduledTasks/LogsGeneral/Logs';
import InsightsReports from './Insights/Reports';
import ProjectHome from './Projects/Home/Home';
import Document from './Projects/Document/Document';
import Home from './Home';

function Main() {

  const [loading, setLoading] = useState(true)
  const cttApp = useContext(AppContext);
  const { functions: { initContext } } = useContextAppHook()
  const stateRedux = useSelector((store: any) => store.userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stateRedux.loading === false) {
      setLoading(cttApp.loading)
    }
  }, [cttApp, stateRedux])

  useEffect(() => {
    initContext();
    dispatch(actions.users.getAllUsers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let { path } = useRouteMatch();

  return (
    <>
      {loading &&
        <Grid minH="100vh" p={3}>
          <VStack spacing={8}>
            <Box margin='auto'>
              <Grid
                templateColumns="repeat(1, 1fr)"
                gap={1}
              >
                <GridItem colSpan={1} textAlign="center">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <Text>
                    Loading Kitspace Hub
                  </Text>
                </GridItem>
              </Grid>
            </Box>
          </VStack>
        </Grid>
      }
      {!loading &&
        <>
          <TopMenu />
          <Box flex="1" padding='100px 2em' minHeight='calc(100vh - 90px)'>
            <Switch>
              <Route exact path={[path, `${path}/home`]}>
                <Home />
              </Route>
              <Route exact path={[`${path}/settings`]}>
                <Settings />
              </Route>
              <Route path={[`${path}/organisations`]}>
                <SubPathOrg />
              </Route>
              <Route path={[`${path}/projects`]}>
                <SubPathProjects />
              </Route>
              <Route path={[`${path}/places`]}>
                <SubPathPlaces />
              </Route>
              <Route path={[`${path}/projects-groups`]}>
                <SubPathProjectsGroups />
              </Route>
              <Route path={[`${path}/superuser`]}>
                <SubPathSuperUser />
              </Route>
              <Route path={[`${path}/insights/reports`]}>
                <SubPathInsightsReports />
              </Route>
              <Route path="*" component={NotFound} />
            </Switch>
          </Box>
        </>
      }
    </>
  );
}

function SubPathInsightsReports() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <InsightsReports />
      </Route>
    </Switch>
  )
}

function SubPathSuperUser() {

  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <SuperUserHome />
      </Route>
      <Route path={[`${path}/debugger`]}>
        <Debugger />
      </Route>
      <Route exact path={[`${path}/scheduled`]}>
        <ScheduledTasks />
      </Route>
      <Route exact path={[`${path}/scheduled/logs`]}>
        <LogsGeneral />
      </Route>
    </Switch>
  )
}

function SubPathOrg() {

  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <OrganisationsHome />
      </Route>
      <Route exact path={[`${path}/:orgId/people`]}>
        <OrganisationsPeople />
      </Route>
      <Route exact path={[`${path}/:orgId/people/edit`]}>
        <OrganisationsAddPeople />
      </Route>
      <Route exact path={[`${path}/:orgId/edit`]}>
        <OrganisationsEdit typeForm='edit' />
      </Route>
      <Route path={[`${path}/new`]}>
        <OrganisationsEdit typeForm='create' />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

function SubPathProjects() {

  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <ProjectsDirectory />
      </Route>
      <Route exact path={[`${path}/:projId/edit`]}>
        <ProjectsEdit typeForm='edit' />
      </Route>
      <Route exact path={[`${path}/:projId/home`]}>
        <ProjectHome />
      </Route>
      <Route exact path={[`${path}/:projId/document/:documentId`]}>
        <Document />
      </Route>
      <Route path={[`${path}/new`]}>
        <ProjectsEdit typeForm='create' />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

function SubPathProjectsGroups() {

  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <ProjectsGroupDirectory />
      </Route>
      <Route exact path={[`${path}/:projectGroupId/edit`]}>
        <ProjectsGroupEdit typeForm='edit' />
      </Route>
      <Route path={[`${path}/new`]}>
        <ProjectsGroupEdit typeForm='create' />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

function SubPathPlaces() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <PlacesDirectory />
      </Route>
      <Route exact path={[`${path}/:placeId/edit`]}>
        <PlacesEdit typeForm='edit' />
      </Route>
      <Route path={[`${path}/new`]}>
        <PlacesEdit typeForm='create' />
      </Route>
      <Route exact path={[`${path}/:placeId/sites`]}>
        <Sites />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Main;