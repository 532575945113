import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Heading, Box, Table, Tbody, Td, Th, Thead, Tr, Text, Flex, Spacer, Alert, AlertIcon, MenuGroup, MenuItem, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { actions } from 'services/redux/actions';
import { AiFillBug } from 'react-icons/ai'
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import CenterBox from 'pages/Components/generic/CenterBox';
import BadgesAccess from 'pages/Components/generic/Badges/BadgesAccess';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import { toastTypes } from 'helpers/alerts';

function OrganisationsPeople() {

  const [loading, setLoading] = useState(true)
  const { orgId } = useParams<any>(); // only in case of 'edit'
  const [organisation, setOrganisation] = useState<any>({})
  const [currentMembers, setCurrentMembers] = useState<any>([])
  const { data: { superUserUI, user } } = useContextUIHook()
  const stateRedux = useSelector((store: any) => store.organisationReducer);
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    dispatch(actions.organisations.getOrganisationById(orgId, true, null))
    dispatch(actions.organisations.getMembersByOrg(orgId, true, null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateRedux.organisationsById[orgId]) {
      const orgData = stateRedux.organisationsById[orgId];
      setOrganisation(orgData)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.organisationsById]);

  useEffect(() => {
    setLoading(stateRedux.loading)
  }, [stateRedux.loading]);

  useEffect(() => {
    if (stateRedux.membersByOrg[orgId]) {
      setCurrentMembers(stateRedux.membersByOrg[orgId])
      console.info(stateRedux.membersByOrg[orgId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.membersByOrg]);

  const removeByUid = (uid: string) => {
    dispatch(actions.organisations.removeMembers(orgId, { removeUsersByUID: [uid], removeUsersByEmail: [] }, () => {
      toast(toastTypes.removed)
    }))
  }

  const switchAdminAccess = (status: boolean, uid: string) => {
    dispatch(actions.organisations.switchAdminAccess(orgId, status, uid, () => {
      toast(toastTypes.updated)
    }))
  }

  const removeByEmail = (email: string) => {
    dispatch(actions.organisations.removeMembers(orgId, { removeUsersByUID: [], removeUsersByEmail: [email] }, () => {
      toast(toastTypes.custom({
        title: `User removed correctly`,
        status: 'info',
      }))
    }))
  }

  return (
    <>
      {organisation.name &&
        <BreadcrumbsKs
          data={
            [
              { name: 'Organizations', link: '/app/organisations' },
              { name: 'Directory', link: '/app/organisations' },
              { name: organisation.name, link: `/app/organisations/${orgId}/edit` },
              { name: 'People', link: null }
            ]
          }
        />
      }
      <CenterBox>
        <Heading>
          People directory
        </Heading>
        <Text>
          {organisation.name && 'People in '} {organisation.name}
        </Text>

        <Flex mt={6}>
          {/* <Box p="4">
                <Text>
                  {currentMembers.users &&
                    <>
                      {currentMembers.users && currentMembers.users.length} &nbsp;
                      {pluralize('User', currentMembers.users ? currentMembers.users.length : 0)}
                    </>
                  }
                  &nbsp; | &nbsp;
                  {
                    currentMembers.usersInvitedByEmail &&
                    <>
                      {currentMembers.usersInvitedByEmail && currentMembers.usersInvitedByEmail.length} &nbsp;
                      {pluralize('Invitation', currentMembers.usersInvitedByEmail ? currentMembers.usersInvitedByEmail.length : 0)}
                    </>
                  }
                </Text>
              </Box> */}
          <Box p="4">
            {
              (superUserUI === true || user.data.uid === currentMembers.createdBy) && organisation.state === 'draft' &&
              <Alert status="warning">
                <AlertIcon />
                You can't add people to an organization in draft
              </Alert>
            }

          </Box>
          <Spacer />
          <Box p="4">
            {
              (superUserUI === true || user.data.uid === currentMembers.createdBy) && organisation.state === 'active' &&
              <ButtonKs.Primary as={Link} to='people/edit' mr={2}>
                Add user
              </ButtonKs.Primary>
            }
            <ButtonKs.IconCustom icon={<AiFillBug />} tooltip='Check new accounts with open invitations' mr={2} onClick={() => {
              dispatch(actions.organisations.getMembersByOrgAndSyncInvitations(orgId, false, null))
            }} />
            <ButtonKs.Refresh onClick={() =>
              dispatch(actions.organisations.getMembersByOrg(orgId, false, null))
            } />
          </Box>
        </Flex>

        {loading &&
          <Box mt={16}>
            <LoadingTable columns={5} />
          </Box>
        }

        <Box overflow='auto'>
          <Table variant="striped" display={loading ? 'none' : ''}>
            <Thead>
              <Tr>
                <Th>Photo</Th>
                <Th>User</Th>
                <Th>Teams</Th>
                <Th>Projects</Th>
                <Th>Roles</Th>
                {
                  superUserUI === true &&
                  <>
                    <Th>Email</Th>
                    <Th>Invitation</Th>
                    <Th>Status</Th>
                    <Th>Options</Th>
                  </>
                }
              </Tr>
            </Thead>
            <Tbody>
              {currentMembers.users && currentMembers.users.map((uid: string, index: number) => {

                let userSimplified = { uid: uid, email: '', photoURL: '', displayName: '', disabled: false }
                if (currentMembers.usersData[uid]) userSimplified = currentMembers.usersData[uid]

                return (
                  <Tr index={index}>
                    <Td>
                      <Avatar name={userSimplified.displayName} src={userSimplified.photoURL} />
                    </Td>
                    <Td>{userSimplified.displayName}</Td>
                    <Td> - </Td>
                    <Td> - </Td>
                    <Td>
                      {uid === organisation.createdBy &&
                        <Badge ml={3} variant="outline" >Creator</Badge>
                      }
                      {currentMembers.adminsByUID && currentMembers.adminsByUID.includes(uid) &&
                        <Badge ml={3} variant="outline" >Admin</Badge>
                      }
                      <Badge ml={3} variant="outline" > Member </Badge>
                    </Td>
                    {
                      superUserUI === true &&
                      <>
                        <Td>{userSimplified.email}</Td>
                        <Td>
                          <Badge colorScheme="green">
                            Auto Accepted
                          </Badge>
                        </Td>
                        <Td>
                          {userSimplified.disabled === true && <BadgesAccess.UserSuspend />}
                          {userSimplified.disabled === false && <BadgesAccess.UserActive />}
                        </Td>

                      </>
                    }
                    <Td>
                      <ButtonKs.OptionsMenu index={index}>
                        <MenuGroup title="Options">
                          {(superUserUI === true) &&
                            <>
                              <MenuItem
                                onClick={() => removeByUid(uid)} isDisabled={organisation.createdBy === uid}
                              >
                                Remove user from organization
                              </MenuItem>
                              {currentMembers.adminsByUID && currentMembers.adminsByUID.includes(uid) ?
                                <MenuItem
                                  onClick={() => { switchAdminAccess(false, uid) }}
                                >
                                  Remove&nbsp;<b>admin</b>&nbsp;permissions
                                </MenuItem>
                                :
                                <MenuItem
                                  onClick={() => { switchAdminAccess(true, uid) }}
                                >
                                  Add&nbsp;<b>admin</b>&nbsp;permissions
                                </MenuItem>
                              }
                            </>
                          }
                        </MenuGroup>
                      </ButtonKs.OptionsMenu>
                    </Td>
                  </Tr>
                )
              })}
              {currentMembers.usersInvitedByEmail && currentMembers.usersInvitedByEmail.map((email: string, index: number) => {
                return (
                  <Tr index={index}>
                    <Td>
                      <Avatar name={email} />
                    </Td>
                    <Td> - </Td>
                    <Td> - </Td>
                    <Td> - </Td>
                    <Td>
                      {currentMembers.adminsByEmail && currentMembers.adminsByEmail.includes(email) &&
                        <Badge variant="outline" ml={3}>Admin</Badge>
                      }
                    </Td>
                    {
                      superUserUI === true &&
                      <>
                        <Td>{email}</Td>
                        <Td>
                          <Badge colorScheme="yellow">Open</Badge>
                        </Td>
                        <Td>
                          <BadgesAccess.UserWithoutAccount />
                        </Td>
                      </>
                    }
                    <Td>
                      <ButtonKs.OptionsMenu index={index}>
                        <MenuGroup title="Options">
                          {(superUserUI === true) &&
                            <>
                              <MenuItem onClick={() => removeByEmail(email)}> Remove email access </MenuItem>
                            </>
                          }
                        </MenuGroup>
                      </ButtonKs.OptionsMenu>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
      </CenterBox>
    </>
  );
}

export default OrganisationsPeople;