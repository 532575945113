import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from 'reactfire';
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { actions } from 'services/redux/actions'

export function useOrganisationHook() {

  const { data: { superUserUI } } = useContextUIHook()
  const stateOrganisation = useSelector((store: any) => store.organisationReducer);
  const dispatch = useDispatch();
  const user = useUser()
  const [organisationsLoading, setOrganisationsLoading] = useState(true)
  const [organisations, setOrganisations] = useState([])


  useEffect(() => {
    setOrganisationsLoading(true)
    dispatch(actions.organisations.getOrganisations(true, () => {
      setOrganisationsLoading(false)
    }))
  }, [dispatch]);

  useEffect(() => {
    if (superUserUI) {
      setOrganisations(stateOrganisation.organisations)
    } else {
      setOrganisations(stateOrganisation.organisations.filter((orgData: any) => {
        if (stateOrganisation?.organisationsById[orgData.id]?.organisation_access.users?.includes(user.data.uid)) return true
        return false
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateOrganisation.organisations, superUserUI, user.data.uid]);

  const getOrgById = (orgId: string) => {
    if (stateOrganisation.organisationsById) {
      if (stateOrganisation.organisationsById[orgId] && stateOrganisation.organisationsById[orgId].name) return stateOrganisation.organisationsById[orgId].name
      return 'Unknown organization'
    }
    return 'Error organization Data'
  }

  return {
    fun: {
      getOrgById
    },
    data: {
      organisationsLoading: organisationsLoading,
      organisationsById: stateOrganisation.organisationsById,
      organisations: organisations,
      organisationsForMultiSelect: organisations.map((organisation: any) => {
        return { 'value': organisation.id, 'label': organisation.name, 'colorScheme': 'green' }
      }),
      organisationsForSelect: organisations.map((organisation: any) => {
        return { 'value': organisation.id, 'text': organisation.name }
      }),
      organisationsAdminForMultiSelect: organisations.filter((organisation: any) => {
        if (superUserUI) return true
        if (stateOrganisation?.organisationsById[organisation.id]?.organisation_access?.adminsByUID.includes(user.data.uid)) return true
        return false
      }).map((organisation: any) => {
        return { 'value': organisation.id, 'label': organisation.name, 'colorScheme': 'green' }
      }),
      organisationsAdminForSelect: organisations.filter((organisation: any) => {
        if (superUserUI) return true
        if (stateOrganisation?.organisationsById[organisation.id]?.organisation_access?.adminsByUID.includes(user.data.uid)) return true
        return false
      }).map((organisation: any) => {
        return { 'value': organisation.id, 'text': organisation.name }
      }),
    },
  }
}