import React from 'react';

const siteTypes: Array<any> = [
  { text: 'Mall', value: 'mall' },
  { text: 'Street side', value: 'street_side' },
  { text: 'Office block', value: 'office_block' }
];

function OptionsTypeSite() {

  return (
    <>
      {
        siteTypes.map((site: any, index: number) => {
          return (
            <option value={site.value} key={index}>
              {site.text}
            </option>
          )
        })
      }
    </>
  );
}

export function OptionsToText(option: string) {
  const filt = siteTypes.filter((site: any) => site.value === option)
  if (filt[0]) return filt[0].text
  return 'Invalid Type'
}


export default OptionsTypeSite;