import {
  LOADING, UPDATE_ALL, UPDATE_BY_ID,
  LOADING_COLLECT, UPDATE_ALL_COLLECT, UPDATE_BY_ID_COLLECT,
  LOADING_DOCUMENTS, UPDATE_ALL_DOCUMENTS_BY_PROJ,
} from 'services/redux/project/project.type'
import { ApiKs } from 'services/api/API';
import { cleanDocNative } from 'helpers/kshub';
import { patchEntitiesCollectionObject, patchNewEntityCollectionObject, removeEntityCollectionObject } from '../helper';
import { saveAs } from "file-saver";

export const patchProjects = (projId: string, payload: any, currentProjects: Array<any>) => {
  const projectsPatched = currentProjects.map((proj: any) => {
    if (proj.id !== projId) return proj
    return { ...proj, ...payload }
  })
  return projectsPatched
}

export const patchProjectsGroups = (projGroupId: string, payload: any, currentProjectsGroups: Array<any>) => {
  const groupsPatched = currentProjectsGroups.map((group: any) => {
    if (group.id !== projGroupId) return group
    return { ...group, ...payload }
  })
  return groupsPatched
}

// DOCUMENTS

export const getDocumentById = (projId: string, documentId: string, cache: boolean = false, callback: any = null) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_DOCUMENTS });

  const { projectReducer }: any = getState();
  if (
    cache &&
    projectReducer?.documentsByProject[projId] &&
    projectReducer?.documentsByProject[projId][documentId] &&
    projectReducer?.documentsByProject[projId][documentId].versions !== null) {

    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: patchNewEntityCollectionObject(
        documentId,
        projectReducer.documentsByProject[projId][documentId],
        projectReducer.documentsByProject[projId])
    })

    callback && callback(projectReducer.documentsByProject[projId][documentId])
  } else {
    ApiKs().apiPrivate.projects.documents.get(projId, documentId).then((res: any) => {
      const docData = res.data.data;

      dispatch({
        type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
        projectId: projId,
        payload: patchNewEntityCollectionObject(
          documentId,
          docData,
          projectReducer.documentsByProject[projId])
      })

      callback && callback(docData)
    })
  }
}

export const getDocumentsByProject = (projId: string, cache: boolean = false, callback: any = null) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_DOCUMENTS });
  const { projectReducer }: any = getState();

  if (cache && projectReducer.documentsByProject[projId]) {
    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: projectReducer.documentsByProject[projId]
    })

    callback && callback()
  } else {
    ApiKs().apiPrivate.projects.documents.list(projId).then((res: any) => {

      const documentsById: any = {}
      res.data.data.map((proj: any) => {
        return documentsById[proj.id] = proj;
      })

      dispatch({
        type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
        projectId: projId,
        payload: documentsById
      })

      callback && callback()
    })
  }
}

export const updateDocument = (projId: string, documentId: string, payload: any, callback: any = null) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();
  const payloadEdited = { ...payload }
  const documentComplete: any = { ...projectReducer.documentsByProject[projId][documentId], ...payload }

  ApiKs().apiPrivate.projects.documents.update(projId, documentId, cleanDocNative(payloadEdited)).then((res: any) => {
    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: patchNewEntityCollectionObject(
        documentId,
        documentComplete,
        projectReducer.documentsByProject[projId])
    })
    callback && callback(res.data.status)
  }).catch((err: any) => {
    callback && callback(false, err)
  })
}

export const updateDocumentVersion = (projId: string, documentId: string, versionId: string, payload: any, callback: any = null) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();
  const payloadEdited = { ...payload }

  ApiKs().apiPrivate.projects.documents.updateVersion(projId, documentId, versionId, cleanDocNative(payloadEdited)).then((res: any) => {
    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: patchNewEntityCollectionObject(
        documentId,
        res.data.data,
        projectReducer.documentsByProject[projId])
    })
    callback && callback(res.data.status)
  }).catch((err: any) => {
    callback && callback(false, err)
  })
}

export const createDocument = (projId: string, payload: any, callback: any = null) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_DOCUMENTS });
  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.documents.create(projId, payload).then((res: any) => {
    const response = res.data.data;
    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: patchNewEntityCollectionObject(response.id, response, projectReducer.documentsByProject[projId])
    })
    callback && callback(true, res)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false, err)
  });
}

export const createDocumentVersion = (projId: string, documentId: string, payload: any, callback: any = null) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_DOCUMENTS });
  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.documents.createVersion(projId, documentId, payload).then((res: any) => {
    const response = res.data.data;
    console.log(response)
    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: patchEntitiesCollectionObject(documentId, response, projectReducer.documentsByProject[projId])
    })
    callback && callback(true, res)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false, err)
  });
}

export const downloadDocument = (projId: string, fileName: string, documentId: string, refStorage: string, callback: any = null) => (dispatch: any, getState: any) => {
  ApiKs().apiPrivate.projects.documents.download(projId, documentId, refStorage).then((res: any) => {

    const blob = new Blob([res.data])
    saveAs(blob, fileName)
    callback && callback(true)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false)
  });
}

export const downloadDocumentByVersion = (projId: string, documentId: string, fileName: string, version: number, refStorage: string, callback: any = null) => (dispatch: any, getState: any) => {
  ApiKs().apiPrivate.projects.documents.downloadByVersion(projId, documentId, version, refStorage).then((res: any) => {
    const blob = new Blob([res.data])
    saveAs(blob, `V${version}_${fileName}`)
    callback && callback(true)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false)
  });
}

export const archiveDocument = (projId: string, documentId: string, callback: any = null) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_DOCUMENTS });
  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.documents.archive(projId, documentId).then((res: any) => {
    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: removeEntityCollectionObject(documentId, projectReducer.documentsByProject[projId])
    })
    callback && callback(true, res)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false, err)
  });
}

export const archiveDocumentVersion = (projId: string, documentId: string, versionId: string, callback: any = null) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_DOCUMENTS });
  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.documents.archiveVersion(projId, documentId, versionId).then((res: any) => {
    dispatch({
      type: UPDATE_ALL_DOCUMENTS_BY_PROJ,
      projectId: projId,
      payload: patchEntitiesCollectionObject(documentId, res.data.data, projectReducer.documentsByProject[projId])
    })
    callback && callback(true, res)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false, err)
  });
}

// PROJECTS

export const getProjects = (cache: boolean = false) => async (dispatch: any, getState: any) => {

  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();
  if (cache && projectReducer.projects.length > 0) {

    const projectsById: any = {}
    projectReducer.projects.map((proj: any) => {
      return projectsById[proj.id] = proj;
    })

    dispatch({
      type: UPDATE_ALL,
      payload: { data: projectReducer.projects, projectsById: projectsById }
    })
  } else {
    ApiKs().apiPrivate.projects.list().then((res: any) => {

      const projectsById: any = {}
      res.data.data.map((proj: any) => {
        return projectsById[proj.id] = proj;
      })

      dispatch({
        type: UPDATE_ALL,
        payload: { data: res.data.data, projectsById: projectsById }
      })
    })
  }
}

export const getProjectById = (projId: string, cache: boolean = false, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  if (cache && projectReducer.projectsById[projId]) {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(projId, projectReducer.projectsById[projId], projectReducer.projects),
      id: projId,
      payload: projectReducer.projectsById[projId]
    })

    callback && callback(projectReducer.projectsById[projId])
  } else {
    ApiKs().apiPrivate.projects.get(projId).then((res: any) => {
      if (res?.data?.id) {
        dispatch({
          type: UPDATE_BY_ID,
          projects: patchProjects(projId, res.data.data, projectReducer.projects),
          id: projId,
          payload: { id: projId, ...res.data.data }
        })

        callback && callback({ id: projId, ...res.data.data })
      }
    })
  }
}

export const createProject = (payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.create(payload).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(res.data.data.id, res.data.data, projectReducer.projects),
      payload: { ...res.data.data }
    })
    callback && callback(true, res)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false, err)
  });

}

export const updateProject = (projId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  const payloadEdited = { ...payload }
  delete payloadEdited.lastCommentAt
  delete payloadEdited.lastComment
  delete payloadEdited.overviewText


  ApiKs().apiPrivate.projects.update(projId, cleanDocNative(payloadEdited)).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      id: projId,
      projects: patchProjects(projId, payload, projectReducer.projects),
      payload: payload
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    callback(false, err)
  })
}

export const updateProjectManagment = (projId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  const payloadEdited = {
    channelId: payload.channelId,
    confidence: payload.confidence,
    placeId: payload.placeId,
    dateProjectedCompletion: payload.dateProjectedCompletion,
    dateTargetCompletion: payload.dateTargetCompletion,
    currentStatus: payload.currentStatus,
    currentPhase: payload.currentPhase
  }

  const fullPayload = { ...projectReducer.projectsById[projId], ...payloadEdited }

  ApiKs().apiPrivate.projects.updateManagment(projId, cleanDocNative(payloadEdited)).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(projId, payload, projectReducer.projects),
      id: projId,
      payload: fullPayload
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    callback(false, err)
  })
}

export const updateProjectLastComment = (projId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.updateLastComment(projId, payload).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(projId, payload, projectReducer.projects),
      id: projId,
      payload: {
        ...projectReducer.projectsById[projId],
        lastCommentAt: res.data.data.lastCommentAt,
        ...payload
      }
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    callback(false, err)
  })
}

export const updateProjectStatus = (projId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.updateProjectStatus(projId, payload).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(projId, payload, projectReducer.projects),
      id: projId,
      payload: {
        ...projectReducer.projectsById[projId],
        ...payload
      }
    })
    callback && callback(res.data.status)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false)
  })
}

export const updateProjectPhase = (projId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.updateProjectPhase(projId, payload).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(projId, payload, projectReducer.projects),
      id: projId,
      payload: {
        ...projectReducer.projectsById[projId],
        ...payload
      }
    })
    callback && callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false, err)
  })
}

export const updateProjectOverview = (projId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.updateProjectOverview(projId, payload).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(projId, payload, projectReducer.projects),
      id: projId,
      payload: {
        ...projectReducer.projectsById[projId],
        ...payload
      }
    })
    callback && callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    console.error(err)
    callback && callback(false, err)
  })
}

export const updateProjectConfidence = (projId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projects.updateProjectConfidence(projId, payload).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      projects: patchProjects(projId, payload, projectReducer.projects),
      id: projId,
      payload: {
        ...projectReducer.projectsById[projId],
        ...payload
      }
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    console.error(err)
    callback(false, err)
  })
}


// PROJECTS GROUPS

export const getProjectsGroups = () => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_COLLECT });

  ApiKs().apiPrivate.projectsgroups.list().then((res: any) => {

    const projectsGroupsById: any = {}
    res.data.data.map((proj: any) => {
      return projectsGroupsById[proj.id] = proj;
    })

    dispatch({
      type: UPDATE_ALL_COLLECT,
      payload: { data: res.data.data, projectsGroupsById: projectsGroupsById }
    })
  })
}

export const getProjectGroupById = (projectGroupId: string, cache: boolean = false, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_COLLECT });

  const { projectReducer }: any = getState();

  if (cache && projectReducer.projectsGroupsById[projectGroupId]) {
    dispatch({
      type: UPDATE_BY_ID_COLLECT,
      projectsGroups: patchProjectsGroups(projectGroupId, projectReducer.projectsGroupsById[projectGroupId], projectReducer.projectsGroups),
      id: projectGroupId,
      payload: projectReducer.projectsGroupsById[projectGroupId]
    })
  } else {
    ApiKs().apiPrivate.projectsgroups.get(projectGroupId).then((res: any) => {
      if (res?.data?.id) {
        dispatch({
          type: UPDATE_BY_ID_COLLECT,
          projectsGroups: patchProjectsGroups(projectGroupId, res.data, projectReducer.projectsGroups),
          id: projectGroupId,
          payload: res.data
        })
      }
    })
  }
}

export const createProjectGroup = (payload: any, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_COLLECT });
  const { projectReducer }: any = getState();

  ApiKs().apiPrivate.projectsgroups.create(payload).then((res: any) => {
    if (res.data.id) {
      dispatch({
        type: UPDATE_BY_ID_COLLECT,
        projectsGroups: patchProjectsGroups(res.data.id, res.data, projectReducer.projectsGroups),
        id: res.data.id,
        payload: { id: res.data.id, ...res.data.data }
      })
      callback(true, res)
    } else {
      callback(false, 'ID Error')
    }
  }).catch((err: any) => {
    callback(false, err)
  });
}

export const updateProjectGroup = (projectGroupId: string, payload: any, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING_COLLECT });

  const { projectReducer }: any = getState();
  const currenteProjectGroup = projectReducer.projectsGroupsById[projectGroupId]

  const payloadEdited = { ...payload }
  if (payloadEdited.currentPhase) delete payloadEdited.currentPhase

  ApiKs().apiPrivate.projectsgroups.update(projectGroupId, cleanDocNative(payloadEdited)).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID_COLLECT,
      projectsGroups: patchProjectsGroups(res.data.id, payload, projectReducer.projectsGroups),
      id: projectGroupId,
      payload: { ...currenteProjectGroup, ...payload }
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    callback(false, err)
  })
}