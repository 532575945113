import React, { useEffect } from 'react'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { localSt, LOCAL_ST } from 'helpers/local-session-storage'
import { AuthCheck } from 'reactfire'
import { Provider } from 'react-redux';
import { store } from 'services/redux/store'
import Main from './App/Main'
import ChakraBase from './Wrappers/ChakraBase'
import Firebase from './Wrappers/Firebase'
import Contexts from './Wrappers/Contexts'
import Open from './Open'
import Private from './Private'
import NotFound from './Open/NotFound'
import { LoginScreen } from './Open/Login'
import ReactIntl from './Wrappers/ReactIntl';

export function AppGeneral(props: any) {

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      localSt.defineNow(LOCAL_ST.firstUrlOpen, window.location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={store}>
      <ChakraBase>
        <Firebase>

          <BrowserRouter>

            <Switch>
              <Route exact path="/">
                <Redirect to="/app" />
              </Route>

              <Route path='/open'>
                <Open />
              </Route>

              <Route path='/private'>
                <Private />
              </Route>

              <Route path='/app'>
                <Contexts>
                  <ReactIntl>
                    <AuthCheck fallback={<LoginScreen />}>
                      <Main />
                    </AuthCheck>
                  </ReactIntl>
                </Contexts>
              </Route>

              <Route>
                <NotFound />
              </Route>
            </Switch>

          </BrowserRouter>

        </Firebase>
      </ChakraBase>
    </Provider>
  )
}
