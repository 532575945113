import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Badge,
  Avatar,
  Flex,
  Spacer,
  Text,
  Box, Heading, MenuItem, MenuGroup, Stack, useToast
} from "@chakra-ui/react"
import { ApiKs } from 'services/api/API';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import BadgesAccess from 'pages/Components/generic/Badges/BadgesAccess';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import { toastTypes } from 'helpers/alerts';

function SuperUserHome() {

  const { apiSuper } = ApiKs();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [openDetail, setOpenDetail] = useState(null)
  const toast = useToast();

  useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateData = () => {
    setLoading(true)
    setDisabled(true)
    setData([])
    apiSuper.users.getAll().then((res: any) => {
      setData(res.data.users)
      setLoading(false)
      setDisabled(false)
    })
  }

  const restoreUser = (uid: string) => {
    setDisabled(true);
    setLoading(true);
    apiSuper.users.restore(uid).then((res: any) => {
      toast(toastTypes.saved)
      updateData()
    }).catch((err: any) => {
      console.error(err)
    })
  }

  const suspendUser = (uid: string) => {
    setDisabled(true);
    setLoading(true);
    apiSuper.users.suspend(uid).then((res: any) => {
      toast(toastTypes.saved)
      updateData()
    }).catch((err: any) => {
      console.error(err)
    })
  }

  return (
    <Box>
      <Flex mb={5}>
        <Box>
          <Heading as="h1" size="xl">
            All Users
          </Heading>
          <Text as="h2" size="m" mb={3}>
            This feature is available only for <b>Super Users</b>
          </Text>
        </Box>
        <Spacer />
        <Box>
          <ButtonKs.Refresh
            isLoading={loading}
            onClick={updateData}
            mr={3}
          />
        </Box>
      </Flex>
      <Flex>
        <Box overflow='auto' width={openDetail ? '70%' : '100%'}>
          {!loading &&
            <Table variant="striped" colorScheme="gray" overflowX="auto">
              <Thead>
                <Tr>
                  <Th>Photo</Th>
                  <Th>User</Th>
                  <Th>Email</Th>
                  <Th>UID</Th>
                  <Th>Status</Th>
                  <Th>Options</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  data.map((user: any, index: number) => {
                    return (
                      <Tr key={index}>
                        <Td>
                          <Avatar size="sm" name="Ryan Florence" src={user.photoURL} />
                        </Td>
                        <Td>{user.displayName}</Td>
                        <Td>{user.email}</Td>
                        <Td><Badge>{user.uid}</Badge></Td>
                        <Td>
                          {user.disabled ?
                            <BadgesAccess.UserSuspend /> :
                            <BadgesAccess.UserActive />
                          }
                        </Td>
                        <Td>
                          <ButtonKs.OptionsMenu
                            m={2}
                            index={index}
                          >
                            <MenuGroup title="Firebase Auth">
                              <MenuItem isDisabled={disabled || !user.disabled} onClick={() => restoreUser(user.uid)}>Restore</MenuItem>
                              <MenuItem isDisabled={disabled || user.disabled} onClick={() => suspendUser(user.uid)}>Suspend</MenuItem>
                            </MenuGroup>
                            <MenuGroup title="Data">
                              <MenuItem isDisabled={disabled} onClick={() => setOpenDetail(user || {})}>
                                Metadata
                              </MenuItem>
                            </MenuGroup>
                          </ButtonKs.OptionsMenu>

                          <ButtonKs.IconInfo
                            index={index}
                            onClick={() => setOpenDetail(user || {})}
                            m={2}
                          />

                        </Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>Photo</Th>
                  <Th>User</Th>
                  <Th>Email</Th>
                  <Th>UID</Th>
                  <Th>Status</Th>
                </Tr>
              </Tfoot>
            </Table>
          }
          {loading &&
            <LoadingTable columns={6} />
          }
        </Box>
        {
          openDetail &&

          <Box margin={5} padding={3} maxWidth='30%'>
            <Stack spacing={3}>
              <Box>
                <Avatar size="xl" name="Ryan Florence" src={openDetail.photoURL} />
                <ButtonKs.Close float='right' onClick={() => setOpenDetail(null)} />
              </Box>
              <Box p={5} shadow="md" borderWidth="1px">
                <Heading fontSize="xl">User Data: </Heading>
                <Text mt={4}>
                  <b>Display Name:</b> {openDetail.displayName}
                </Text>
                <Text mt={1}>
                  <b>Email:</b> {openDetail.email}
                </Text>
              </Box>
              <Box p={5} shadow="md" borderWidth="1px">
                <Heading fontSize="xl">Metadata: </Heading>
                <Text mt={4}>
                  <b>Creation Time:</b> {openDetail.metadata.creationTime}
                </Text>
                <Text mt={1}>
                  <b>Last Sign In:</b> {openDetail.metadata.lastSignInTime}
                </Text>
              </Box>
              <Box p={5} shadow="md" borderWidth="1px">
                <Heading fontSize="xl">Provider Data: </Heading>
                {
                  openDetail.providerData && openDetail.providerData.map((provider: any) => {
                    return (
                      <Box mt={3} mb={3}>
                        {
                          Object.keys(provider).map((key: any) => {
                            return (
                              <Text key={key}><b>{`${key}:`} </b>{`${provider[key]}`}</Text>
                            )
                          })
                        }
                      </Box>
                    )
                  })
                }
              </Box>
            </Stack>
          </Box>
        }
      </Flex>
    </Box>
  );
}

export default SuperUserHome;