import { VStack, Stack, Box, Grid, GridItem, Spinner, Text, SimpleGrid, Skeleton, } from '@chakra-ui/react';
import React from 'react';

function LoadingGeneric({ boxes = 3 }: any) {
  const spread = Array.from(new Array(boxes).keys())

  return (
    <SimpleGrid columns={1} spacing={10} mt={6}>
      <Box>

        <SimpleGrid columns={1} spacing={6}>
          <Box>
            <Stack>
              {spread.map((index: number) => {
                const height = (160 - index * 20) + 'px'
                return (
                  <Skeleton key={index} height={height} />
                )
              })}
            </Stack>
          </Box>
        </SimpleGrid>

        <VStack spacing={8} mt={8}>
          <Box margin='auto'>
            <Grid
              templateColumns="repeat(1, 1fr)"
              gap={1}
            >
              <GridItem colSpan={1} textAlign="center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="lg"
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Text>
                  Loading
                </Text>
              </GridItem>
            </Grid>
          </Box>
        </VStack>
      </Box >
    </SimpleGrid >
  );
}

export default LoadingGeneric;