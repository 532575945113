import React from 'react';
import { ButtonKs } from 'pages/Components/generic/Buttons'
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import { Heading, MenuGroup, MenuItem, Text } from '@chakra-ui/react';

function UIDemoHome() {


  return (
    <BoxShadow>
      <Heading mb={6}>
        Kitspace Buttons
      </Heading>

      <Text> # Close (1) </Text>
      <ButtonKs.Close m={2} />

      <Text> # Confirm Action (2) </Text>
      <ButtonKs.ConfirmAction m={2} />

      <Text> # Create (3)</Text>
      <ButtonKs.Create m={2} />

      <Text> # Primary (Custom Text) (4)</Text>
      <ButtonKs.Primary m={2} text='Open Modal' />

      <Text> # Save Changes (6)</Text>
      <ButtonKs.SaveChanges m={2} />

      <Text> # Success Action (Custom Text) (5)</Text>
      <ButtonKs.Success m={2} text='Send Emails' />

      <Text> # Cancel (7)</Text>
      <ButtonKs.Cancel m={2} />

      <Text> # Danger (Custom Text) (8)</Text>
      <ButtonKs.Danger m={2} text='Delete Product' />

      <Text> # Refresh with Icon (9)</Text>
      <ButtonKs.Refresh m={2} />

      <Text> # Info with Icon (10)</Text>
      <ButtonKs.IconInfo m={2} />

      <Text> # Menu with Icon (11)</Text>
      <ButtonKs.OptionsMenu m={2}>
        <MenuGroup title="Example Title">
          <MenuItem>Example Item</MenuItem>
        </MenuGroup>
      </ButtonKs.OptionsMenu>

    </BoxShadow>
  );
}

export default UIDemoHome;