import styled from 'styled-components'
import { Box } from '@chakra-ui/react';

interface DropZoneHWProps {
  haveFile: boolean;
}

export const DropZoneHW: any = styled.div<DropZoneHWProps>`
  min-width: 50%;
  width: auto;
  min-height: 50px;
  border-radius: 1em;
  margin: 1em;
  border: ${props => props.haveFile ? "thin dashed" : "thin dashed"};
  /* background-color: ${props => !props.haveFile && "#FFF"}; */
  padding-top: 1.5em;
  text-align: center;
  cursor: pointer;
`

export const ThumnailZone = styled.div`
  width: auto;
  margin: 1em;
  padding: 2em;
  text-align: center;
  cursor: pointer;
`

export const ThumbnailFromDropZone = styled.img`
  max-height: 6em;
  height: auto;
  border-radius: 1em;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.75) !important;
  box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.75) !important;
`

export const ThumbnailText = styled(Box)`
  margin: 0 auto;
  margin-top: 1em;
  font-size: 14px;
  white-space: nowrap; 
  width: 50%; 
  overflow: hidden;
  text-overflow: ellipsis; 
  border: 0px solid #000000;
`