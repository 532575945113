import React, { createContext, useEffect } from "react";
import { useImmer } from 'use-immer';

export interface AppContextDataType {
  nouser: any;
  superUserReference: boolean;
  public: any;
}

export interface AppContextType {
  loading: boolean;
  initialized: boolean;
  updatedAt: Date | null;
  data: AppContextDataType;
  FNsetDataImmer: (draft: any) => void;
}

const init: AppContextType = {
  loading: false,
  initialized: false,
  updatedAt: null,
  data: {
    nouser: {},
    superUserReference: false,
    public: {
      authorized: {} // Document : /public/authorized
    }
  },
  FNsetDataImmer: () => { },
}

const AppContext = createContext(init);
const { Consumer } = AppContext;

function AppProvider(props: any): any {

  const [AppData, setAppData]: any = useImmer(init)

  useEffect(() => {
    setAppData((draft: AppContextType) => {
      draft.FNsetDataImmer = setAppData;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppContext.Provider value={AppData} >
      {props.children}
    </AppContext.Provider >
  )

}

export default AppContext;

export {
  AppProvider,
  Consumer as AppConsumer,
};