import { apiConfig } from 'config/api.config'
import axios from 'axios'
import firebase from 'firebase'

export function ApiKs() {
  const user: any = firebase.auth().currentUser;
  const baseURL: string = apiConfig.fullURL;

  const axiosAuth = {
    baseURL: baseURL,
    headers: {
      'FireUid': user ? user.uid : '',
      'Authorization': ''
    }
  }

  const axiosPublic = {
    baseURL: baseURL
  }

  const getAxiosAuth = () => {
    return new Promise((resolve) => {
      user.getIdToken().then((res: any) => {
        const config: any = axiosAuth;
        config.headers.Authorization = res;
        // config.headers.Accept = 'application/octet-stream'
        // config.headers['Content-Type'] = 'application/octet-stream'

        resolve(axios.create(config))
      })
    })
  }

  const getAxiosPublic = () => {
    return axios.create(axiosPublic);
  }

  const apiPrivate = {
    firebase: {
      me: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/firebase/me',
          })
        });
      }
    },
    example: {
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/example',
          })
        });
      },
      get: (exampleId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/example/${exampleId}`,
          })
        });
      },
      create: (payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: '/example',
          })
        });
      },
      update: (exampleId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/example/${exampleId}`,
            data: payload
          })
        });
      },
    },
    places: {
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/places',
          })
        });
      },
      get: (placeId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/places/${placeId}`,
          })
        });
      },
      create: (payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: '/places',
          })
        });
      },
      update: (placeId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/places/${placeId}`,
            data: payload
          })
        });
      },
    },
    sites: {
      list: (placeId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/places/${placeId}/sites`,
          })
        });
      },
      get: (placeId: string, siteId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/places/${placeId}/sites/${siteId}`,
          })
        });
      },
      create: (placeId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: `/places/${placeId}/sites`,
          })
        });
      },
      update: (placeId: string, siteId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/places/${placeId}/sites/${siteId}`,
            data: payload
          })
        });
      },
    },
    projects: {
      documents: {
        get: (projId: string, documentId: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'get',
              url: `/projects/${projId}/documents/${documentId}`,
            })
          });
        },
        update: (projId: string, documentId: string, payload: any) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'patch',
              url: `/projects/${projId}/documents/${documentId}`,
              data: payload
            })
          });
        },
        updateVersion: (projId: string, documentId: string, versionId: string, payload: any) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'patch',
              url: `/projects/${projId}/documents/${documentId}/${versionId}`,
              data: payload
            })
          });
        },
        create: (projId: string, payload: any) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'post',
              url: `/projects/${projId}/documents`,
              data: payload,
            })
          });
        },
        createVersion: (projId: string, documentId: string, payload: any) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'post',
              url: `/projects/${projId}/documents/${documentId}/new`,
              data: payload,
            })
          });
        },
        list: (projId: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'get',
              url: `/projects/${projId}/documents`,
            })
          });
        },
        archive: (projId: string, documentId: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'delete',
              url: `/projects/${projId}/documents/${documentId}/archive-doc`,
            })
          });
        },
        archiveVersion: (projId: string, documentId: string, versionId: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'patch',
              url: `/projects/${projId}/documents/${documentId}/version/${versionId}/archive-version`,
            })
          });
        },
        download: (projId: string, documentId: string, refStorage: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'post',
              url: `/projects/${projId}/documents/${documentId}/download`,
              data: { refStorage: refStorage },
              responseType: 'blob',
            })
          });
        },
        downloadByVersion: (projId: string, documentId: string, version: number, refStorage: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'post',
              url: `/projects/${projId}/documents/${documentId}/download`,
              data: { refStorage: refStorage, version: version },
              responseType: 'blob',
            })
          });
        },
      },
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/projects',
          })
        });
      },
      get: (projectId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/projects/${projectId}`,
          })
        });
      },
      create: (payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: '/projects',
          })
        });
      },
      updateLastComment: (projId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projects/${projId}/last-comment`,
            data: payload
          })
        });
      },
      updateProjectOverview: (projId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projects/${projId}/overview`,
            data: payload
          })
        });
      },
      updateProjectStatus: (projId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projects/${projId}/proj-status`,
            data: payload
          })
        });
      },
      updateProjectPhase: (projId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projects/${projId}/proj-phase`,
            data: payload
          })
        });
      },
      updateProjectConfidence: (projId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projects/${projId}/proj-confidence`,
            data: payload
          })
        });
      },
      update: (projId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projects/${projId}`,
            data: payload
          })
        });
      },
      updateManagment: (projId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projects/${projId}/managment`,
            data: payload
          })
        });
      },
    },
    projectsgroups: {
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/projectsgroups',
          })
        });
      },
      get: (projectGroupId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/projectsgroups/${projectGroupId}`,
          })
        });
      },
      create: (payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: '/projectsgroups',
          })
        });
      },
      update: (projectGroupId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/projectsgroups/${projectGroupId}`,
            data: payload
          })
        });
      }
    },
    organisations: {
      create: (payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: '/organisations/create',
          })
        });
      },
      update: (orgId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/organisations/${orgId}`,
            data: payload
          })
        });
      },
      addMembers: (orgId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/organisations/${orgId}/members`,
            data: payload
          })
        });
      },
      switchAdminAccess: (orgId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/organisations/${orgId}/switch-admin`,
            data: payload
          })
        });
      },
      removeMembers: (orgId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/organisations/${orgId}/members/remove`,
            data: payload
          })
        });
      },
      getMembers: (orgId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/organisations/${orgId}/members`,
          })
        });
      },
      getMembersAndSyncInvitations: (orgId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/organisations/${orgId}/members/sync-invitations`,
          })
        });
      },
      updateState: (orgId: string, newState: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/organisations/super/${orgId}/update-state`,
            data: { newState }
          })
        });
      },
      updatePartners: (orgId: string, partners: Array<string>) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/organisations/super/${orgId}/org-partners`,
            data: { partners: partners }
          })
        });
      },
      updatePlacesRelated: (orgId: string, places: Array<string>) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/organisations/super/${orgId}/places-related`,
            data: { places: places }
          })
        });
      },
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/organisations/list',
          })
        });
      },
      get: (orgId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/organisations/${orgId}`,
          })
        });
      },
    },
    users: {
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/users/all-limited',
          })
        });
      },
    },
    insights: {
      projects: {
        list: () => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'get',
              url: '/insights/projects',
            })
          });
        },
        update: (projectReportId: string, payload: any) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'patch',
              data: payload,
              url: `/insights/projects/${projectReportId}`,
            })
          });
        },
        create: (payload: any) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'post',
              data: payload,
              url: '/insights/projects',
            })
          });
        },
        sendNow: (projectReportId: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'post',
              url: `/insights/projects/${projectReportId}/send-now`,
            })
          });
        },
      }
    }
  }

  const apiSuper = {
    users: {
      getAll: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/users/super/all',
          })
        });
      },
      restore: (uid: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/users/super/restore/${uid}`,
          })
        });
      },
      suspend: (uid: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/users/super/suspend/${uid}`,
          })
        });
      }
    },
    nousers: {
      getAll: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/nousers/super/all',
          })
        });
      },
      invite: {
        create: (email: string, fullName: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'post',
              url: '/nousers/super/create',
              data: {
                email: email,
                fullName: fullName
              }
            })
          });
        },
        cancel: (email: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'patch',
              url: '/nousers/super/invite/cancel',
              data: {
                email: email
              }
            })
          });
        },
        open: (email: string) => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'patch',
              url: '/nousers/super/invite/open',
              data: {
                email: email
              }
            })
          });
        }
      },
      public: {
        authorized: {
          get: () => {
            return getAxiosAuth().then((axi: any) => {
              return axi({
                method: 'get',
                url: '/nousers/super/doc/public/authorized/get',
              })
            });
          },
          update: (emailsList: Array<string>, domainsList: Array<string>) => {
            return getAxiosAuth().then((axi: any) => {
              return axi({
                method: 'patch',
                url: '/nousers/super/doc/public/authorized/update',
                data: {
                  emailsList: emailsList,
                  domainsList: domainsList
                }
              })
            });
          }
        }
      },
      superusers: {
        list: () => {
          return getAxiosAuth().then((axi: any) => {
            return axi({
              method: 'get',
              url: '/nousers/super/superusers/list',
            })
          });
        }
      }
    },
    scheduledtasks: {
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/scheduledtasks',
          })
        });
      },
      listLogsGroups: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/scheduledtasks/logs',
          })
        });
      },
      getLogsByGroup: (groupId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/scheduledtasks/logs/${groupId}`,
          })
        });
      },
      get: (exampleId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/scheduledtasks/${exampleId}`,
          })
        });
      },
      create: (payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: '/scheduledtasks',
          })
        });
      },
      update: (taskId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/scheduledtasks/${taskId}`,
            data: payload
          })
        });
      },
    },
    example: {
      list: () => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: '/example',
          })
        });
      },
      get: (exampleId: string) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'get',
            url: `/example/${exampleId}`,
          })
        });
      },
      create: (payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'post',
            data: payload,
            url: '/example',
          })
        });
      },
      update: (exampleId: string, payload: any) => {
        return getAxiosAuth().then((axi: any) => {
          return axi({
            method: 'patch',
            url: `/example/${exampleId}`,
            data: payload
          })
        });
      },
    },
  }

  const apiPublic = {
    insights: {
      projects: {
        render: (jwt: string, projectReportId: string) => {
          return getAxiosPublic()({
            method: 'post',
            url: `/insights/projects/render/${projectReportId}`,
            data: { jwt: jwt }
          });
        }
      }
    },
    get: {
      test: () => {
        return getAxiosPublic()({
          method: 'get',
          url: '/test',
        });
      },
      firebase: {
        test: () => {
          return getAxiosPublic()({
            method: 'get',
            url: '/firebase/test',
          });
        },
      }
    },
  }

  return {
    data: {
      baseURL
    },
    apiPublic: apiPublic,
    apiPrivate: apiPrivate,
    apiSuper: apiSuper
  }
}