import React from 'react';
import {
  Heading,
  Text,
  Box,
  Divider,
} from '@chakra-ui/react';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import { useIntl } from 'react-intl';
import BadgesAccess from 'pages/Components/generic/Badges/BadgesAccess';


function LabelsUsers() {

  const intl = useIntl()


  return (
    <BoxShadow>
      <Heading fontSize="xl" mb={5}>
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.registered_user' }
          )
        }
      </Heading>
      <Box mt={3}>
        <Text fontWeight="bold">
          {
            intl.formatMessage(
              { id: 'ks.app.status' }
            )
          }
        </Text>
        <BadgesAccess.UserActive ml={1} mr={2} />
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.definition_active' }
          )
        }
        <br />
        <Divider mt={3} mb={3} />
        <BadgesAccess.UserSuspend ml={1} mr={2} />
        {
          intl.formatMessage(
            { id: 'ks.superuser.home.labels.definition_suspend' }
          )
        }
        <br />
      </Box>
    </BoxShadow>
  );
}

export default LabelsUsers;