import React, { createContext, useEffect } from "react";
import { useImmer } from 'use-immer';
import { LOCAL_ST, localSt } from 'helpers/local-session-storage'

const initial: any = {
  data: {
    superUser: false,
    language: localSt.obtainNow(LOCAL_ST.settingsLanguage) || 'en-US'
  }
}

const UIContext = createContext(initial);
const { Consumer } = UIContext;

function UIProvider(props: any): any {

  const [UIData, setUIData]: any = useImmer(initial)

  useEffect(() => {
    setUIData((draft: any) => {
      draft.setDataImmer = setUIData;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UIContext.Provider value={UIData} >
      {props.children}
    </UIContext.Provider >
  )

}

export default UIContext;

export {
  UIProvider,
  Consumer as ModalConsumer,
};