import { UseToastOptions } from "@chakra-ui/react";

const processing: UseToastOptions = {
  title: `Processing request`,
  status: 'info',
  isClosable: true,
}

const downloading: UseToastOptions = {
  title: `Starting the download ...`,
  description: 'The download will start automatically',
  status: 'info',
  isClosable: true,
}

const processingLong: UseToastOptions = {
  title: `Processing request`,
  description: 'This may take a few seconds',
  status: 'info',
  isClosable: true,
}

const invalid: UseToastOptions = {
  title: `Invalid or empty resource`,
  status: 'error',
  isClosable: true,
}

const saved: UseToastOptions = {
  status: "success",
  title: "Saved",
  description: "Changes successfully saved"
}

const created: UseToastOptions = {
  status: "success",
  title: "Created",
  description: "Resource created correctly"
}

const success: UseToastOptions = {
  status: "success",
  title: "Success",
}

const updated: UseToastOptions = {
  title: "Updated correctly",
  status: "success",
}

const removed: UseToastOptions = {
  description: `Removed correctly`,
  title: 'Success',
  status: 'info',
}

const archived: UseToastOptions = {
  description: `Archived correctly`,
  title: 'Success',
  status: 'info',
}

const custom = (data: any) => {
  const { title, description, status } = data;

  return {
    status: status || 'success',
    title: title || 'Success',
    description: description
  }
}

class Props {
  downloading: any;
  processing: any;
  processingLong: any;
  created: any;
  updated: any;
  removed: any;
  error: any;
  invalid: any;
  saved: any;
  success: any;
  custom: any;
  archived: any;
}

const error = {
  title: "Oops! an error occurred",
  description: "The request was not processed",
  status: "error",
}

export const toastTypes: Props = {
  'archived': archived,
  'downloading': downloading,
  'removed': removed,
  'processing': processing,
  'processingLong': processingLong,
  'updated': updated,
  'created': created,
  'error': error,
  'success': success,
  'invalid': invalid,
  'saved': saved,
  'custom': custom
}