import { LOADING, UPDATE_ALL, UPDATE_BY_ID, UPDATE_MEMBERS, UPDATE_ALL_ORG_ACCESS, UPDATE_ORG_ACCESS_BY_ID } from 'services/redux/organisation/organisation.type'

const initialState = {
  organisations: [],
  organisationsById: {},
  organisationsAccessById: {},
  membersByOrg: {},
  myAccess: {},
  updatedAt: new Date(),
  loading: false,
}

// type stateType = keyof typeof initialState;

export const organisationReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case UPDATE_MEMBERS:
      return {
        ...state,
        membersByOrg: {
          ...state.membersByOrg,
          [action.orgId]: action.payload
        },
        loading: false
      };

    case UPDATE_ALL:
      return {
        ...state,
        organisations: action.payload.data,
        organisationsById: action.payload.organisationsById,
        updatedAt: new Date(),
        loading: false,
      };

    case UPDATE_ALL_ORG_ACCESS:
      return {
        ...state,
        myAccess: action.payload.data,
        loading: false
      }

    case UPDATE_ORG_ACCESS_BY_ID:
      return {
        ...state,
        organisationsAccessById: {
          ...state.organisationsAccessById,
          [action.id]: action.payload,
        },
        loading: false
      }

    case UPDATE_BY_ID:
      return {
        ...state,
        organisations: action.organisations,
        organisationsById: {
          ...state.organisationsById,
          [action.id]: action.payload,
        },
        loading: false
      }

    case LOADING:
      return {
        ...state,
        loading: true
      }

    default:
      return state;
  }
};
