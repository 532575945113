import { LOADING, UPDATE_ALL, UPDATE_BY_ID, LOADING_SITE, UPDATE_ALL_SITES_BY_PLACE, UPDATE_SITES_BY_PLACE, } from 'services/redux/place/place.type'

const initialState = {
  places: [],
  placesById: {},
  loading: false,
  updatedAt: new Date(),

  sitesById: {},
  sitesByPlace: {},
  loadingSites: false,
  updatedAtSites: new Date(),
}

// type stateType = keyof typeof initialState;

export const placeReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case UPDATE_ALL:
      return {
        ...state,
        places: action.payload.data,
        placesById: action.payload.placesById,
        updatedAt: new Date(),
        loading: false,
      };

    case UPDATE_BY_ID:
      return {
        ...state,
        loading: false,
        places: [...action.places],
        placesById: {
          ...state.placesById,
          [action.id]: action.payload
        }
      }

    case LOADING:
      return {
        ...state,
        loading: true
      }

    case LOADING_SITE:
      return {
        ...state,
        loadingSites: true
      }

    case UPDATE_ALL_SITES_BY_PLACE:
      return {
        ...state,
        sitesByPlace: {
          ...state.sitesByPlace,
          [action.placeId]: action.payload.data
        },
        sitesById: {
          ...state.sitesById,
          ...action.payload.sitesById
        },
        updatedAtSites: new Date(),
        loadingSites: false,
      };

    case UPDATE_SITES_BY_PLACE:
      return {
        ...state,
        loadingSites: false,
        sitesByPlace: {
          ...state.sitesByPlace,
          [action.placeId]: action.payload
        },
        sitesById: {
          ...state.sitesById,
          [action.siteId]: action.siteData
        }
      }

    default:
      return state;
  }
};
