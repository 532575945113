import React from 'react';
import { Box } from '@chakra-ui/react';

function BoxResponsiveTwo({ children, boxA, boxB }: any) {

  if (boxB === undefined || boxB === 0) {
    return (
      <Box mt="20px" mb="20px" display='grid' gridGap='0px' gridAutoFlow='dense' gridTemplateColumns='repeat(12, [col-start] 1fr)'>
        <Box
          flexDirection='column'
          display='flex'
          gridColumn={[
            `col-start / span 12`,
            `col-start / span 12`,
            `col-start / span ${boxA}`,
            `col-start / span ${boxA}`
          ]}
          gridRow={['2', '2', '1', '1']}
        >
          {children[0] ? children[0] : children}
        </Box>
      </Box>
    )
  }

  return (
    <Box mt="20px" mb="20px" display='grid' gridGap='20px' gridAutoFlow='dense' gridTemplateColumns='repeat(12, [col-start] 1fr)'>
      <Box
        flexDirection='column'
        display='flex'
        gridColumn={[
          `col-start / span 12`,
          `col-start / span 12`,
          `col-start / span ${boxA}`,
          `col-start / span ${boxA}`
        ]}
        gridRow={['2', '2', '1', '1']}
      >
        {children[0] ? children[0] : children}
      </Box>
      <Box
        flexDirection='column'
        display='flex'
        gridColumn={[
          `col-start / span 12`,
          `col-start / span 12`,
          `col-start ${boxA + 1}/ span ${boxB}`,
          `col-start ${boxA + 1} / span ${boxB}`
        ]}
        gridRow='1'
      >
        {children[1] ? children[1] : <></>}
      </Box>
    </Box>
  );
}

export default BoxResponsiveTwo;