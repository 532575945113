import { UPDATE_ALL, LOADING } from './user.type'
import { ApiKs } from 'services/api/API';

export const getAllUsers = (cache: boolean = false, callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });
  ApiKs().apiPrivate.users.list().then((res: any) => {

    const usersById: any = {}
    res.data.data.map((proj: any) => {
      return usersById[proj.id] = proj;
    })

    dispatch({
      type: UPDATE_ALL,
      payload: { data: res.data.data, usersById: usersById }
    })
  })
}