const PREFIX = 'project_'
export const LOADING = PREFIX + 'LOADING'
export const UPDATE_ALL = PREFIX + 'UPDATE_ALL'
export const UPDATE_BY_ID = PREFIX + 'UPDATE_BY_ID'

const PREFIX_COL = 'project_groups_'
export const LOADING_COLLECT = PREFIX_COL + 'LOADING'
export const UPDATE_ALL_COLLECT = PREFIX_COL + 'UPDATE_ALL'
export const UPDATE_BY_ID_COLLECT = PREFIX_COL + 'UPDATE_BY_ID'

const PREFIX_DOC = 'project_documents_'
export const LOADING_DOCUMENTS = PREFIX_DOC + 'LOADING'
export const UPDATE_ALL_DOCUMENTS_BY_PROJ = PREFIX_DOC + 'UPDATE_ALL'
export const UPDATE_BY_ID_DOCUMENT = PREFIX_DOC + 'UPDATE_BY_ID'