import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/number-input';
import { Box, Icon, InputGroup, InputLeftAddon, Skeleton, Stack } from '@chakra-ui/react';
import { projectStatusMultiSelect } from 'helpers/kshub';
import { daysExtObj } from 'helpers/mini';
import SelectMultiple, { CreatableSelect } from 'pages/Components/generic/SelectMultiple';
import React from 'react';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from 'react-icons/ri';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';

export function ChannelSelector({ value, setValue }: any) {

  const { data: { organisationsLoading, organisationsAdminForMultiSelect } } = useOrganisationHook();

  return (
    <Box maxWidth='500px'>
      {!organisationsLoading
        &&
        <SelectMultiple
          isMulti
          isDisabled={organisationsLoading}
          defaultValue={organisationsAdminForMultiSelect.filter((org: any) => {
            if (org.value && (value.includes(org.value))) return true
            return false
          })}
          options={organisationsAdminForMultiSelect}
          onChange={(organisationsId: any) => {
            setValue && setValue((draft: any) => {
              draft.channels = organisationsId.map((orgData: any) => orgData.value)
            })
          }}
          placeholder="Select channels..."
          closeMenuOnSelect={false}
        />
      }
      {
        organisationsLoading &&
        <Skeleton height='30px' />
      }
    </Box>
  );
}

export function DeliverySelector({ value, setValue }: any) {

  return (
    <Box maxWidth={200} cursor='pointer'>
      <Stack
        direction="row"
        onClick={() => {
          setValue && setValue((draft: any) => {
            draft.active = !value
          })
        }}
      >
        <Icon
          cursor='pointer'
          as={value === true ? RiCheckboxCircleLine : RiCheckboxBlankCircleLine}
          boxSize='2em'
          color='green'
          marginTop={1}
        />
      </Stack>
    </Box>
  );
}

export function DaysSelector({ value, setValue }: any) {
  return (
    <Box maxWidth='400px'>
      <SelectMultiple
        isMulti
        options={daysExtObj}
        closeMenuOnSelect={false}
        defaultValue={daysExtObj.filter((dayData: any) => {
          if (value && value.includes(dayData.value)) return true
          return false
        })}
        onChange={(daysSelected: Array<any>) => {
          setValue && setValue((draft: any) => {
            draft.runDays = daysSelected.map((dayData: any) => dayData.value)
          })
        }
        }
      />
    </Box>
  );
}

export function TimeSelector({ value, setValue }: any) {
  return (
    <Box display='inline-block' maxWidth='200px' mr={4}>
      <InputGroup>
        <InputLeftAddon children="HS" />
        <NumberInput
          borderRadius='0px'
          defaultValue={0}
          min={-1}
          max={23}
          clampValueOnBlur={false}
          value={value}
          onChange={(valueString) => {
            let val = parseInt(valueString);
            if (!val) {
              val = 0
            } else if (val > 23) {
              val = 23
            } else if (val < 0 || val === 0) {
              val = 1
            }
            setValue && setValue((draft: any) => {
              draft.runHour = val
            })
          }}
        >
          <NumberInputField borderRadius='0px' />
          <NumberInputStepper >
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </InputGroup>
    </Box>
  );
}

export function ProjectStatusFilterSelector({ value, setValue }: any) {

  return (
    <Box maxWidth='500px'>
      <SelectMultiple
        isMulti
        options={projectStatusMultiSelect}
        placeholder="Select status filters..."
        closeMenuOnSelect={false}
        defaultValue={projectStatusMultiSelect.filter((statusData: any) => {
          if (value && value.includes(statusData.value)) return true
          return false
        })}
        onChange={(statusSelected: Array<any>) => {
          setValue && setValue((draft: any) => {
            draft.projectStatusFilter = statusSelected.map((dayData: any) => dayData.value)
          })
        }}
      />
    </Box>
  );
}

export function RecipentSelector({ value, setValue }: any) {

  return (
    <Box maxWidth='500px'>
      <CreatableSelect
        isMulti
        allowAdditions
        placeholder="Write to create a recipent..."
        closeMenuOnSelect={false}
        defaultValue={value.map((email: string) => {
          return { label: email, value: email }
        })}
        onChange={(emailsSelected: Array<any>) => {
          setValue && setValue((draft: any) => {
            draft.notifEmail = emailsSelected.map((emailData: any) => emailData.value)
          })
        }}
      />
    </Box>
  );
}