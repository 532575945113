import React from 'react';
import { Box, Tabs, TabList, TabPanel, TabPanels, Tab } from '@chakra-ui/react'
import ApiDebugger from './API'
import UIDemoHome from './UIDemo/Home';

function Debugger() {

  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>
            API
          </Tab>
          <Tab>
            React Context
          </Tab>
          <Tab>
            React Redux
          </Tab>
          <Tab>
            UI Demo
          </Tab>
        </TabList>

        <TabPanels paddingTop="2em">
          <TabPanel>
            <ApiDebugger />
          </TabPanel>
          <TabPanel>

          </TabPanel>
          <TabPanel>

          </TabPanel>
          <TabPanel>
            <UIDemoHome />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Debugger;