import { Heading, Image, useColorMode, Box, Divider, Center, Button } from '@chakra-ui/react';
import CenterBox from 'pages/Components/generic/CenterBox';
import BoxResponsiveTwo from 'pages/Components/layout/Box/BoxResponsiveTwo';
import React from 'react';
import logo from 'assets/images/logo.png'
import logo_white from 'assets/images/logo_wht.png'
import { Link } from 'react-router-dom';

function Home() {

  const { colorMode } = useColorMode()

  return (
    <div>
      <CenterBox noBorder>
        <BoxResponsiveTwo boxA={12}>
          <CenterBox fullWidth>
            <Box pb={2} pt={2}>
              {
                colorMode === 'dark' ?
                  <Image src={logo_white} width='80px' float='right' /> :
                  <Image src={logo} width='80px' float='right' />
              }
              <Heading size='4xl'> Welcome to Kitspace Hub </Heading>
              <Heading size='2xl' mt={4} ml={2} color='gray.400'>
                An agile platform for design & build
              </Heading>
            </Box>
          </CenterBox>
        </BoxResponsiveTwo>
        <BoxResponsiveTwo boxA={8} boxB={4}>
          <CenterBox withHover fullWidth as={Link} to='/app/projects'>
            <Heading> Projects </Heading>
          </CenterBox>
          <CenterBox withHover fullWidth as={Link} to='/app/insights/reports'>
            <Heading> Reports </Heading>
          </CenterBox>
        </BoxResponsiveTwo>
        <BoxResponsiveTwo boxA={6} boxB={6}>
          <CenterBox withHover fullWidth as={Link} to='/app/organisations'>
            <Heading> Organizations </Heading>
          </CenterBox>
          <CenterBox withHover fullWidth as={Link} to='/app/places'>
            <Heading> Places </Heading>
          </CenterBox>
        </BoxResponsiveTwo>

        <Divider mt={14} mb={9} />
        <Center>
          <Button mb={5}> Coming Soon </Button>
        </Center>

        <BoxResponsiveTwo boxA={5} boxB={7}>
          <CenterBox fullWidth>
            <Heading color='gray.400'> People </Heading>
          </CenterBox>
          <CenterBox fullWidth>
            <Heading color='gray.400'> Channels </Heading>
          </CenterBox>
        </BoxResponsiveTwo>
        <BoxResponsiveTwo boxA={6} boxB={6}>
          <CenterBox fullWidth>
            <Heading color='gray.400'> Products </Heading>
          </CenterBox>
          <CenterBox fullWidth>
            <Heading color='gray.400'> Markets </Heading>
          </CenterBox>
        </BoxResponsiveTwo>
      </CenterBox>
    </div>
  );
}

export default Home;