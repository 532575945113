/* eslint-disable @typescript-eslint/no-unused-vars */
import { cleanDocNative } from 'helpers/kshub';
import { ApiKs } from 'services/api/API';
import { LOADING, UPDATE_ALL, UPDATE_BY_ID, LOG_GROUPS_UPDATE_ALL, LOGS_BY_GROUP_UPDATE_ALL } from 'services/redux/scheduledtasks/scheduledtasks.type'

export const getLogsGroups = (callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiSuper.scheduledtasks.listLogsGroups().then((res: any) => {

    dispatch({
      type: LOG_GROUPS_UPDATE_ALL,
      payload: { logGroups: res.data.data, qty: res.data.data.length }
    })

    callback()
  }).catch((err) => {
    console.error(err)
    dispatch({
      type: LOG_GROUPS_UPDATE_ALL,
      payload: { logGroups: [], qty: 0 }
    })
    callback()
  })
}

export const getLogsByGroup = (groupId: string, callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiSuper.scheduledtasks.getLogsByGroup(groupId).then((res: any) => {

    dispatch({
      type: LOGS_BY_GROUP_UPDATE_ALL,
      id: groupId,
      payload: res.data.data,
    })

    callback()
  }).catch(() => {
    dispatch({
      type: LOGS_BY_GROUP_UPDATE_ALL,
      payload: { logGroups: [], qty: 0 }
    })
  })
}

export const getScheduledTasks = (callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiSuper.scheduledtasks.list().then((res: any) => {

    const scheduledtasksById: any = {}
    res.data.data.map((task: any) => {
      return scheduledtasksById[task.id] = task;
    })

    dispatch({
      type: UPDATE_ALL,
      payload: { scheduledtasksById: scheduledtasksById, qty: res.data.data.length }
    })

    callback()
  }).catch(() => {
    dispatch({
      type: UPDATE_ALL,
      payload: { scheduledtasksById: {}, qty: 0 }
    })
  })
}

export const createScheduledTasks = (payload: any, callback: () => void = () => { }) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });
}

export const updateScheduledTasks = (taskId: string, payload: any, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiSuper.scheduledtasks.update(taskId, payload).then((res: any) => {

    const { scheduledtasksReducer }: any = getState();

    dispatch({
      type: UPDATE_BY_ID,
      id: taskId,
      payload: {
        ...scheduledtasksReducer.scheduledtasksById[taskId],
        ...payload
      }
    })

    callback(true)
  }).catch(() => {
    callback(false)
  })
}