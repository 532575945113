import React from 'react';
import { Button, ButtonGroup, Icon, Tooltip, IconButton, Menu, MenuButton, MenuList, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { RepeatIcon } from '@chakra-ui/icons';
import { RiMore2Fill, RiInformationLine } from 'react-icons/ri'
import { useIntl } from 'react-intl';

const SHADOW = "md"

function Success({ ...props }) {
  return (
    <Button  {...props} colorScheme="green" boxShadow={SHADOW}>
      {props.children || props.text || 'CONFIRM SUCCESS'}
    </Button>
  )
}

function Default({ children, ...props }: any) {
  return (
    <Button boxShadow={SHADOW} {...props}>
      {children || props.text || 'PRIMARY'}
    </Button>
  )
}

function Primary({ ...props }) {
  return (
    <Button variant='ks-primary' boxShadow={SHADOW}  {...props}>
      {props.children || props.text || 'PRIMARY'}
    </Button>
  )
}

function Warning({ ...props }) {
  return (
    <Button colorScheme='orange' boxShadow={SHADOW}  {...props}>
      {props.children || props.text || 'PRIMARY'}
    </Button>
  )
}

function Danger({ ...props }) {
  return (
    <Button {...props} variant='ks-danger' boxShadow={SHADOW} >
      {props.children || props.text || 'DANGER'}
    </Button>
  )
}

function DangerPopOver({ callback, ...props }) {

  const [isOpen, setIsOpen] = React.useState(false)
  const close = () => setIsOpen(false)
  const open = () => setIsOpen(true)

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={close}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          variant='ks-danger'
          boxShadow={SHADOW}
          onClick={open}
          {...props}>
          {props.children || props.text || 'DANGER'}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">Confirmation</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          Are you sure you want to continue with your action?
        </PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={close}>Cancel</Button>
            <Button colorScheme="red" onClick={() => {
              close()
              if (callback) callback()
            }}>Yes</Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

function Create({ ...props }: any) {

  let more: any = props;

  if (props.to && !props.isDisabled) {
    more.as = Link
  }

  return (
    <Button variant='ks-primary' boxShadow={SHADOW} {...more}> {props.text ? props.text : 'Create'} </Button>
  );
}

function Refresh({ ...props }: any) {
  return (
    <Tooltip label='Refresh' fontSize="md">
      <IconButton
        variant='ks-primary'
        aria-label="Refresh Data"
        icon={<Icon as={RepeatIcon} />}
        borderRadius='10px'
        boxShadow={SHADOW}
        {...props}
      />
    </Tooltip>
  );
}

function SaveChanges({ shortVersion, ...props }: any) {
  const intl = useIntl()

  return (
    <Button colorScheme="green" boxShadow={SHADOW} {...props}>
      {shortVersion ?
        'Save'
        :
        intl
          .formatMessage(
            {
              id: 'ks.app.buttons.savechanges'
            }
          )
      }
    </Button>
  );
}

function ConfirmAction({ ...props }: any) {
  return (
    <Button loadingText="Submitting" boxShadow={SHADOW} {...props}>
      Confirm
    </Button>
  );
}

function Cancel({ ...props }: any) {

  const intl = useIntl()
  let more: any = props;

  if (props.to) {
    more.as = Link
  }

  return (
    <Button variant='ks-danger' boxShadow={SHADOW} {...props}>
      {intl
        .formatMessage(
          {
            id: 'ks.app.buttons.cancel'
          }
        )
      }
    </Button>
  );
}

function Close({ ...props }: any) {

  const intl = useIntl()
  let more: any = props;

  if (props.to) {
    more.as = Link
  }

  return (
    <Button {...props} boxShadow={SHADOW}>
      {intl
        .formatMessage(
          {
            id: 'ks.app.buttons.close'
          }
        )
      }
    </Button>
  );
}

function colorByIndex(index: any, defaultX: boolean = true) {

  let bgColor = defaultX ? 'gray.100' : 'white';
  bgColor = index % 2 ? 'gray.100' : 'white'
  if (index === 0) bgColor = 'white'

  return bgColor
}

function OptionsMenu({ ...props }: any) {

  return (
    <Menu>
      <MenuButton
        boxShadow={SHADOW}
        as={IconButton}
        aria-label="Options"
        borderRadius='10px'
        bgColor={colorByIndex(props.index, props.default)}
        icon={<Icon as={RiMore2Fill} color='black' />}
        {...props}
      />
      <MenuList>
        {props.children}
      </MenuList>
    </Menu>
  )
}

function IconCustom({ icon, tooltip, ...props }: any) {

  if (tooltip) {
    return (
      <Tooltip label={tooltip} isDisabled={!tooltip} fontSize="md">
        <IconButton
          boxShadow={SHADOW}
          aria-label="Button with Icon"
          icon={icon}
          borderRadius='10px'
          {...props}
        />
      </Tooltip>
    )
  }

  return <IconButton
    boxShadow={SHADOW}
    aria-label="Button with Icon"
    icon={icon}
    borderRadius='10px'
    {...props}
  />
}

function IconInfo({ ...props }: any) {

  // Index is for change the color in tables with two colours of rows
  let bgColor = props.default ? 'gray.100' : 'white';

  if (props.index) {
    bgColor = props.index % 2 ? 'gray.100' : 'white'
    if (props.index === 0) bgColor = 'white'
  }

  return (
    <IconButton
      boxShadow={SHADOW}
      bgColor={bgColor}
      aria-label="More Information"
      icon={<Icon as={RiInformationLine} />}
      color='black'
      borderRadius='10px'
      {...props}
    />
  )
}

const ButtonKs = {
  OptionsMenu,
  Default,
  Success,
  Primary,
  Warning,
  Danger,
  DangerPopOver,
  Create,
  IconCustom,
  IconInfo,
  Refresh,
  SaveChanges,
  ConfirmAction,
  Cancel,
  Close
}

export {
  ButtonKs,
  colorByIndex
}