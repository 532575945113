import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/accordion';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Spacer, Grid, GridItem, Text, Divider, Heading } from '@chakra-ui/layout';
import { Badge } from '@chakra-ui/react'
import { Skeleton } from '@chakra-ui/skeleton';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import DateBadge from 'pages/Components/generic/Time/DateBadge';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'services/redux/actions';

function AccordionChild({ groupData }: any) {

  const dispatch = useDispatch()
  const stateRedux = useSelector((store: any) => store.scheduledtasksReducer);
  const [loading, setLoading] = useState(true)

  const cccc = (info: any) => {
    if (loading === true) {
      dispatch(actions.scheduledtasks.getLogsByGroup(groupData.id, () => {
        setLoading(false)
      }))
    }
  }

  return (
    <AccordionItem onClick={(info) => cccc(info)}>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {groupData.id}
          </Box>
          <AddIcon fontSize="12px" />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {
          loading ?
            <>
              <Skeleton height={8} mb={1} />
              <Skeleton height={8} mb={1} />
              <Skeleton height={8} mb={1} />
              <Skeleton height={8} mb={1} />
            </>
            :
            <>
              {stateRedux?.logByGroupId[groupData.id]?.map((logItem: any) => {
                return (
                  <>
                    <BoxShadow mb={4} mt={4}>
                      <Heading size='sm'>
                        Execution Sequence <Badge float='right'>{logItem.id}</Badge>
                        <DateBadge seconds={logItem?.createdAt?._seconds} format='complete' withoutP />
                      </Heading>
                      {logItem.logsData.map((logLine: string) => {
                        return (
                          <>
                            <Text mb={4} mt={0} whiteSpace='pre'> {logLine} </Text>
                            <Divider mb={0} mt={0} />
                          </>
                        )
                      })}
                    </BoxShadow>
                  </>
                )
              })}
            </>
        }
      </AccordionPanel>
    </AccordionItem >
  )
}

function LogsGeneral() {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const stateRedux = useSelector((store: any) => store.scheduledtasksReducer);

  const refreshLogs = () => {
    setLoading(true)
    dispatch(actions.scheduledtasks.getLogsGroups(() => {
      setLoading(false)
    }))
  }

  useEffect(() => {
    refreshLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderKs
        title='Logs Scheduled Tasks'
        subtitle='This feature is available only for Super Users'
      >
        <ButtonKs.Refresh
          onClick={refreshLogs}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      <Spacer />
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
        gap={6}
      >
        <GridItem colSpan={4}>
          {
            loading ?
              <>
                <Skeleton height={8} mb={2} />
                <Skeleton height={8} mb={2} />
                <Skeleton height={8} mb={2} />
                <Skeleton height={8} mb={2} />
              </>
              :
              <Accordion allowToggle>
                {
                  stateRedux.logGroups.map((groupData: any, key: number) =>
                    <AccordionChild groupData={groupData} key={key} />
                  )
                }
              </Accordion>
          }
        </GridItem>
      </Grid>
    </Box>
  );
}

export default LogsGeneral;