import { combineReducers } from 'redux';
import { projectReducer } from './project/project.reducer'
import { organisationReducer } from './organisation/organisation.reducer';
import { userReducer } from './user/user.reducer';
import { placeReducer } from './place/place.reducer'
import { scheduledtasksReducer } from './scheduledtasks/scheduledtasks.reducer'
import { insightsReducer } from './insights/insights.reducer';

export default combineReducers({
  organisationReducer,
  projectReducer,
  userReducer,
  scheduledtasksReducer,
  placeReducer,
  insightsReducer
});
