import { VStack, Stack, Box, Grid, GridItem, Spinner, Text, SimpleGrid, Skeleton, Divider } from '@chakra-ui/react';
import React from 'react';

function LoadingTable({ columns, msg }: any) {

  const spread = Array.from(new Array(columns).keys())
  return (
    <SimpleGrid columns={1} spacing={10} mt={6}>
      <Box>
        <SimpleGrid columns={columns} spacing={6}>
          {
            spread.map((key: number) => {
              return (
                <Stack key={key}>
                  <Skeleton height="40px" />
                </Stack>
              )
            })
          }
        </SimpleGrid>

        <Divider mt={4} mb={5} />

        <SimpleGrid columns={columns} spacing={6}>
          {spread.map((key: number) => {
            return (
              <Box key={key}>
                <Stack>
                  <Skeleton height="40px" />
                  <Skeleton height="40px" />
                  <Skeleton height="40px" />
                  <Skeleton height="40px" />
                  <Skeleton height="40px" />
                  <Skeleton height="40px" />
                </Stack>
              </Box>
            )
          })}
        </SimpleGrid>

        <Divider mt={6} mb={6} />
        <VStack spacing={8}>
          <Box margin='auto'>
            <Grid
              templateColumns="repeat(1, 1fr)"
              gap={1}
            >
              <GridItem colSpan={1} textAlign="center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Text>
                  {msg ? msg : 'Loading Table'}
                </Text>
              </GridItem>
            </Grid>
          </Box>
        </VStack>
      </Box >
    </SimpleGrid >
  );
}

export default LoadingTable;