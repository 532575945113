import { LOADING, UPDATE_ALL, UPDATE_BY_ID, UPDATE_MEMBERS, UPDATE_ALL_ORG_ACCESS, UPDATE_ORG_ACCESS_BY_ID } from 'services/redux/organisation/organisation.type'
import { ApiKs } from 'services/api/API';
import { cleanDocNative } from 'helpers/kshub';
import { patchEntitiesCollection, patchNewEntityCollection } from '../helper';

export const getOrganisations = (cache: boolean = false, callback: any = null) => async (dispatch: any, getState: any) => {

  dispatch({ type: LOADING });

  const { organisationReducer }: any = getState();

  if (cache && organisationReducer.organisations.length > 0 && organisationReducer.myAccess) {

    dispatch({
      type: UPDATE_ALL_ORG_ACCESS,
      payload: { data: organisationReducer.myAccess }
    })

    dispatch({
      type: UPDATE_ALL,
      payload: { data: organisationReducer.organisations, organisationsById: organisationReducer.organisationsById }
    })

    callback && callback()
  } else {
    ApiKs().apiPrivate.organisations.list().then((res: any) => {

      const orgsById: any = {}
      res.data.data.organisations.map((org: any) => {
        return orgsById[org.id] = org;
      })

      dispatch({
        type: UPDATE_ALL_ORG_ACCESS,
        payload: { data: res.data.data.myAccess }
      })

      dispatch({
        type: UPDATE_ALL,
        payload: { data: res.data.data.organisations, organisationsById: orgsById }
      })

      callback && callback()
    })
  }
}

export const getMembersByOrgAndSyncInvitations = (orgId: string, cache: boolean = false, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiPrivate.organisations.getMembersAndSyncInvitations(orgId).then((res: any) => {
    dispatch({
      type: UPDATE_MEMBERS,
      orgId: orgId,
      payload: res.data.data
    })
    callback && callback(true, res)
  }).catch((err: any) => {
    callback && callback(false, err)
  });
}

export const getMembersByOrg = (orgId: string, cache: boolean = false, callback: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });
  const { organisationReducer }: any = getState();

  if (cache && organisationReducer.membersByOrg[orgId]) {
    dispatch({
      type: UPDATE_MEMBERS,
      payload: organisationReducer.membersByOrg[orgId]
    })
  } else {
    ApiKs().apiPrivate.organisations.getMembers(orgId).then((res: any) => {
      dispatch({
        type: UPDATE_MEMBERS,
        orgId: orgId,
        payload: res.data.data
      })
      callback && callback(true, res)
    }).catch((err: any) => {
      callback && callback(false, err)
    });
  }
}

export const getOrganisationById = (orgId: string, cache: boolean = false, callback: any) => async (dispatch: any, getState: any) => {
  const { organisationReducer }: any = getState();
  dispatch({ type: LOADING });

  if (cache && organisationReducer.organisationsById[orgId]) {
    dispatch({
      type: UPDATE_BY_ID,
      id: orgId,
      organisations: patchEntitiesCollection(orgId, organisationReducer.organisationsById[orgId], organisationReducer.organisations),
      payload: organisationReducer.organisationsById[orgId]
    })
  } else {
    ApiKs().apiPrivate.organisations.get(orgId).then((res: any) => {
      if (res.data?.id) {
        dispatch({
          type: UPDATE_BY_ID,
          id: res.data.id,
          organisations: patchEntitiesCollection(res.data.id, res.data.data, organisationReducer.organisations),
          payload: res.data.data
        })

        dispatch({
          type: UPDATE_ORG_ACCESS_BY_ID,
          id: res.data.id,
          payload: res.data.orgAccess
        })
      }
    })
  }
}

export const updateOrganisation = (orgId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { organisationReducer }: any = getState();

  const payloadClean = { ...payload }
  delete payloadClean.organisation_access
  delete payloadClean.placesRelated

  ApiKs().apiPrivate.organisations.update(orgId, cleanDocNative(payloadClean)).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      id: orgId,
      organisations: patchEntitiesCollection(orgId, payload, organisationReducer.organisations),
      payload: payload
    })
    callback(res.data.status, res.data.info)
  }).catch((err: any) => {
    console.error(err)
    callback(false, err)
  })
}

export const updateState = (orgId: string, newState: string) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });
  const { organisationReducer }: any = getState();
  const data = { ...organisationReducer.organisationsById[orgId], state: newState }

  ApiKs().apiPrivate.organisations.updateState(orgId, newState).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      id: orgId,
      organisations: patchEntitiesCollection(orgId, data, organisationReducer.organisations),
      payload: data
    })
  }).catch((err: any) => {

  })
}

export const createOrganisation = (payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  const { organisationReducer }: any = getState();

  ApiKs().apiPrivate.organisations.create(payload).then((res: any) => {
    dispatch({
      type: UPDATE_BY_ID,
      organisations: patchNewEntityCollection(res.data.data.orgId, payload, organisationReducer.organisations),
      id: res.data.data.orgId,
      payload: { ...res.data.data }
    })
    callback(true, res)
  }).catch((err: any) => {
    callback(false, err)
  });

}

export const addMembers = (orgId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiPrivate.organisations.addMembers(orgId, payload).then((res: any) => {
    dispatch({
      type: UPDATE_MEMBERS,
      orgId: orgId,
      payload: res.data.data
    })
    callback(true, res)
  }).catch((err: any) => {
    callback(false, err)
  });

}

export const removeMembers = (orgId: string, payload: any, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiPrivate.organisations.removeMembers(orgId, payload).then((res: any) => {
    dispatch({
      type: UPDATE_MEMBERS,
      orgId: orgId,
      payload: res.data.data
    })
    callback(true, res)
  }).catch((err: any) => {
    callback(false, err)
  });

}

export const switchAdminAccess = (orgId: string, status: boolean, uid: string, callback: any) => (dispatch: any, getState: any) => {
  dispatch({ type: LOADING });

  ApiKs().apiPrivate.organisations.switchAdminAccess(orgId, { uid: uid, status: status }).then((res: any) => {
    dispatch({
      type: UPDATE_MEMBERS,
      orgId: orgId,
      payload: res.data.data
    })
    callback(true, res)
  }).catch((err: any) => {
    callback(false, err)
  });
}

export const updatePartners = (orgId: string, partners: Array<string>, callback: any) => (dispatch: any, getState: any) => {
  ApiKs().apiPrivate.organisations.updatePartners(orgId, partners).then((res: any) => {
    callback(true, res)
  }).catch((err: any) => {
    callback(false, err)
  });
}

export const updatePlacesRelated = (orgId: string, places: Array<string>, callback: any) => (dispatch: any, getState: any) => {
  ApiKs().apiPrivate.organisations.updatePlacesRelated(orgId, places).then((res: any) => {
    callback(true, res)
  }).catch((err: any) => {
    callback(false, err)
  });
}