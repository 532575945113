import { LOADING, UPDATE_ALL, UPDATE_BY_ID, LOG_GROUPS_UPDATE_ALL, LOGS_BY_GROUP_UPDATE_ALL } from 'services/redux/scheduledtasks/scheduledtasks.type'

const initialState = {
  scheduledtasksById: {},
  scheduledtasksQty: 0,
  logGroups: [],
  logByGroupId: {},
  loading: false,
  updatedAt: new Date(),
}

export const scheduledtasksReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case UPDATE_ALL:
      return {
        ...state,
        loading: false,
        scheduledtasksQty: action.qty,
        scheduledtasksById: action.payload.scheduledtasksById,
        updatedAt: new Date(),
      };

    case LOGS_BY_GROUP_UPDATE_ALL:
      return {
        ...state,
        loading: false,
        logByGroupId: {
          ...state.logByGroupId,
          [action.id]: action.payload
        },
      };

    case LOG_GROUPS_UPDATE_ALL:
      return {
        ...state,
        loading: false,
        logGroups: action.payload.logGroups,
      };

    case UPDATE_BY_ID:
      return {
        ...state,
        loading: false,
        scheduledtasksById: {
          ...state.scheduledtasksById,
          [action.id]: action.payload
        }
      }

    case LOADING:
      return {
        ...state,
        loading: true
      }

    default:
      return state;
  }
};
