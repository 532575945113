import { Button, Checkbox, HStack, Box, Text, GridItem, Grid, Divider } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ApiKs } from 'services/api/API';
import ReactJson from 'react-json-view'

function RequestDemo({ endpoint, description, setToken }: any) {

  const { data } = ApiKs();

  const [config, setConfig]: any = useState('');
  const [requestResult, setRequestResult] = useState({});
  const [requestTestSimple, setRequestTestSimple] = useState(false);
  const [loading, setLoading] = useState(false)

  const runRequestTest = () => {
    setLoading(true)
    endpoint().then((res: any) => {
      let value = requestTestSimple ? res : res.data

      console.info(value)
      setRequestResult(value)
      setLoading(false)
      setToken(res?.config?.headers?.Authorization || '')
      setConfig(res.config)
    }).catch((err: any) => {
      setLoading(false)
      setRequestResult(err)
    })
  }

  return (
    <Box p="2em">
      <Grid gap={2} templateColumns="repeat(2, 1fr)">
        <GridItem h="250">
          <HStack
            spacing={5}
            direction="row"
            mt='1em'>
            <Checkbox
              isChecked={requestTestSimple}
              onChange={() => setRequestTestSimple(!requestTestSimple)}
              size="lg"
            > All </Checkbox>
            <Button
              onClick={runRequestTest}
              loadingText="Request in progress"
              isLoading={loading}
            >
              Request "{description}"
            </Button>
          </HStack>

          <Text size="l" mt='1em'>
            Base URL : {data.baseURL}
          </Text>
          <Divider />
          <Text size="l" mt='1em'>
            URL : {config.baseURL}<b>{config.url}</b>
          </Text>
          <Text size="l">
            Method : <b>{config.method}</b>
          </Text>

        </GridItem>
        <GridItem h="250" border='1px' borderRadius='1em'>
          <ReactJson src={requestResult} style={{ height: 'calc(250px - 2em)', overflow: 'auto', margin: '1em' }} />
        </GridItem>
      </Grid>
    </Box>
  );
}

export default RequestDemo;