import React, { useEffect, useState } from 'react';
import { Box, Tabs, TabList, TabPanel, TabPanels, Tab } from '@chakra-ui/react'
import Users from './Users'
import Invitations from './Invitations';
import Home from './Home';
import { ApiKs } from 'services/api/API';

function SuperUserHome() {

  const { apiSuper } = ApiKs();

  const [dataSafeList, setDataSafeList] = useState();
  const [dataSuperUsers, setDataSuperUsers] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    updateAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAll = () => {
    setLoading(true)
    Promise.all([
      updateDataSafeList(),
      updateSuperUsersList(),
    ]).then((res) => {
      setLoading(false)
    })
  }

  const updateDataSafeList = () => {
    return apiSuper.nousers.public.authorized.get().then((res: any) => {
      setDataSafeList(res.data.data)
    }).catch((err: any) => {
      console.error(err)
    })
  }

  const updateSuperUsersList = () => {
    return apiSuper.nousers.superusers.list().then((res: any) => {
      setDataSuperUsers(res.data.data)
    }).catch(() => {
    })
  }

  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>
            Home
          </Tab>
          <Tab>
            Access and Invitations
          </Tab>
          <Tab>
            Registered users
          </Tab>
        </TabList>

        <TabPanels paddingTop="2em">
          <TabPanel>
            <Home
              loading={loading}
              dataSafeList={dataSafeList}
              updateDataSafeList={updateDataSafeList}
              dataSuperUsers={dataSuperUsers}
              updateSuperUsersList={updateSuperUsersList}
            />
          </TabPanel>
          <TabPanel>
            <Invitations />
          </TabPanel>
          <TabPanel>
            <Users />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default SuperUserHome;