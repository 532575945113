import React, { useState, useEffect } from 'react';
import { BoxShadow } from 'pages/Components/layout/Box/BoxShadow';
import {
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Badge,
  Box,
  useToast,
  Skeleton
} from '@chakra-ui/react';
import { ApiKs } from 'services/api/API';
import { useIntl } from 'react-intl';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { toastTypes } from 'helpers/alerts';

function SafeList({ dataSafeList, updateDataSafeList }: any) {

  const { apiSuper } = ApiKs();
  const intl = useIntl()
  const toast = useToast();

  const [domains, setDomains] = useState('')
  const [emails, setEmails] = useState('')
  const [previewMode, setPreviewMode] = useState(false)
  const [loading, setLoading] = useState(false)

  const [emailsParsed, setEmailsParsed] = useState<Array<string>>([]);
  const [domainsParsed, setDomainsParsed] = useState<Array<string>>([]);

  useEffect(() => {
    if (dataSafeList) {
      if (dataSafeList.special) setEmails(dataSafeList.special.join(', '))
      if (dataSafeList.domains) setDomains(dataSafeList.domains.join(', '))
    }
  }, [dataSafeList]);

  useEffect(() => {
    setDomainsParsed(domains.split(','))
  }, [domains]);

  useEffect(() => {
    setEmailsParsed(emails.split(','))
  }, [emails]);

  const saveChanges = () => {
    setLoading(true)
    apiSuper.nousers.public.authorized.update(emailsParsed, domainsParsed)
      .then((res: any) => {
        if (res.data.status) {
          toast(toastTypes.saved)
        } else {
          toast(toastTypes.error)
        }
        setPreviewMode(false)
        setLoading(false)
      }).catch((err: any) => {
        toast(toastTypes.error)
        console.error(err)
        setLoading(false)
      });
  }

  const refresh = () => {
    setLoading(true);
    updateDataSafeList().then(() => {
      setLoading(false)
    })
  }

  return (
    <Skeleton isLoaded={!loading} borderRadius={15}>
      <BoxShadow>
        <Heading fontSize="xl">
          {
            intl.formatMessage(
              { id: 'ks.superuser.safelist.header' }
            )
          }
        </Heading>
        <Text mt={1} fontSize="sm">
          {
            intl.formatMessage(
              { id: 'ks.superuser.safelist.subtitle' }
            )
          }
        </Text>

        {!previewMode &&
          <>
            <FormControl id="domains" mt={6} mb={4}>
              <FormLabel>
                {intl.formatMessage({ id: 'ks.app.domains' })}
              </FormLabel>
              <Input type="text" value={domains} onChange={(e) => setDomains(e.target.value)} />
              <FormHelperText>
                {intl.formatMessage({ id: 'ks.superuser.safelist.definition_domains' })}
              </FormHelperText>
            </FormControl>

            <FormControl id="email">
              <FormLabel>
                {intl.formatMessage({ id: 'ks.app.email' })}
              </FormLabel>
              <Input type="email" value={emails} onChange={(e) => setEmails(e.target.value)} />
              <FormHelperText>
                {intl.formatMessage({ id: 'ks.superuser.safelist.definition_emails' })}
              </FormHelperText>
            </FormControl>


            <ButtonKs.Refresh
              float="right"
              mt={4}
              onClick={refresh}
            />

            <ButtonKs.ConfirmAction
              mt={4}
              onClick={() => setPreviewMode(!previewMode)}
            />

          </>
        }
        {
          previewMode &&
          <Box mt={6}>

            <Box mb={6}>
              <Heading size="l">
                {emailsParsed.length} | {intl.formatMessage({ id: 'ks.app.email' })}
              </Heading>
              <Text fontSize="xl" fontWeight="bold">
                {emailsParsed.map((email: string, key: number) => {
                  return (
                    <Badge key={key} ml="1" fontSize="0.8em" colorScheme="purple">
                      {email}
                    </Badge>
                  )
                })}
              </Text>
            </Box>

            <Box mb={6}>
              <Heading size="l">
                {domainsParsed.length} | {intl.formatMessage({ id: 'ks.app.domains' })}
              </Heading>
              <Text fontSize="xl" fontWeight="bold">
                {domainsParsed.map((domain: string, key: number) => {
                  return (
                    <Badge key={key} ml="1" fontSize="0.8em" colorScheme="green">
                      {domain}
                    </Badge>
                  )
                })}
              </Text>
            </Box>

            <ButtonKs.Cancel
              mt={4}
              isDisabled={loading}
              onClick={() => setPreviewMode(!previewMode)}
            />

            <ButtonKs.SaveChanges
              mt={4}
              ml={4}
              onClick={saveChanges}
              isLoading={loading}
            />

          </Box>
        }
      </BoxShadow >
    </Skeleton >
  );
}

export default SafeList;