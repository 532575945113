import React, { useState, useEffect } from 'react';
import { Text, Box, Badge, Icon, Select, MenuGroup, MenuItem, Popover, PopoverTrigger, PopoverArrow, PopoverContent, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverFooter, ButtonGroup, Button, Textarea, Tooltip } from '@chakra-ui/react'
import { RiShieldStarFill, RiEditFill, RiCheckFill, RiCloseFill, RiAlertFill, RiLock2Fill, RiHome2Fill } from 'react-icons/ri'
import { Table, Thead, Tbody, Tr, Th, Td } from "pages/Components/generic/ResponsiveTable/table";
import { ArrowDownIcon, ArrowUpIcon, EditIcon, } from '@chakra-ui/icons'
import { projectConfidence, projectPhases, projectStatus, } from 'helpers/kshub';
import { Link, useRouteMatch } from "react-router-dom";
import { useTable, useSortBy } from 'react-table'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import capitalize from 'capitalize';
import { useUser } from 'reactfire';
import { VscLock, VscOrganization } from 'react-icons/vsc'
import { actions } from 'services/redux/actions'
import { useContextUIHook } from 'services/contexts/hooks/useContextUIHook';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import HeaderKs from 'pages/Components/generic/HeaderKs';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import { ButtonKs } from 'pages/Components/generic/Buttons';
import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import pluralize from 'pluralize';

function PopOverComment({ dateFormatted, lastComment, projId, projAccess }: any) {

  const [editMode, setEditMode] = useState(false)
  const [comment, setComment] = useState(lastComment)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const { data: { superUserUI } } = useContextUIHook()
  const user = useUser();

  const saveChanges = () => {
    setLoading(true)
    dispatch(actions.projects.updateProjectLastComment(projId, { lastComment: comment }, () => {
      setLoading(false)
    }))
  }

  if (!projAccess?.includes(user.data.uid) && !superUserUI) {
    return (<> {lastComment ? lastComment : '---'} </>)
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Text noOfLines={3} cursor='pointer'>
          {lastComment ? lastComment : '---'}
        </Text>
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          <Icon
            fontSize="xl"
            color="current"
            mr={1}
            aria-label='Only for super users'
            as={RiShieldStarFill}
          /> Status Comment
        </PopoverHeader>

        <PopoverArrow />
        <PopoverCloseButton />
        {
          !editMode ?

            <PopoverBody onClick={() => setEditMode(!editMode)}>
              {
                lastComment ? lastComment : 'Without comment'
              }
            </PopoverBody>
            :
            <PopoverBody>
              <Textarea
                isDisabled={loading}
                value={comment} onChange={(e) => setComment(e.target.value)} />
            </PopoverBody>
        }
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <Box fontSize="sm">
            {!editMode && lastComment &&
              <>
                Update: {moment.utc(dateFormatted).fromNow()}
              </>}
          </Box>
          <ButtonGroup size="sm">
            {!editMode ?

              <Button colorScheme="blue" leftIcon={<EditIcon />} onClick={() => setEditMode(!editMode)}>
                Update
              </Button>
              :
              <>
                <Button colorScheme="red" onClick={() => setEditMode(!editMode)}>
                  Cancel
                </Button>
                <Button colorScheme="green" onClick={saveChanges}>
                  Save
                </Button>
              </>
            }
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

function PopOverPhase({ children, currentPhase, projId, projAccess }: any) {
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newStatus, setNewStatus] = useState();
  const dispatch = useDispatch();
  const { data: { superUserUI } } = useContextUIHook()
  const user = useUser();

  const saveChanges = () => {
    setLoading(true)
    dispatch(actions.projects.updateProjectPhase(projId, { currentPhase: newStatus }, () => {
      setLoading(false)
    }))
  }

  if (!projAccess?.includes(user.data.uid) && !superUserUI) {
    return (<> {children} </>)
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Box cursor='pointer'>{children}</Box>
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Current Phase
        </PopoverHeader>

        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverBody>
          <Select
            id="currentPhase"
            placeholder="Select a Phase"
            isDisabled={!editMode}
            onChange={(e: any) => {
              setNewStatus(e.target.value)
            }}
          >
            {Object.keys(projectPhases).map((key: string, index: number) => {
              return (
                <option
                  selected={projectPhases[key].value === currentPhase}
                  value={projectPhases[key].value}
                  key={index}>
                  {projectPhases[key].name}
                </option>
              )
            })}
          </Select>
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <Box fontSize="sm">

          </Box>
          <ButtonGroup size="sm">

            <>
              {!editMode ?
                <Button colorScheme="blue" leftIcon={<EditIcon />} onClick={() => setEditMode(!editMode)}>
                  Update
                </Button>
                :
                <>
                  <Button colorScheme="red" onClick={() => setEditMode(!editMode)}>
                    Cancel
                  </Button>
                  <Button isDisabled={!newStatus} isLoading={loading} colorScheme="green" onClick={saveChanges}>
                    Save
                  </Button>
                </>
              }
            </>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

function PopOverStatus({ children, currentStatus, projId, projAccess }: any) {
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newStatus, setNewStatus] = useState();
  const dispatch = useDispatch();
  const { data: { superUserUI } } = useContextUIHook()
  const user = useUser();

  const saveChanges = () => {
    setLoading(true)
    dispatch(actions.projects.updateProjectStatus(projId, { currentStatus: newStatus }, () => {
      setLoading(false)
    }))
  }

  if (!projAccess?.includes(user.data.uid) && !superUserUI) {
    return (<> {children} </>)
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Box cursor='pointer'>{children}</Box>
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Status Project
        </PopoverHeader>

        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverBody>
          <Select
            id="currentStatus"
            placeholder="Select a Status"
            isDisabled={!editMode}
            onChange={(e: any) => {
              setNewStatus(e.target.value)
            }}
          >
            {Object.keys(projectStatus).map((key: string, index: number) => {
              return (
                <option
                  selected={projectStatus[key].value === currentStatus}
                  value={projectStatus[key].value}
                  key={index}>
                  {projectStatus[key].name}
                </option>
              )
            })}
          </Select>
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <Box fontSize="sm">

          </Box>
          <ButtonGroup size="sm">

            <>
              {!editMode ?

                <Button colorScheme="blue" leftIcon={<EditIcon />} onClick={() => setEditMode(!editMode)}>
                  Update
                </Button>
                :
                <>
                  <Button colorScheme="red" onClick={() => setEditMode(!editMode)}>
                    Cancel
                  </Button>
                  <Button isDisabled={!newStatus} isLoading={loading} colorScheme="green" onClick={saveChanges}>
                    Save
                  </Button>
                </>
              }
            </>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

function PopOverConfidence({ children, currentConfidence, projId, projAccess }: any) {
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newConfidence, setNewConfidence] = useState();
  const dispatch = useDispatch();
  const user = useUser();
  const { data: { superUserUI } } = useContextUIHook()


  const saveChanges = () => {
    setLoading(true)
    dispatch(actions.projects.updateProjectConfidence(projId, { confidence: newConfidence }, null))
  }

  if (!projAccess?.includes(user.data.uid) && !superUserUI) {
    return (<> {children} </>)
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Box cursor='pointer'>{children}</Box>
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Confidence level
        </PopoverHeader>

        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverBody>
          <Select
            id="confidence"
            placeholder="Select a confidence level"
            isDisabled={!editMode}
            onChange={(e: any) => {
              setNewConfidence(e.target.value)
            }}
          >
            {Object.keys(projectConfidence).map((key: string, index: number) => {
              return (
                <option
                  value={projectConfidence[key].value}
                  key={index}
                  selected={projectConfidence[key].value === currentConfidence}
                >
                  {projectConfidence[key].name}
                </option>
              )
            })}
          </Select>
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <Box fontSize="sm">

          </Box>
          <ButtonGroup size="sm">

            <>
              {!editMode ?

                <Button colorScheme="blue" leftIcon={<EditIcon />} onClick={() => setEditMode(!editMode)}>
                  Update
                </Button>
                :
                <>
                  <Button colorScheme="red" onClick={() => setEditMode(!editMode)}>
                    Cancel
                  </Button>
                  <Button isLoading={loading} colorScheme="green" onClick={saveChanges}>
                    Save
                  </Button>
                </>
              }
            </>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}


function ProjectsDirectory(props: any) {

  let { path } = useRouteMatch();
  const { data: { superUserUI } } = useContextUIHook()
  const { data: { organisationsLoading, organisationsById } } = useOrganisationHook();
  const user = useUser();

  const stateRedux = useSelector((store: any) => store.projectReducer);
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const updateData = (cache: boolean = false) => {
    dispatch(actions.projects.getProjects(cache))
  }

  useEffect(() => {
    updateData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(stateRedux.loading)
  }, [stateRedux.loading]);

  const refreshTable = () => {
    if (stateRedux.projects && stateRedux.projects.length !== 0) {
      setDataTable(stateRedux.projects.filter((project: any) => {
        if (superUserUI) return true
        if (project &&
          (
            project.project_access.accessRead.includes(user.data.uid) ||
            project.project_access.accessWrite.includes(user.data.uid) ||
            project.projectOwnerUid === user.data.uid
          )
        ) return true
        return false
      }))
    }
  }

  useEffect(() => {
    refreshTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superUserUI, stateRedux.projects]);

  const columns = React.useMemo(
    () => [
      {
        Header: <> <Icon as={RiEditFill} /> Write </>,
        accessor: 'id',
        Cell: (props: any) => {
          const projAccess: any = props.cell.row.original.project_access?.accessWrite;

          return (
            <>
              {(projAccess && projAccess.includes(user.data.uid)) || superUserUI ?
                <Icon as={RiCheckFill} /> :
                <Icon as={RiCloseFill} />}
            </>
          );
        },
      },
      {
        Header: 'Channel',
        accessor: 'channelId',
        Cell: (props: any) => {
          const orgId = props.cell.value;
          return (
            <>
              {orgId ?
                <Text mb={2}>
                  {organisationsById && organisationsById[orgId] ?
                    <>
                      <Icon
                        fontSize="xl"
                        color={'green.400'}
                        mr={2}
                        aria-label='Only for super users'
                        as={VscOrganization}
                      />
                      {organisationsById[orgId].name}
                    </> :
                    <>
                      <Icon
                        fontSize="xl"
                        color={'green.400'}
                        mr={2}
                        aria-label='Private'
                        as={VscLock}
                      />
                      Private
                    </>
                  }
                </Text>
                : <>{orgId}</>
              }
            </>
          );
        },
      },
      {
        Header: 'Project name',
        accessor: 'name',
        Cell: (props: any) => {
          const name = props.cell.value;
          return (
            <Text cursor='pointer'>
              {name}
            </Text>
          );
        },
      },
      {
        Header: 'Target completion',
        accessor: 'dateTargetCompletion',
        Cell: (props: any) => {
          const date = props.cell.value;
          const dateFormatted = date?._seconds ? date._seconds * 1000 : moment(date).unix() * 1000

          return (
            <Text>
              {moment.utc(dateFormatted).format("Do MMM YY")}&nbsp;

              {(
                props.cell.row.original.dateProjectedCompletion._seconds > props.cell.value._seconds ||
                props.cell.row.original.dateProjectedCompletion > props.cell.value
              ) &&
                <Tooltip label="Projected completion is later than target completion" fontSize="md">
                  <Badge colorScheme="red" ml={2} cursor='pointer'>
                    <Icon as={RiAlertFill} boxSize={4} />
                  </Badge>
                </Tooltip>
              }

              <Text color="gray.400" mt={2}>
                ({moment.utc(dateFormatted).fromNow()})
              </Text>
            </Text>
          );
        },
      }, {
        Header: 'Projected completion',
        accessor: 'dateProjectedCompletion',
        Cell: (props: any) => {
          const date = props.cell.value;
          const dateFormatted = date?._seconds ? date._seconds * 1000 : moment(date).unix() * 1000

          return (
            <Text>
              {moment.utc(dateFormatted).format("Do MMM YY")}&nbsp;
              <Text color="gray.400" mt={2}>
                ({moment.utc(dateFormatted).fromNow()})
              </Text>
            </Text>
          );
        },
      },
      {
        Header: 'Organizations',
        accessor: 'organisationsInvolved',
        Cell: (props: any) => {
          const organisationsInvolved = props.cell.value;
          const orgId = props.cell.row.original.orgId;
          const summaryLength = organisationsInvolved.length + (orgId ? 1 : 0)

          return (
            <>
              <Popover>
                <PopoverTrigger>
                  <Box cursor='pointer'>
                    {summaryLength} {pluralize('organization', summaryLength)}
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverHeader pt={4} fontWeight="bold" border="0">
                    Organizations Involved
                  </PopoverHeader>

                  <PopoverArrow />
                  <PopoverCloseButton />

                  <PopoverBody>
                    {orgId &&
                      <Tooltip label="Organization owner">
                        <Text mb={2} cursor='pointer'>
                          <Icon
                            fontSize="xl"
                            color={'blue.400'}
                            mr={2}
                            aria-label='Only for super users'
                            as={VscOrganization}
                          />
                          {organisationsById && organisationsById[orgId] ?
                            organisationsById[orgId].name :
                            orgId
                          }
                        </Text>
                      </Tooltip>
                    }
                    {
                      organisationsInvolved && organisationsInvolved.map((orgId: string) => {
                        return <Text mb={2}>
                          {organisationsById && organisationsById[orgId] ?
                            <>
                              <Icon
                                fontSize="xl"
                                color="current"
                                mr={2}
                                aria-label='Organization'
                                as={VscOrganization}
                              />
                              {organisationsById[orgId].name}
                            </>
                            :
                            <>
                              <Icon
                                fontSize="xl"
                                color="current"
                                mr={2}
                                aria-label='Lock'
                                as={RiLock2Fill}
                              />
                              Organization
                            </>
                          }
                        </Text>
                      })
                    }
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </>
          );
        },
      },
      {
        Header: 'Current Phase',
        accessor: 'currentPhase',
        Cell: (props: any) => {
          const phase: string = props.cell.value;
          return (
            <Text>
              <PopOverPhase
                projId={props.cell.row.original.id || props.cell.row.original.projId}
                projAccess={props.cell.row.original.project_access?.accessWrite}
                currentPhase={phase}>
                <Badge variant="solid">
                  {projectPhases[phase] ? projectPhases[phase].name : phase}
                </Badge>
              </PopOverPhase>
            </Text>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'currentStatus',
        Cell: (props: any) => {
          const status: string = props.cell.value;
          return (
            <Text>
              <PopOverStatus
                projId={props.cell.row.original.id || props.cell.row.original.projId}
                projAccess={props.cell.row.original.project_access?.accessWrite}
                currentStatus={status}>
                <Badge variant="solid" size=''>
                  {projectStatus[status] ? projectStatus[status].name : status}
                </Badge>
              </PopOverStatus>
            </Text>
          );
        },
      },
      {
        Header: 'Situation',
        accessor: 'lastComment',
        Cell: (props: any) => {

          const lastComment: string = props.cell.value;
          const lastCommentDate: any = props.cell.row.original.lastCommentAt;
          const dateFormatted = lastCommentDate?._seconds ? lastCommentDate._seconds * 1000 : moment(lastCommentDate).unix() * 1000

          return (
            <Box maxWidth={200}>
              <PopOverComment
                projAccess={props.cell.row.original.project_access?.accessWrite}
                projId={props.cell.row.original.id || props.cell.row.original.projId}
                lastComment={lastComment}
                dateFormatted={dateFormatted}
              />
            </Box>
          );
        },
      },
      {
        Header: 'Confidence',
        accessor: 'confidence',
        Cell: (props: any) => {
          const confidence: string = props.cell.value;
          return (
            <Text>
              <PopOverConfidence
                projAccess={props.cell.row.original.project_access?.accessWrite}
                projId={props.cell.row.original.id || props.cell.row.original.projId}
                currentConfidence={confidence}>
                <Badge variant="solid" colorScheme="blue">
                  {capitalize(confidence)}
                </Badge>
              </PopOverConfidence>
            </Text>
          );
        },
      },
    ],
    [organisationsById, superUserUI, user.data.uid]
  )

  const initialStateTable: any = {
    columns: columns,
    data: dataTable
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  return (
    <Box>
      <BreadcrumbsKs
        data={
          [
            { name: 'Projects', link: 'projects' },
          ]
        }
      />
      <HeaderKs
        title='Projects Directory'
        subtitle='Projects with access and my projects in draft'
      >
        <ButtonKs.Create
          to={`${path}/new`}
          ml={2}
        />
        <ButtonKs.Refresh
          onClick={() => updateData()}
          ml={2}
          mr={3}
        />
      </HeaderKs>
      {
        !loading && !organisationsLoading &&
        <Box overflow='auto'>
          <Table variant="striped" mt={4}  {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup: any) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <Th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span style={{ marginLeft: '5px' }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownIcon />
                            : <ArrowUpIcon />
                          : ''}
                      </span>
                    </Th>
                  ))}
                  <Th>
                    Options
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {
                rows.map((row: any, index: number) => {
                  prepareRow(row)

                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <Td {...cell.getCellProps()} headerTitle={cell.column.Header}>
                            {cell.render('Cell')}
                          </Td>
                        )
                      })}
                      <Td headerTitle='Options'>
                        <ButtonKs.IconCustom icon={<RiHome2Fill />} m={2} as={Link} to={`${path}/${row.original.id || row.original.projId}/home`} />
                        <ButtonKs.OptionsMenu m={2} bgColor={index % 2 ? 'gray.100' : 'white'}>
                          <MenuGroup title="Options">
                            {(superUserUI === true || user.data.uid === row.original.createdBy) &&
                              <>
                                <MenuItem as={Link} to={`${path}/${row.original.id || row.original.projId}/edit`}> Configuration </MenuItem>
                              </>
                            }
                          </MenuGroup>
                        </ButtonKs.OptionsMenu>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>
      }
      {
        (loading || organisationsLoading) &&
        <LoadingTable columns={10} />
      }
    </Box >
  );
}

export default ProjectsDirectory;