import LoadingTable from 'pages/Components/generic/Loading/LoadingTable';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { ApiKs } from 'services/api/API';
import LogoImg from 'assets/images/kshubLogo.png'
import { Flex, Box, useColorModeValue, Text, Image, Table, Heading, Tbody, Td, Th, Thead, Tr, Spacer, Stack, Divider, Center } from '@chakra-ui/react';
import DateBadge from 'pages/Components/generic/Time/DateBadge';
import { projectPhases, projectStatus } from 'helpers/kshub';
import capitalize from 'capitalize';

function ProjectReport() {

  const query = new URLSearchParams(useLocation().search);
  const { apiPublic } = ApiKs();
  const { reportProjectId } = useParams<any>(); // only in case of 'edit'

  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState<Array<any>>([])
  const [orgsById, setOrgsById] = useState<any>({})
  const [reportDate, setReportDate] = useState('No Date')
  const [error, setError] = useState<string | null>(null)
  const [jwt, setJwt] = useState('')

  useEffect(() => {
    // console.info(query.get('jwt'))

    const jwt: string = query.get('jwt') || ''
    setJwt(jwt);

    if (!jwt) {
      setError('Empty JWT')
      setLoading(false)
    } else {
      apiPublic.insights.projects.render(jwt, reportProjectId).then((res) => {

        const orgs: any = {}
        res.data.organisations.map((orgData: any) => {
          return orgs[orgData.id] = orgData;
        })

        setProjects(res.data.projects)
        setReportDate(res.data.date)
        setOrgsById(orgs)
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        if (err?.response?.status === 401) {
          setError('No Authorized or Invalid Token')
        } else {
          setError('Unexpected error')
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      display='flex'
      minHeight='100vh'
      flexDirection='column'
    >
      <Box flex='1'>
        <Flex>
          <Box p="4" >
            <Text fontSize="s">
              Report Date
            </Text>
            <Text fontSize="xl">
              {loading ? 'Loading...' : reportDate}
            </Text>
          </Box>
          <Spacer />
          <Box pb="4" pr="4">
            <Text
              fontFamily={'heading'}
              color={useColorModeValue('gray.800', 'white')}>
              <Image src={LogoImg} alt="Kitspace Hub Logo" maxHeight="80px" width="auto" />
            </Text>
          </Box>
        </Flex>
        {
          loading &&
          <LoadingTable msg='Generating project report...' />
        }
        {
          error &&
          <Box>
            <Center bg="red.200" h="100px" color="white">
              <Heading as="h2" size="3xl" isTruncated>
                {error}
              </Heading>
            </Center>
            <Center>
              <Text>
                {new Date().toISOString()}
              </Text>
            </Center>
          </Box>
        }
        {
          !loading && !error &&
          <Box maxWidth='100%' overflow='auto'>
            <Table variant="striped" colorScheme="blue" size="sm">
              <Thead>
                <Tr>
                  <Th>Project</Th>
                  <Th>Channel</Th>
                  <Th>Target</Th>
                  <Th>Projected</Th>
                  <Th>Phase</Th>
                  <Th>Status</Th>
                  <Th>Confidence</Th>
                  <Th>Situation</Th>
                </Tr>
              </Thead>
              <Tbody>
                {projects.map((projData: any) => {
                  return (
                    <Tr>
                      <Td>{projData.name}</Td>
                      <Td>{orgsById[projData.channelId] ? orgsById[projData.channelId].name : projData.channelId}</Td>
                      <Td>
                        <DateBadge seconds={projData.dateTargetCompletion._seconds} withoutP />
                      </Td>
                      <Td>
                        <DateBadge seconds={projData.dateProjectedCompletion._seconds} withoutP />
                      </Td>
                      <Td>{projectPhases[projData.currentPhase] ? projectPhases[projData.currentPhase].name : projData.currentPhase}</Td>
                      <Td>{projectStatus[projData.currentStatus] ? projectStatus[projData.currentStatus].name : projData.currentStatus}</Td>
                      <Td>{capitalize(projData.confidence || '')}</Td>
                      <Td>{projData.lastComment || 'No comments'}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </Box>
        }
      </Box>
      <Box as="footer" role="contentinfo" px={{ base: '4', md: '8' }}>
        <Stack>
          <Stack direction="row" spacing="4" align="center" justify="space-between">
            <Text>
              This report can be viewed only once with this token
              <Text fontSize='1px'>
                JWT: {jwt}
              </Text>
              <Text fontSize='1px'>
                URL: {window.location.origin + window.location.pathname}
              </Text>
            </Text>
          </Stack>
          <Divider />
          <Box alignSelf={{ base: 'center', sm: 'start' }}>
            © 2021 Kitspace. All rights reserved.
          </Box>
        </Stack>
      </Box>
    </Box >
  );
}

export default ProjectReport;