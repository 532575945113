import React, { useEffect, useState } from 'react';
import BreadcrumbsKs from 'pages/Components/generic/Breadcrumbs';
import BoxResponsiveTwo from 'pages/Components/layout/Box/BoxResponsiveTwo';
import CenterBox from 'pages/Components/generic/CenterBox';
import { Badge, Button, Grid, Heading, Input, InputGroup, InputRightElement, Image, Center, GridItem, Box } from '@chakra-ui/react';
import workingInProgress from 'assets/themeisle/undraw_in_sync_re_jlqd.svg'
import workingInProgressProducts from 'assets/themeisle/undraw_in_sync_re_jlqd.svg'
import workingInProgressDesigns from 'assets/themeisle/undraw_color_palette_yamk.svg'
import { ButtonKs } from 'pages/Components/generic/Buttons';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { actions } from 'services/redux/actions'
import Introduction from './Introduction';
import Overview from './Overview';
import Documents from './Documents';

function ProjectHome() {

  const { projId } = useParams<any>();
  const [loading, setLoading] = useState(true)
  const [projData, setProjData] = useState<any>([])
  const [projAccessWrite, setProjAccessWrite] = useState<any>([])
  const [projAccessRead, setProjAccessRead] = useState<any>([])

  const stateRedux = useSelector((store: any) => store.projectReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.projects.getProjectById(projId, true, (projData: any) => {
      if (projData) {
        setProjData(projData)
        setProjAccessWrite(projData?.project_access?.accessWrite)
        setProjAccessRead(projData?.project_access?.accessRead)
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.projectsById[projId]]);

  useEffect(() => {
    if (loading !== stateRedux.loading) setLoading(stateRedux.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRedux.loading]);

  return (
    <div>
      <BreadcrumbsKs
        data={
          [
            { name: 'Projects', link: '/app/projects' },
            { name: 'Project Home', link: null }
          ]
        }
      />
      <BoxResponsiveTwo boxA={6} boxB={6}>
        <Introduction
          projData={projData}
          projId={projId}
          projAccessWrite={projAccessWrite}
          projAccessRead={projAccessRead}
        />
        <Box>
          <CenterBox fullWidth>
            <Heading as="h3" size="lg">
              Activity
            </Heading>
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(4, 1fr)"
            >
              <GridItem colSpan={4}>
                <Center>
                  <Image src={workingInProgress} maxHeight={'150px'} mt={12} mb={12} />
                </Center>
                <Center>
                  <Badge ml={4} mb={6}>Coming soon</Badge>
                </Center>
              </GridItem>
            </Grid>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                disabled
                placeholder="Write a comment..."
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" disabled>
                  Post
                </Button>
              </InputRightElement>
            </InputGroup>
          </CenterBox>
          <CenterBox fullWidth mt={4}>
            <Overview projId={projId} projData={projData} />
          </CenterBox>
        </Box>
      </BoxResponsiveTwo>

      <BoxResponsiveTwo boxA={12}>
        <Documents
          projId={projId}
        />
      </BoxResponsiveTwo>

      <BoxResponsiveTwo boxA={6} boxB={6}>
        <CenterBox fullWidth>
          <Heading as="h3" size="lg" mb={6}>Products</Heading>
          <GridItem colSpan={4}>
            <Center>
              <Image src={workingInProgressProducts} height='150px' />
            </Center>
          </GridItem>
          <GridItem colSpan={4}>
            <Center>
              <Badge ml={4} mt={6}>Coming soon</Badge>
            </Center>
          </GridItem>
          <ButtonKs.Primary disabled>Add</ButtonKs.Primary>
        </CenterBox>

        <CenterBox fullWidth>
          <Heading as="h3" size="lg" mb={6}>Concept art and design guides</Heading>
          <GridItem colSpan={4}>
            <Center>
              <Image src={workingInProgressDesigns} height='150px' />
            </Center>
          </GridItem>
          <GridItem colSpan={4}>
            <Center>
              <Badge ml={4} mt={6}>Coming soon</Badge>
            </Center>
          </GridItem>
          <ButtonKs.Primary disabled>Add</ButtonKs.Primary>
        </CenterBox>
      </BoxResponsiveTwo>
    </div >
  );
}

export default ProjectHome;